import { ReactNode } from 'react';
import styled from 'styled-components';

export const StyledLeftHalf = styled.div`
  min-width: 360px;
  max-width: 360px;
  margin-right: 32px;
`;

export const PageLeftHalf = (props: { children: ReactNode }) => {
  const { children } = props;
  return <StyledLeftHalf {...props}>{children}</StyledLeftHalf>;
};
