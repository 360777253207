export const Stars = ({ w = '72', h = '72' }: { w?: string; h?: string }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 124 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.9623 21.1719C51.104 18.6037 54.4685 17.9828 56.4517 19.9742L73.56 37.1537C74.2092 37.8056 75.0633 38.2138 75.9784 38.3094L100.092 40.8303C102.887 41.1225 104.518 44.1304 103.236 46.632L92.1846 68.2117C91.7652 69.0306 91.641 69.969 91.8328 70.8689L96.8868 94.5814C97.4727 97.3301 95.1158 99.8101 92.3408 99.3647L68.402 95.5222C67.4936 95.3764 66.5627 95.5482 65.7662 96.0087L44.776 108.143C42.3429 109.55 39.2559 108.074 38.822 105.298L35.079 81.3431C34.9369 80.434 34.4858 79.6018 33.8018 78.9865L15.775 62.7734C13.6854 60.894 14.1345 57.5023 16.6413 56.2316L38.2667 45.2693C39.0874 44.8533 39.7395 44.1672 40.1133 43.3264L49.9623 21.1719Z"
      fill="#90D128"
    />
    <path
      d="M89.9426 15.2424C90.3555 14.9417 90.9416 15.1494 91.0731 15.643L91.5112 17.2882C91.5543 17.4498 91.6517 17.5916 91.7871 17.6897L93.1656 18.689C93.5791 18.9888 93.5627 19.6104 93.1339 19.8879L91.7046 20.813C91.5642 20.9039 91.4594 21.0403 91.4079 21.1994L90.8836 22.8192C90.7263 23.3052 90.13 23.4817 89.7336 23.1596L88.4121 22.0861C88.2823 21.9807 88.1201 21.9232 87.9529 21.9234L86.2503 21.9253C85.7395 21.9258 85.3874 21.4133 85.5712 20.9367L86.1838 19.3482C86.244 19.1922 86.2485 19.0202 86.1967 18.8612L85.6688 17.2425C85.5104 16.7569 85.889 16.2637 86.3991 16.2912L88.0992 16.3829C88.2662 16.3919 88.4311 16.3431 88.5663 16.2446L89.9426 15.2424Z"
      fill="#90D128"
    />
    <path
      d="M13.7213 42.6331C13.5444 42.1539 13.9039 41.6465 14.4146 41.6544L14.9317 41.6624C15.0989 41.665 15.2619 41.6099 15.3932 41.5064L15.7992 41.1861C16.2003 40.8698 16.7939 41.0549 16.9442 41.5431L17.0964 42.0373C17.1456 42.1971 17.2483 42.3351 17.3874 42.428L17.8174 42.7152C18.2422 42.9989 18.2497 43.6206 17.8318 43.9144L17.4088 44.2119C17.272 44.308 17.1725 44.4484 17.1272 44.6094L16.9869 45.1071C16.8484 45.5988 16.2593 45.798 15.8508 45.4914L15.4372 45.181C15.3034 45.0806 15.1392 45.0294 14.9721 45.036L14.4554 45.0564C13.945 45.0766 13.5735 44.5779 13.7388 44.0946L13.9062 43.6053C13.9604 43.4471 13.9583 43.2751 13.9004 43.1182L13.7213 42.6331Z"
      fill="#90D128"
    />
  </svg>
);
