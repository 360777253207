import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GamificationIcon } from '../assets/icons/GamificationIcon';
import { ConfirmButton } from '../components/common/buttonComp/ConfirmButton';
import { InputText } from '../components/common/inputComp/InputText';
import { Typography } from '../components/common/Typography';
import { useKeyPress } from '../hooks/useKeyPress';
import { useStateValidation } from '../hooks/useStateValidation';
import { theme } from '../theme';

interface Props {
  onLogin: () => void;
}

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grayMainBg};
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 11px;
`;

const Form = styled.div`
  margin: auto;
  width: 424px;
  height: 560px;
  background: #ffffff;
  border-radius: 32px;
  padding: 76px 32px 32px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  max-width: 360px;
  a {
    text-decoration: none;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 360px;
  a {
    text-decoration: none;
  }
`;

const Header = styled.div`
  text-align: center;
`;

const login = 'pgadmin';
const password = '172938';

export const LoginPage: React.FC<Props> = ({ onLogin }) => {
  const [
    loginValue,
    setLoginValue,
    loginTouched,
    setLoginTouched,
    ,
    ,
    loginErrors,
  ] = useStateValidation('');
  const [
    passwordValue,
    setPasswordValue,
    passwordTouched,
    setPasswordTouched,
    ,
    ,
    passwordErrors,
  ] = useStateValidation('');

  const enterPressed = useKeyPress('Enter');

  const isValid = useCallback(
    () => !(loginErrors.length || passwordErrors.length),
    [loginErrors.length, passwordErrors.length]
  );

  const onClickHandler = useCallback(
    () =>
      new Promise<void>((resolve) => {
        if (loginValue === login && passwordValue === password) {
          onLogin();
          resolve();
        }
      }),
    [loginValue, onLogin, passwordValue]
  );

  useEffect(() => {
    if (enterPressed && isValid()) onClickHandler();
  }, [enterPressed, isValid, onClickHandler]);

  // useEffect(() => {
  //   setLoginValidationRules({ format: 'email', required: true });
  // }, [setLoginValidationRules]);

  const setLoginValueHandler = (value: string) => {
    setLoginValue(value);
  };

  return (
    <Wrapper>
      <GamificationIcon />
      <Form>
        <Header>
          <Typography
            fw={600}
            fz={24}
            lh={24}
            ls="-0.04px"
            mb={40}
            color={theme.colors.blackSidebar}
          >
            Вход в Playground
          </Typography>
        </Header>
        <InputsWrapper>
          <InputText
            inputValue={loginValue}
            setInputValue={setLoginValueHandler}
            placeholder="Введите рабочую почту"
            maxLength={50}
            mb={8}
            errorMessage={loginTouched && loginErrors[0]}
            setTouched={() => setLoginTouched(true)}
          />
          <InputText
            type="password"
            inputValue={passwordValue}
            setInputValue={setPasswordValue}
            placeholder="Пароль"
            maxLength={50}
            mb={8}
            errorMessage={passwordTouched && passwordErrors[0]}
            setTouched={() => setPasswordTouched(true)}
          />
          <Link to="/password-recovery">
            <Typography lh={18} mb={40} color="#808080">
              забыли пароль?
            </Typography>
          </Link>
        </InputsWrapper>
        <ButtonsWrapper>
          <ConfirmButton
            onClick={onClickHandler}
            text="Войти"
            mt={40}
            disable={!isValid()}
          />
          <Link to="/registration">
            <Typography lh={18} color="#808080">
              Зарегистрироваться
            </Typography>
          </Link>
        </ButtonsWrapper>
      </Form>
    </Wrapper>
  );
};
