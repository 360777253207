import styled from 'styled-components';
import { theme } from '../../theme';
import { Indicator } from './Indicator';
import { Typography } from './Typography';

type Props = { currentXP: string; levelXP: string; mb: number };

const StyledSberXP = styled.div`
  margin-bottom: ${({ mb }: { mb: number | undefined }) =>
    mb ? `${mb}px;` : 'unset'};
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 16px;
`;

export const SberXP = (props: Props) => {
  const { currentXP, levelXP } = props;
  const { graySubtitle } = theme.colors;

  return (
    <StyledSberXP {...props}>
      <Typography fz={14} lh={20} mb={10} ff="SB Sans Text">
        Доступное количество XP в этом месяце
      </Typography>
      <Indicator currentXP={currentXP} levelXP={levelXP} />
      <Typography fz={14} lh={20} color={graySubtitle} ff="SB Sans Text">
        Осталось {+levelXP - +currentXP} XP
      </Typography>
    </StyledSberXP>
  );
};
