import styled from 'styled-components';
import { useState } from 'react';
import { theme } from '../theme';

import { PageContent } from '../components/common/pageLayout/Shared';
import { PageLeftHalf, PageWrapper } from '../components/common/pageLayout';
import { Typography } from '../components/common/Typography';
import { InputText } from '../components/common/inputComp/InputText';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
`;

const AccountButton = styled.button<{ color: string }>`
  line-height: 20px;
  letter-spacing: -0.02em;
  color: ${({ color }) => color};
  cursor: pointer;
`;

const AccountBoldButton = styled.button<{ color: string }>`
  line-height: 18px;
  font-weight: 600;
  letter-spacing: -0.02em;
  color: ${({ color }) => color};
  cursor: pointer;
`;

export const PersonalAccountPage = () => {
  const { graySubtitle, redBanner } = theme.colors;

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [passwordLifetime, setPasswordLifetime] = useState('');

  const handlePasswordChange = () => {
    // console.log('handlePasswordChange');
  };

  const handleLogout = () => {
    // console.log('Logout');
  };

  return (
    <PageWrapper>
      <PageContent>
        <PageLeftHalf>
          <Typography mb={24} fw={600} fz={28} lh={34}>
            Личный кабинет
          </Typography>
          <InputText
            inputValue={fullName}
            setInputValue={setFullName}
            label="ФИО"
            placeholder="Иванов Иван Иванович"
            mb={16}
          />{' '}
          <InputText
            inputValue={phoneNumber}
            setInputValue={setPhoneNumber}
            label="Мобильный телефон"
            placeholder="Введите ваш номер телефона для работы"
            mb={16}
          />
          <InputText
            inputValue={email}
            setInputValue={setEmail}
            label="Рабочая почта"
            placeholder="Введите свою рабочую почту"
            mb={16}
          />
          <InputText
            inputValue={passwordLifetime}
            setInputValue={setPasswordLifetime}
            label="Пароль"
            placeholder="Срок жизни"
            mb={8}
          />
          <ButtonsContainer>
            <AccountButton color={graySubtitle} onClick={handlePasswordChange}>
              Изменить пароль
            </AccountButton>
            <AccountBoldButton color={redBanner} onClick={handleLogout}>
              Выйти из аккаунта
            </AccountBoldButton>
          </ButtonsContainer>
        </PageLeftHalf>
      </PageContent>
    </PageWrapper>
  );
};
