import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { SelectData } from '../../models';
import {
  achievementService,
  productService,
  seasonService,
} from '../../services';
import { theme } from '../../theme';
import { useCheckIsSuperAdmin } from '../../hooks/useSuperAdminCheck';
import {
  ButtonsWrapper,
  Level,
  LevelsWrapper,
  ProductLabel,
  RewardCongratulationPreview,
  RewardShortPreview,
  SavedLabel,
  SeasonSelect,
  StarsWrapper,
  StyledPageLeftContent,
} from './Styled';
import {
  PageContent,
  PageMain,
} from '../../components/common/pageLayout/Shared';
import { Stars } from '../../assets/icons/Stars';
import gamificationMedal from '../../assets/images/gamificationMedal.png';
import sberagentReward from '../../assets/images/sberagentReward.png';
import {
  InputSelect,
  SelectList,
} from '../../components/common/inputComp/InputSelect';
import { IMG_URL } from '../../http';
import sberSound from '../../assets/images/sberSound.png';
import { RewardCreateCheckIcon } from '../../assets/icons/RewardCreateCheckIcon';
import {
  PageHeader,
  PageLeftHalf,
  PageRightHalf,
  PageWrapper,
} from '../../components/common/pageLayout';
import { Typography } from '../../components/common/Typography';
import { ArrowIcon } from '../../assets/icons/ArrowIcon';
import { InputText } from '../../components/common/inputComp/InputText';
import { ConfirmButton } from '../../components/common/buttonComp/ConfirmButton';
import { InputFile } from '../../components/common/inputComp/InputFile';

const defaultAward = {
  description: '',
  promocode: '',
  activation: '',
  fileUrl: '',
  imageFileKey: '',
  awardDescription: '',
};

const selectedAwardsInit = {
  id: '0',
  name: 'Выбрать награды',
};

type PlaceData = {
  name: string;
  awardDescription: string;
  congratulation: string;
  file: File | null;
  fileUrl: string;
  status: string;
  saved: boolean;
  awardsIds: number[];
  awardIndex?: number;
};

export const RewardCreatePage = () => {
  const navigate = useNavigate();
  const {
    graySubtitle,
    grayInnerHint,
    blackSidebar,
    greenBg,
    statusBlueText,
    blackLabel,
    redBg,
    greenLightText,
    grayDarkBgButton,
  } = theme.colors;
  const isSuperAdmin = useCheckIsSuperAdmin();
  const reader = new FileReader();
  const [mounted, setMounted] = useState(false);
  const [seasonsData, setSeasonsData] = useState<
    {
      id: string;
      name: string;
      startDate: string;
      endDate: string;
    }[]
  >([]);
  const [productsData, setProductsData] = useState<SelectData[]>([]);
  const [places, setPlaces] = useState<PlaceData[]>([]);
  const [playbookId, setPlaybookId] = useState<number>();
  const [product, setProduct] = useState({ id: '0', name: 'Выберите продукт' });
  const [placeIndex, setPlaceIndex] = useState(-1);
  const [previewBtnIdx, setPreviewBtnIdx] = useState(0);
  const [openSelect, setOpenSelect] = useState(false);
  const [selectedSeason, setSelectedSeason] = useState<{
    id: string;
    name: string;
    startDate: string;
    endDate: string;
  }>({ id: '0', name: 'Выберите сезон', startDate: '', endDate: '' });
  const [selectedAwards] = useState<SelectData>(selectedAwardsInit);
  const [awardsData, setAwardsData] = useState<
    { id: string; name: string; imageUrl: string; selected: boolean }[]
  >([]);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const productsRes = (await productService.getAll()).data;
      const products = productsRes.map((el) => ({
        id: String(el.id),
        name: el.name,
      }));
      setProductsData(products);

      if (!isSuperAdmin)
        setProduct(products[products.findIndex((el) => el.id === '1')]);

      const seasonsRes = (await seasonService.getAll()).data;
      const seasons = seasonsRes.map((el) => ({
        id: String(el.playbookId),
        name: el.name,
        startDate: el.startDate,
        endDate: el.endDate,
      }));
      setSeasonsData(seasons);
      // убрать потом
      if (seasons.length) setSelectedSeason(seasons[0]);

      achievementService.getAll().then(({ data }) =>
        setAwardsData(
          data.map((t) => ({
            id: String(t.elementId),
            name: t.name,
            imageUrl: t.imageUrl,
            selected: false,
          }))
        )
      );
    }
    if (mounted) {
      fetchData();
    }
  }, [isSuperAdmin, mounted]);

  useEffect(() => {
    async function updateSeasonData(id: string) {
      const seasonData = (await seasonService.getByPlaybookId(id)).data;
      const newPlaces = seasonData.seasonElementsGetDtos.map((el) => ({
        status: el.seasonElementsStatus,
        name: '',
        awardDescription: '',
        congratulation: '',
        file: null,
        fileUrl: '',
        saved: false,
        awardsIds: [],
      }));
      setPlaces(newPlaces);
      setPlaybookId(seasonData.playbookId);
    }
    const curSeason = seasonsData.find((se) => se.id === selectedSeason.id);
    if (mounted && selectedSeason.id !== '0' && curSeason) {
      updateSeasonData(curSeason.id);
    }
  }, [selectedSeason, seasonsData, mounted]);

  const handleTrophySelect = (v: SelectData) => {
    const newAwardsData = awardsData.map((award) => ({
      ...award,
      selected: award.id === v.id ? !award.selected : award.selected,
    }));
    setAwardsData(newAwardsData);
    setPlaces((prev) =>
      prev.map((pl, index) =>
        index === placeIndex
          ? {
              ...pl,
              awardsIds: newAwardsData
                .filter((el) => el.selected)
                .map((el) => +el.id),
            }
          : pl
      )
    );
  };

  const handleRewardCreate = () =>
    new Promise<void>((resolve) => {
      // try {
      //   if (!playbookId) return;
      //   Promise.all(
      //     awardData.map(async (el, index) => {
      //       const idx = places.findIndex((place) => place.awardIndex === index);
      //       const award = {
      //         awards: [
      //           {
      //             description: el.description,
      //             product: { id: +product.id, name: product.name },
      //             promocode: el.promocode,
      //             imageFileKey: el.imageFileKey,
      //             activationLink: el.activation,
      //             awardDescription: el.awardDescription,
      //           },
      //         ],
      //         level: idx !== -1 ? idx + 1 : 1,
      //       };
      //       return seasonService.createAward(award, playbookId);
      //     })
      //   )
      //     .then((data) => {
      //       if (data.every((el) => el.status === 200)) {
      //         resolve();
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // } catch (error) {
      //   console.log(error);
      // }
    });

  const toggleSavePlace = (value: boolean) =>
    new Promise<void>((resolve) => {
      setPlaces((prev) =>
        prev.map((el, index) =>
          index === placeIndex ? { ...el, saved: value } : el
        )
      );
      resolve();
    });

  const handleChangePlaceField = (v: string | null, field: string) =>
    setPlaces((prev) =>
      prev.map((el, index) =>
        index === placeIndex ? { ...el, [field]: v } : el
      )
    );

  const handleAddFile = (fileData: any) => {
    const form = new FormData();
    let imageFileKey: string;
    form.append('file', fileData);

    seasonService.uploadImage(form).then((response) => {
      imageFileKey = response.data;
      handleChangePlaceField(`${IMG_URL}${imageFileKey}`, 'fileUrl');
    });

    if (fileData) {
      handleChangePlaceField(fileData, 'file');
      reader.readAsDataURL(fileData);
    }
  };

  const handleDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const fileData = e.dataTransfer.files[0];
    reader.readAsDataURL(fileData);
  };

  const handlePlaceSelect = (index: number) => {
    if (places[index].status === 'FREE') {
      setPlaceIndex(index);
      setAwardsData((prev) =>
        prev.map((el) => ({
          ...el,
          selected: places[index].awardsIds.includes(+el.id),
        }))
      );
      if (!places[index].saved) {
        setPlaces((prev) =>
          prev.map((p, i) =>
            i === index
              ? {
                  awardDescription: '',
                  name: '',
                  congratulation: '',
                  file: null,
                  fileUrl: '',
                  status: 'FREE',
                  saved: false,
                  awardsIds: [],
                }
              : p
          )
        );
      }
    }
  };

  const isValid = () => product.id !== '0';

  function getFormattedDate(date: string) {
    const years = date.slice(0, 4);
    const months = date.slice(5, 7);
    const days = date.slice(8, 10);
    return `${days}.${months}.${years}`;
  }

  function renderSeasonDates() {
    if (selectedSeason.id === '0') return 'дата проведения';
    return `${getFormattedDate(selectedSeason.startDate)} - ${getFormattedDate(
      selectedSeason.endDate
    )}`;
  }

  function getSeasonStatusType() {
    const now = new Date();
    const start = new Date(selectedSeason.startDate);
    const end = new Date(selectedSeason.endDate);
    if (start > now) return 'next';
    if (now > end) return 'prev';
    if (selectedSeason.id === '0') return 'default';
    return 'now';
  }

  function getStatusColor() {
    const type = getSeasonStatusType();
    switch (type) {
      case 'next':
        return statusBlueText;
      case 'now':
        return greenLightText;
      default:
        return blackLabel;
    }
  }

  function renderSeasonStatus() {
    const type = getSeasonStatusType();
    switch (type) {
      case 'next':
        return 'Следующий';
      case 'prev':
        return 'Закончен';
      case 'now':
        return 'Текущий';
      default:
        return 'Статус';
    }
  }

  return (
    <PageWrapper>
      <PageContent maxWidth={864}>
        <PageHeader
          onClick={() => navigate('/gamification')}
          title="Создание сезонной награды"
          navButtons={[
            { to: '/reward-create', label: 'Создание' },
            { to: '/reward-archive', label: 'Уже созданные' },
          ]}
        />
        <PageMain>
          <PageLeftHalf>
            <StyledPageLeftContent>
              <Typography
                ff="SB Sans Text"
                lh={18}
                ls="-0.02em"
                color={blackSidebar}
                mb={6}
              >
                Сезон
              </Typography>
              <SeasonSelect onClick={() => setOpenSelect((prev) => !prev)}>
                <div>
                  <Typography
                    ff="SB Sans Display"
                    fz={16}
                    lh={20}
                    ls="-0.02em"
                    color="#000"
                    mb={8}
                  >
                    {selectedSeason.name}
                  </Typography>
                  <Typography
                    ff="SB Sans Display"
                    fz={16}
                    lh={20}
                    color={graySubtitle}
                  >
                    {renderSeasonDates()}
                  </Typography>
                </div>
                <div>
                  <Typography
                    ff="Alef"
                    fz={14}
                    lh={16}
                    ls="-0.01em"
                    color={getStatusColor()}
                    mb={15}
                  >
                    {renderSeasonStatus()}
                  </Typography>
                  <ArrowIcon />
                </div>
                {openSelect && (
                  <SelectList>
                    {seasonsData.map((s) => (
                      <div
                        key={s.id}
                        role="presentation"
                        onClick={() => {
                          setSelectedSeason(s);
                          setPlaceIndex(-1);
                        }}
                      >
                        {s.name}
                      </div>
                    ))}
                  </SelectList>
                )}
              </SeasonSelect>
              {isSuperAdmin && (
                <InputSelect
                  data={productsData}
                  inputValue={product}
                  setInputValue={setProduct}
                  label="Продукт"
                  mb={6}
                />
              )}
              {selectedSeason.id !== '0' && (
                <LevelsWrapper>
                  {places.map((el, index) => (
                    <Level
                      key={uuidv4()}
                      type="button"
                      free={el.status === 'FREE'}
                      selected={index === placeIndex}
                      onClick={() => handlePlaceSelect(index)}
                    >
                      {index + 1}
                      {el.status === 'TAKEN' && (
                        <ProductLabel bgUrl={sberSound} />
                      )}
                      {index !== placeIndex && el.saved && (
                        <SavedLabel>
                          <RewardCreateCheckIcon />
                        </SavedLabel>
                      )}
                    </Level>
                  ))}
                </LevelsWrapper>
              )}

              {placeIndex === -1 ? (
                <Typography
                  fz={12}
                  lh={16}
                  ff="SB Sans Text"
                  ls="-0.02em"
                  color={graySubtitle}
                >
                  Каждая цифра — уровень текущего Сезона. Если пользователь
                  достигает какого-либо из уровней, он получает награду от
                  продукта, под который этот уровень был забронирован здесь. Вы
                  можете выбрать столько уровней для наград от своего продукта,
                  сколько захотите.
                </Typography>
              ) : (
                <>
                  <InputSelect
                    data={awardsData}
                    inputValue={selectedAwards}
                    setInputValue={handleTrophySelect}
                    multiple
                    color={grayDarkBgButton}
                    mb={23}
                  />
                  <InputText
                    label="Название награды"
                    inputValue={places[placeIndex].name}
                    placeholder="Например, Футболка для аватара"
                    innerHint="максимум Х символов"
                    setInputValue={(v) => handleChangePlaceField(v, 'name')}
                    maxLength={500}
                    multiple
                    mb={20}
                  />
                  <InputText
                    label="Описание награды"
                    inputValue={places[placeIndex].awardDescription}
                    placeholder="Например, Зарабатывайте больше очков опыта, чтобы покорить новый уровень. В награду — футболка, в которой ваш аватар будет неотразим."
                    innerHint="максимум Х символов"
                    setInputValue={(v) =>
                      handleChangePlaceField(v, 'awardDescription')
                    }
                    maxLength={500}
                    minHeight={120}
                    multiple
                    mb={6}
                  />
                  <Typography
                    fz={12}
                    lh={16}
                    ff="SB Sans Text"
                    ls="-0.02em"
                    color={graySubtitle}
                    mb={20}
                  >
                    Небольшой текст, мотивирующий пользователя получить награду.
                  </Typography>
                  <InputText
                    label="Поздравление после получения награды"
                    inputValue={places[placeIndex].congratulation}
                    placeholder="Например, Футболка уже в гардеробе вашего аватара. Примерим её?"
                    innerHint="максимум Х символов"
                    setInputValue={(v) =>
                      handleChangePlaceField(v, 'congratulation')
                    }
                    maxLength={500}
                    minHeight={120}
                    multiple
                    mb={6}
                  />
                  <Typography
                    fz={12}
                    lh={16}
                    ff="SB Sans Text"
                    ls="-0.02em"
                    color={graySubtitle}
                    mb={19}
                  >
                    Мини-поздравление, которое пользователь увидит после
                    получения новой награды. Действие, за которое выдается
                    награда, появится автоматически.
                  </Typography>
                  <InputFile
                    label="Иллюстрация"
                    placeholder="Прикрепите изображение награды
                        в формате PNG или JPG"
                    handleAddFile={(e) => handleAddFile(e)}
                    handleDropFile={handleDropFile}
                    fileUrl={places[placeIndex].fileUrl}
                    file={places[placeIndex].file}
                    handleFileRemove={() => {
                      handleChangePlaceField('', 'fileUrl');
                      handleChangePlaceField(null, 'file');
                    }}
                    mb={20}
                  />
                  <ButtonsWrapper>
                    <ConfirmButton
                      onClick={() => toggleSavePlace(true)}
                      text="Сохранить"
                      background={greenBg}
                      disable={false}
                    />
                    <ConfirmButton
                      onClick={() => toggleSavePlace(false)}
                      text="Отменить"
                      paddingBlock={9}
                      background="transparent"
                      border={redBg}
                      color={redBg}
                      disable={false}
                    />
                  </ButtonsWrapper>

                  <ConfirmButton
                    onClick={handleRewardCreate}
                    text="Создать все сохранённые награды"
                    modalMessage="Сезонная награда
                создана и добавлена в виджет"
                    modalBtnText="Хорошо"
                    mt={10}
                    disable={!isValid()}
                  />
                </>
              )}
            </StyledPageLeftContent>
          </PageLeftHalf>
          <PageRightHalf
            maxWidth={472}
            description="Так награда будет выглядеть в виджете"
            buttons={[
              {
                label: 'Название',
                callback: () => setPreviewBtnIdx(0),
              },
              {
                label: 'Описание',
                callback: () => setPreviewBtnIdx(1),
              },
              {
                label: 'Поздравление',
                callback: () => setPreviewBtnIdx(2),
              },
            ]}
            currentBtnIdx={previewBtnIdx}
          >
            {previewBtnIdx === 0 && (
              <RewardShortPreview>
                <div>
                  <StarsWrapper>
                    <Stars w="124" h="124" />
                  </StarsWrapper>
                  <img
                    src={
                      placeIndex === -1 || !places[placeIndex].fileUrl.length
                        ? gamificationMedal
                        : places[placeIndex].fileUrl
                    }
                    alt="RewardPreview"
                  />
                </div>
                <div>
                  <Typography fz={20} lh={25} ls="-0.02em" color={blackSidebar}>
                    {placeIndex === -1 || places[placeIndex].name.length === 0
                      ? 'Название награды'
                      : places[placeIndex].name}
                  </Typography>
                  <Typography
                    fz={12}
                    lh={15}
                    ls="-0.02em"
                    color={grayInnerHint}
                  >
                    100 XP
                  </Typography>
                </div>
              </RewardShortPreview>
            )}
            {/* {previewBtnIdx === 1 && <RewardDetailedPreview />} */}
            {previewBtnIdx === 2 && (
              <RewardCongratulationPreview>
                <img src={sberagentReward} alt="rewardPreview" />
                <Typography
                  ff="SB Sans Text"
                  fw={600}
                  fz={24}
                  ls="-0.02em"
                  lh="110%"
                  mb={24}
                  center
                >
                  Футболка «СберАгент» для аватара
                </Typography>
                <Typography
                  ff="SB Sans Text"
                  fz={16}
                  ls="-0.02em"
                  lh={20}
                  mb={24}
                  center
                >
                  Отличное начало! Вы получили эту награду за выполнение первого
                  задания.
                </Typography>
                <Typography
                  ff="SB Sans Text"
                  fz={16}
                  ls="-0.02em"
                  lh={20}
                  center
                  color={grayInnerHint}
                >
                  Получено 20 июля 2022
                </Typography>
              </RewardCongratulationPreview>
            )}
          </PageRightHalf>
        </PageMain>
      </PageContent>
    </PageWrapper>
  );
};
