import styled from 'styled-components';
import { MapAwordItem } from './MapAwordItem';

type Props = {
  name?: string;
  description?: string;
  elementCount?: number;
  backgroundSrc?: string;
};

const StyledMap = styled.div`
  width: 100%;
  padding: 0;
`;
const Footer = styled.div`
  position: absolute;
  bottom: 15px;
  left: 20px;
`;
const SelectAwardButton = styled.div`
  padding: 8px 12px;
  gap: 10px;

  width: 93px;
  height: 36px;

  border: 1px solid #bdbdbd;
  border-radius: 8px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const AwardButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 6px;
`;

export const SelectMapAwords = () => {
  // const { elementCount, description, name, backgroundSrc } = props;

  const handleAwardTypeChange = (val: any) => {
    console.log(val);
  };

  return (
    <StyledMap>
      <Container>
        <AwardButtons>
          <SelectAwardButton>Награда 1</SelectAwardButton>
        </AwardButtons>
        <MapAwordItem
          awardType={1}
          handleAwardTypeChange={handleAwardTypeChange}
        />
      </Container>
    </StyledMap>
  );
};
