import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GamificationIcon } from '../assets/icons/GamificationIcon';
import { ConfirmButton } from '../components/common/buttonComp/ConfirmButton';
import { InputText } from '../components/common/inputComp/InputText';
import { Typography } from '../components/common/Typography';
import { useStateValidation } from '../hooks/useStateValidation';
import { theme } from '../theme';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grayMainBg};
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 11px;
`;

const Form = styled.div`
  margin: auto;
  width: 424px;
  height: 560px;
  background: #ffffff;
  border-radius: 32px;
  padding: 76px 32px 32px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  max-width: 360px;
  a {
    text-decoration: none;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 360px;
  a {
    text-decoration: none;
  }
`;

const Header = styled.div`
  text-align: center;
`;

export const RegisterPage: React.FC = () => {
  const [
    loginValue,
    setLoginValue,
    loginTouched,
    setLoginTouched,
    setLoginValidationRules,
    ,
    loginErrors,
  ] = useStateValidation('');
  const [
    passwordValue,
    setPasswordValue,
    passwordTouched,
    setPasswordTouched,
    ,
    ,
    passwordErrors,
  ] = useStateValidation('');
  const [
    secondPasswordValue,
    setSecondPasswordValue,
    secondPasswordTouched,
    setSecondPasswordTouched,
    ,
    setSecondPasswordErrors,
    secondPasswordErrors,
  ] = useStateValidation('');

  useEffect(() => {
    setLoginValidationRules({ required: true, format: 'email' });
  });

  const registerUser = () =>
    new Promise<void>((resolve) => {
      resolve();
    });

  const isValid = () =>
    !(
      loginErrors.length ||
      passwordErrors.length ||
      secondPasswordErrors.length
    );

  const setSecondPasswordTouchedHandler = () => {
    setSecondPasswordTouched(true);
    if (passwordValue !== secondPasswordValue)
      setSecondPasswordErrors((errors) => [...errors, 'Пароли не совпадают']);
  };

  const setLoginValueHandler = (value: string) => {
    setLoginValue(value);
  };

  return (
    <Wrapper>
      <GamificationIcon />
      <Form>
        <Header>
          <Typography
            fw={600}
            fz={24}
            lh={24}
            ls="-0.04px"
            mb={40}
            color={theme.colors.blackSidebar}
          >
            Регистрация в Playground
          </Typography>
        </Header>
        <InputsWrapper>
          <InputText
            inputValue={loginValue}
            setInputValue={setLoginValueHandler}
            placeholder="Введите рабочую почту"
            maxLength={50}
            mb={8}
            errorMessage={loginTouched && loginErrors[0]}
            setTouched={() => setLoginTouched(true)}
          />
          <InputText
            type="password"
            inputValue={passwordValue}
            setInputValue={setPasswordValue}
            placeholder="Пароль"
            maxLength={50}
            mb={8}
            errorMessage={passwordTouched && passwordErrors[0]}
            setTouched={() => setPasswordTouched(true)}
          />
          <InputText
            type="password"
            inputValue={secondPasswordValue}
            setInputValue={setSecondPasswordValue}
            placeholder="Подтвердите пароль"
            maxLength={50}
            mb={8}
            errorMessage={secondPasswordTouched && secondPasswordErrors[0]}
            setTouched={() => setSecondPasswordTouchedHandler()}
          />
        </InputsWrapper>
        <ButtonsWrapper>
          <ConfirmButton
            onClick={registerUser}
            text="Создать аккаунт"
            mt={40}
            disable={!isValid()}
          />
          <Link to="/login">
            <Typography lh={18} color="#808080">
              Войти в аккаунт
            </Typography>
          </Link>
        </ButtonsWrapper>
      </Form>
    </Wrapper>
  );
};
