import { ChangeEvent, useRef } from "react"
import { ClipIcon } from "../../../../assets/icons/ClipIcon"
import { CrossIcon } from "../../../../assets/icons/CrossIcon"
import { theme } from "../../../../theme"
import { cutFileExtension, getFileSize } from "../../../../utils"
import { Typography } from "../../Typography"
import { Button, InformationWrapper, Input, InputWrapper, Label } from "./Styles"

type InputUploadProps = {
  id: string | number;
  value: File | null;
  setValue: (file: File | null) => void;
  accept?: string;
  maxSize?: number;
};

export const InputUpload = ({id, value, setValue, accept = ".png", maxSize = 2}: InputUploadProps) => {
  const { graySubtitle } = theme.colors;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChangeFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const fileExtension = `.${file.name.split('.').pop()}`;
      const acceptedExtensions = accept.split(',');
      const sizeInMb = getFileSize(file.size);

      if (acceptedExtensions.includes(fileExtension) && sizeInMb < maxSize) {
        setValue(file)
      } else {
        alert(`выберите файл с расширением ${acceptedExtensions} и размером не больше ${maxSize} mb.`)
      }
    }
  };

  const handleRemoveFile = () => {
    setValue(null)
  }

  const handleClipClick = () => inputRef.current?.click();

  const renderContent = () => (
    value ? (
      <>
        <InformationWrapper>
          <Typography mr={10}>{cutFileExtension(value.name)}</Typography>
          <Typography color={graySubtitle}>{`${getFileSize(value.size)} mb.`}</Typography>
        </InformationWrapper>
        <Button type="button" onClick={handleRemoveFile}>
          <CrossIcon />
        </Button>
      </>
    ) : (
      <>
        <Label htmlFor={`uploadInput-${id}`}>Загрузить изображение</Label>
        <Input
          id={`uploadInput-${id}`}
          type="file"
          accept={accept}
          ref={inputRef}
          onChange={handleChangeFileInput}
        />
        <Button type="button" onClick={handleClipClick}>
          <ClipIcon />
        </Button>
      </>
    )
  );

  return (
    <InputWrapper>
      {renderContent()}
    </InputWrapper>
  );
};