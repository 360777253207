import styled from 'styled-components';
import { GamificationIcon } from '../assets/icons/GamificationIcon';
import { authService } from '../services';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grayMainBg};
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 11px;
`;

const Title = styled.div`
  color: ${(props) => props.theme.colors.grayTitle};
  font-family: 'SB Sans Display';
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #999999;
  margin-top: 155px;
`;

const Subtitle = styled.div`
  font-family: 'SB Sans Text';
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  display: flex;
  letter-spacing: -0.01em;
  color: #000000;
  margin-bottom: 32px;
  text-align: center;
`;

const LoginButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 336px;
  width: 100%;
`;

const LoginButton = styled.button`
  font-family: 'SB Sans Text';
  font-weight: 500;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  color: #323232;
  background-color: white;
  padding: 16px 0;
  width: 100%;
  border-radius: 12px;
  &:first-child {
    margin-bottom: 8px;
  }
`;

export const SelectAdminTypePage = () => {
  const handleSuperAuth = () =>
    authService.login(btoa('super-admin:admin'), 'super');
  const handleLocalAuth = () =>
    authService.login(btoa('klever-admin:admin'), 'local');

  return (
    <Wrapper>
      <GamificationIcon />
      <Title>Playground</Title>
      <Subtitle>
        Выберите тип
        <br />
        администратора
      </Subtitle>
      <LoginButtonsWrapper>
        <LoginButton onClick={handleLocalAuth}>Админ продукта</LoginButton>
        <LoginButton onClick={handleSuperAuth}>Админ лаборатории</LoginButton>
      </LoginButtonsWrapper>
    </Wrapper>
  );
};
