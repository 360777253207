import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { seasonService } from '../services';
import { getClaimDateFormat, getSeasonDateFormat } from '../utils';
import { SeasonArchive, SeasonArchiveField } from '../models';
import { PageHeader, PageWrapper } from '../components/common/pageLayout';
import { InputControls } from '../components/common/inputComp/InputControls';

const StyledSeasonWrapper = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 1440px;
  min-height: 100vh;
  overflow: auto;
  background: #f3f3f3;
`;

const PageMain = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Thead = styled.thead`
  background-color: ${(props) => props.theme.colors.whiteBg};
  tr {
    color: ${(props) => props.theme.colors.grayHeaderText};
    background-color: ${(props) => props.theme.colors.whiteBg};
    th {
      font-style: normal;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: ${(props) => props.theme.colors.grayHeaderText};
    }
    th:first-child {
      border-radius: 8px 0 0 8px;
    }
    th:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

const Tbody = styled.tbody`
  tr {
    background-color: ${(props) => props.theme.colors.whiteBg};
    cursor: pointer;
    line-height: 24px;
    letter-spacing: -0.01em;
    td:first-child {
      border-radius: 10px 0 0 10px;
    }
    td:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
`;

const Tr = styled.tr`
  text-align: left;
`;

const Th = styled.th`
  padding: 9px 16px;
  & > div {
    position: relative;
    width: fit-content;
    padding-right: 12px;
  }
`;

const Td = styled.td`
  padding: 15px 16px;
  color: ${(props) => props.theme.colors.blackLabel};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
`;

const statuses = ['Следующий', 'Текущий', 'Закончен'];

const columns = [
  {
    field: 'seasonPeriod',
    label: 'Дата начала и конца сезона',
    controls: true,
    sortType: 'lowCaseString',
  },
  {
    field: 'name',
    label: 'Название сезона',
    controls: true,
    sortType: 'lowCaseString',
  },
  {
    field: 'numberSeasonElements',
    label: 'Кол-во уровней в сезоне',
    controls: true,
    sortType: 'number',
  },
  {
    field: 'status',
    label: 'Статус',
    controls: true,
    sortType: 'number',
  },
] as {
  field: SeasonArchiveField;
  label: string;
  controls: boolean;
  sortType: string;
}[];

type SeasonListItem = SeasonArchive & {
  playbookId: number;
};

const PageContent = styled.div`
  max-width: 1300px;
  margin: 0 auto;
`;

const StyledStatusLabel = styled.div<{ status: number }>`
  color: ${({ status }) => {
    if (status === 0) return '#4657F3';
    if (status === 1) return '#28B750';
    return '#323232';
  }};
  line-height: 24px;
`;

export const SeasonArchivePage = () => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState<{
    field: SeasonArchiveField;
    dir: 'desc' | 'asc';
    sortType: string;
  }>({
    field: 'status',
    dir: 'desc',
    sortType: 'date',
  });
  const [seasons, setSeason] = useState<SeasonListItem[]>([]);

  const getStatus = (startDate: string, endDate: string) => {
    const today = new Date().getTime();
    const startDateStamp = new Date(startDate).getTime();
    if (startDateStamp > today) return 0;
    const endDateStamp = new Date(endDate).getTime();
    if (startDateStamp <= today && endDateStamp >= today) return 1;
    return 2;
  };

  useEffect(() => {
    seasonService.getAll().then(({ data }) => {
      setSeason(
        data.map((season) => ({
          playbookId: season.playbookId,
          name: season.name,
          startDate: getClaimDateFormat(season.startDate),
          endDate: getClaimDateFormat(season.endDate),
          seasonPeriod: `с ${getSeasonDateFormat(
            season.startDate
          )} по ${getSeasonDateFormat(season.endDate)}`,
          status: getStatus(season.startDate, season.endDate),
          numberSeasonElements: season.numberSeasonElements,
        }))
      );
    });
  }, []);

  const filteredAndSortedSeason = useCallback(() => {
    const sorted = seasons.sort((a, b) => {
      if (filter.sortType === 'number') {
        if (+a[filter.field] > +b[filter.field])
          return filter.dir === 'desc' ? -1 : 1;
        if (+a[filter.field] < +b[filter.field])
          return filter.dir === 'desc' ? 1 : -1;
      }

      if (filter.sortType === 'lowCaseString') {
        if (
          (a[filter.field] as string).toLowerCase() >
          (b[filter.field] as string).toLowerCase()
        )
          return filter.dir === 'desc' ? -1 : 1;
        if (
          (a[filter.field] as string).toLowerCase() <
          (b[filter.field] as string).toLowerCase()
        )
          return filter.dir === 'desc' ? 1 : -1;
      }

      // if (filter.field === 'startDate') {
      //   if (
      //     stringToTimestamp(a[filter.field]) <
      //     stringToTimestamp(b[filter.field])
      //   )
      //     return filter.dir === 'desc' ? -1 : 1;
      //   if (
      //     stringToTimestamp(a[filter.field]) >
      //     stringToTimestamp(b[filter.field])
      //   )
      //     return filter.dir === 'desc' ? 1 : -1;
      // }
      if (a[filter.field] < b[filter.field])
        return filter.dir === 'desc' ? -1 : 1;
      if (a[filter.field] > b[filter.field])
        return filter.dir === 'desc' ? 1 : -1;
      return 0;
    });
    return sorted;
  }, [seasons, filter]);

  const buttonClickHandler = (id: string) => {
    console.log(id);
    // navigate(`${id}`);
  };

  return (
    <PageWrapper>
      <PageContent>
        <PageHeader
          onClick={() => navigate('/gamification')}
          title="Создание задания"
          archivePage
          navButtons={[
            { to: '/season-create', label: 'Создание' },
            { to: '/season-archive', label: 'Уже созданные' },
          ]}
        />
        <PageMain>
          <StyledSeasonWrapper>
            <Table>
              <Thead>
                <Tr>
                  {columns.map((column) => (
                    <Th key={uuidv4()}>
                      <div>
                        {column.label}
                        {column.controls && (
                          <InputControls
                            handleStepUpClick={() =>
                              setFilter({
                                field: column.field,
                                dir: 'desc',
                                sortType: column.sortType,
                              })
                            }
                            handleStepDownClick={() =>
                              setFilter({
                                field: column.field,
                                dir: 'asc',
                                sortType: column.sortType,
                              })
                            }
                          />
                        )}
                      </div>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {filteredAndSortedSeason().map((season) => (
                  <Tr
                    key={uuidv4()}
                    onClick={() =>
                      buttonClickHandler(String(season.playbookId))
                    }
                  >
                    <Td>{season.seasonPeriod}</Td>
                    <Td>{season.name}</Td>
                    <Td>{season.numberSeasonElements}</Td>
                    <Td>
                      {' '}
                      <StyledStatusLabel status={season.status}>
                        {statuses[season.status]}
                      </StyledStatusLabel>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </StyledSeasonWrapper>
        </PageMain>
      </PageContent>
    </PageWrapper>
  );
};
