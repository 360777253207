import React, { useState } from 'react';
import styled from 'styled-components';
import { GamificationIcon } from '../assets/icons/GamificationIcon';
import { ConfirmButton } from '../components/common/buttonComp/ConfirmButton';
import { InputText } from '../components/common/inputComp/InputText';
import { Typography } from '../components/common/Typography';
import { useStateValidation } from '../hooks/useStateValidation';
import { theme } from '../theme';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grayMainBg};
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 11px;
`;

const Form = styled.div`
  margin: auto;
  width: 424px;
  height: 560px;
  background: #ffffff;
  border-radius: 32px;
  padding: 76px 32px 32px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  max-width: 360px;
  text-align: left;
  a {
    text-decoration: none;
  }
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 360px;
  a {
    text-decoration: none;
  }
`;

const Header = styled.div`
  text-align: center;
`;

export const PasswordRecoveryPage: React.FC = () => {
  const { graySubtitle, blackSidebar } = theme.colors;
  const [
    loginValue,
    setLoginValue,
    loginTouched,
    setLoginTouched,
    ,
    ,
    loginErrors,
  ] = useStateValidation('');

  const [emailSend, setEmailSend] = useState(false);

  const onClickHandler = () =>
    new Promise<void>((resolve) => {
      setEmailSend(true);
      resolve();
    });

  const isValid = () => !loginErrors.length;

  const setLoginValueHandler = (value: string) => {
    setLoginValue(value);
  };

  return (
    <Wrapper>
      <GamificationIcon />
      <Form>
        <Header>
          <Typography
            fw={600}
            fz={24}
            lh={24}
            ls="-0.04px"
            mb={40}
            color={theme.colors.blackSidebar}
          >
            Регистрация в Playground
          </Typography>{' '}
          <InputsWrapper>
            <InputText
              inputValue={loginValue}
              setInputValue={setLoginValueHandler}
              placeholder="Введите рабочую почту"
              maxLength={50}
              mb={6}
              errorMessage={loginTouched && loginErrors[0]}
              setTouched={() => setLoginTouched(true)}
            />

            <Typography
              fz={12}
              lh={16}
              mb={12}
              ls="-0.02em"
              color={graySubtitle}
            >
              Введите свой электронный адрес, мы отправим вам ссылку для
              восстановления доступа к аккаунту
            </Typography>
            {emailSend && (
              <Typography fz={12} lh={16} ls="-0.02em" color={blackSidebar}>
                Cсылка для восстановления пароля отправлена на почту
              </Typography>
            )}
          </InputsWrapper>
        </Header>

        <ButtonsWrapper>
          <ConfirmButton
            onClick={onClickHandler}
            text="Получить ссылку для входа"
            mt={40}
            disable={!isValid()}
          />
        </ButtonsWrapper>
      </Form>
    </Wrapper>
  );
};
