import { theme } from '../../theme';

const CheckedCircleIcon = () => {
  const { greenBg } = theme.colors;

  return (
    <svg fill="none" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg">
      <circle cx="35" cy="35" r="35" fill={greenBg} />
      <path
        d="m30.017 41.743-7.2558-7.2557-2.4708 2.4533 9.7266 9.7266 20.88-20.88-2.4534-2.4534-18.427 18.409z"
        fill="#fff"
      />
      <mask
        x="20"
        y="23"
        width="31"
        height="24"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
      >
        <path
          d="m30.017 41.743-7.2558-7.2557-2.4708 2.4533 9.7266 9.7266 20.88-20.88-2.4534-2.4534-18.427 18.409z"
          fill="#fff"
        />
      </mask>
    </svg>
  );
};

export default CheckedCircleIcon;
