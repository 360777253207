import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import { theme } from '../theme';
import FontStyles from '../global/globalFonts';
import { AuthMiddleware } from './AuthMiddleware';

export const App = () => (
  <ThemeProvider theme={theme}>
    <FontStyles />
    <BrowserRouter>
      <AuthMiddleware />
    </BrowserRouter>
  </ThemeProvider>
);
