import { useEffect, useState } from 'react';
import { cookies } from '../utils';

export function useCheckIsSuperAdmin() {
  // харкдор для показа вернуть все что закомиченно
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean | null>(true);
  // const [isSuperAdmin, setIsSuperAdmin] = useState<boolean | null>(null);

  // const cookiesCallback = ({ name }: { name: string }) => {
  //   if (name === 'authValue') {
  //     window.location.reload();
  //   }
  // };

  // useEffect(() => {
  //   cookies.addChangeListener(cookiesCallback);

  //   const type = cookies.get<{ token: string; type: string }>(
  //     'authValue'
  //   )?.type;

  //   if (type) setIsSuperAdmin(type === 'super');

  //   return () => cookies.removeChangeListener(cookiesCallback);
  // }, []);

  return isSuperAdmin;
}
