import styled from 'styled-components';
import { GamificationTaskButton } from './GamificationTaskButton';
import treasureMap from '../../assets/images/treasure_map.png';
import { theme } from '../../theme';
import { Typography } from './Typography';

type GamificationTreasureMapProps = {
  isSuperAdmin: boolean;
}

const Card = styled.div<{ src: string; bgColor: string }>`
  position: relative;
  min-width: 544px;
  height: 100%;
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 28px 0 14px 28px;
  gap: 8px;
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${({ src }) => `url(${src})`};
    background-position: right top;
    background-repeat: no-repeat;
    z-index: 1;
  }
`;

const CardBody = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  width: 307px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 8px;
  z-index: 2;
`;

const Header = styled.div`
  width: 100%;
`;

export function GamificationTreasureMap(props: GamificationTreasureMapProps) {
  const { whiteBg, blackLabel, grayDarkBgButton } = theme.colors;
  const { isSuperAdmin } = props;

  const renderLinks = () => (
      <>
        {isSuperAdmin && (
          <GamificationTaskButton
            color={grayDarkBgButton}
            link="/map-create"
            text="Создать"
            noBorder
          />
        )}
        <GamificationTaskButton
          color={grayDarkBgButton}
          link="/map-task-create"
          text="Добавить награды в карту"
          noBorder
        />
      </>
    )
  return (
    <Card src={treasureMap as string} bgColor={whiteBg}>
      <CardBody bgColor={whiteBg}>
        <Header>
          <Typography fw={600} fz={20} lh={26} ls="-0.01em" color="#000000">
            Карта сокровищ
          </Typography>
        </Header>
        <Typography lh={18} ls="-0.01em" color={blackLabel}>
          Простое действие, которое пользователи смогут выполнять каждый день,
          чтобы зарабатывать очки опыта.
        </Typography>
        {renderLinks()}
      </CardBody>
      {/* <Gamepad /> */}
    </Card>
  );
}
