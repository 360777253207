import styled, { css } from 'styled-components';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { SelectData } from '../../../models/Shared';
import { Typography } from '../Typography';
import { theme } from '../../../theme';
import { ArrowIcon } from '../../../assets/icons/ArrowIcon';

type Props = {
  data: {
    id: string;
    name: string;
    imageUrl?: string;
    disabled?: boolean;
    selected?: boolean;
  }[];
  label?: string;
  inputValue?: SelectData;
  setInputValue?: (val: any) => void;
  hint?: string;
  mb?: number;
  disabled?: boolean;
  multiple?: boolean;
  color?: string;
};

const StyledInputSelect = styled.div`
  margin-bottom: ${(props: Props) => (props.mb ? `${props.mb}px;` : 'unset')};
`;

const Label = styled.div`
  color: ${(props) => props.theme.colors.blackSidebar};
  font-family: 'SB Sans Text';
  line-height: 18px;
  letter-spacing: -0.02em;
  margin-bottom: 6px;
`;

export const SelectList = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grayBorderButton};
  z-index: 1;
  position: absolute;
  left: 0;
  top: calc(100% + 1px);
  background-color: #fff;
  width: 100%;
  max-height: 250px;
  overflow: auto;
  box-shadow: 0 2px 14px 7px rgba(52, 51, 91, 0.1);
  & > div {
    padding: 0 16px;
    line-height: 2;
    &:hover {
      background-color: ${(props) => props.theme.colors.grayMainBg};
    }
  }
  img {
    margin: 10px 0;
    width: 50px;
    height: 50px;
  }
`;

const Select = styled.button`
  font-family: 'SB Sans Text';
  background-color: #fff;
  outline: none;
  resize: none;
  width: 100%;
  line-height: 20px;
  padding: 8px 46px 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  text-align: left;
  &::placeholder {
    font-size: 16px;
  }
  svg {
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
  }
  &.placeholder {
    color: ${(props) => props.color ?? props.theme.colors.graySubtitle};
    ${SelectList} {
      color: ${(props) => props.theme.colors.blackLabel};
    }
  }
  &.open > svg {
    transform: translateY(-50%) rotate(180deg);
  }
  &.margin {
    margin-bottom: 6px;
  }
`;

const SelectItem = styled.div`
  &:hover {
    background-color: ${(props) => props.theme.colors.grayBgButton};
  }
  ${(propsData: { disabled: boolean }) =>
    propsData.disabled &&
    css`
      background-color: ${(props) => props.theme.colors.grayBgButton};
      cursor: default;
      &:hover {
        background-color: ${(props) => props.theme.colors.grayBgButton};
      }
      div {
        color: ${(props) => props.theme.colors.graySubtitle};
      }
    `}
  & > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ${(propsData: { disabled: boolean; selected?: boolean }) =>
    propsData.selected === false &&
    css`
      & > div {
        color: ${(props) => props.theme.colors.graySubtitle};
      }
      img {
        filter: grayscale(1);
      }
    `}
`;

export const InputSelect = (props: Props) => {
  const {
    label,
    data,
    inputValue,
    setInputValue,
    multiple,
    hint,
    disabled,
    color,
  } = props;
  const { graySubtitle } = theme.colors;

  const [openSelect, setOpenSelect] = useState(false);

  const toggleSelect = () => {
    setOpenSelect((prev) => !prev);
  };

  const handleSelectChange = (selectData: SelectData, e: React.MouseEvent) => {
    if (multiple) e.stopPropagation();
    if (setInputValue && selectData) {
      setInputValue(selectData);
    }
  };

  return (
    <StyledInputSelect {...props}>
      {label && <Label>{label}</Label>}
      <Select
        type="button"
        className={[
          openSelect && 'open',
          inputValue?.id === '0' && 'placeholder',
          hint && 'margin',
        ].join(' ')}
        onClick={disabled ? () => {} : toggleSelect}
        onBlur={() => openSelect && toggleSelect()}
        color={color}
      >
        {inputValue?.name}
        {!disabled && <ArrowIcon />}
        {openSelect && (
          <SelectList>
            {data.map((item) => (
              <SelectItem
                key={uuidv4()}
                role="presentation"
                onClick={(e) => !item.disabled && handleSelectChange(item, e)}
                disabled={!!item.disabled}
                selected={item.selected}
              >
                <div>
                  <div>{item.name}</div>
                  {item.imageUrl && (
                    <img src={item.imageUrl} alt={item.imageUrl} />
                  )}
                </div>
              </SelectItem>
            ))}
          </SelectList>
        )}
      </Select>
      <Typography
        ff="SB Sans Text"
        fz={12}
        lh={16}
        ls="-0.02em"
        color={graySubtitle}
      >
        {hint}
      </Typography>
    </StyledInputSelect>
  );
};
