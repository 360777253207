type CrossIconProps = {
  onClick? : () => void
}

export const CrossIcon = ({ onClick }: CrossIconProps) => (
  <svg
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
  >
    <path
      d="M1.5 1.5L5 5M8.5 8.5L5 5M5 5L8.5 1.5L1.5 8.5"
      stroke="#85858F"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
