import styled from 'styled-components';

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  & > div:first-child {
    margin-bottom: 2.19px;
  }
  & > div:last-child {
    display: flex;
    align-items: center;
    & > span {
      font-family: 'SB Sans Text';
      font-size: 8.71856px;
      line-height: 11px;
      letter-spacing: -0.02em;
      color: ${(props) => props.theme.colors.grayInnerHint};
      &:first-of-type {
        margin: 0 7.47px;
      }
    }
  }
`;

type Props = { maxWidth?: number };
export const PageContent = styled.div<Props>`
  max-width: ${(props: Props) => `${props.maxWidth ?? 792}px`};
  margin: 0 auto;
`;

export const PageMain = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const IndicatorWrapper = styled.div`
  min-width: 61px;
  & > div {
    max-height: 5px;
    margin-bottom: 0;
  }
`;
