import styled from 'styled-components';

import ArrowRightIcon from '../../assets/icons/ArrowRightIcon';
import { theme } from '../../theme';
import { Indicator } from './Indicator';
import { Typography } from './Typography';

type Props = {
  xpCount: number;
  xpColor?: string;
  title: string;
  label1: string;
  label2?: string;
  theme: typeof theme;
};

const TaskItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  margin: auto;
`;

const IndicatorsBlock = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

interface xPBlockBgColor {
  xpColor?: string;
}

const IndicatorXPBlock = styled.div<xPBlockBgColor>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background: ${({ xpColor = '#3935FF' }) => xpColor};
  border-radius: 8px;
`;

const IndicatorInfoBlock = styled.div`
  height: 100%;
  padding: 0 7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const IndicatorBottomBlock = styled.div`
  padding: 0 7px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const ArrowBlock = styled.div`
  color: ${(props) => props.theme.colors.grayInnerHint};
  transition: all 0.3s ease;
  opacity: 0.4;
  align-self: center;
`;

const IndicatorWrapper = styled.div`
  min-width: 62px;
  background: #f4f4f4;
  margin-right: 7.5px;
  & > div {
    max-height: 5px;
    margin-bottom: 0;
  }
`;

export function GamificationTaskItem(props: Props) {
  const { xpCount, title, label1, label2, xpColor } = props;
  const { grayInnerHint, blackSidebar, greenIndicator, whiteBg } = theme.colors;
  return (
    <TaskItem>
      <IndicatorsBlock>
        <IndicatorXPBlock xpColor={xpColor}>
          <Typography fw={600} fz={7.5} lh={9} ls="-0.02em" color={whiteBg}>
            {xpCount}
          </Typography>
          <Typography fw={600} fz={5} lh={6} ls="-0.02em" color={whiteBg}>
            XP
          </Typography>
        </IndicatorXPBlock>

        <IndicatorInfoBlock>
          <Typography fz={12.4} lh={14} ls="-0.02em" color={blackSidebar}>
            {title}
          </Typography>
          <IndicatorBottomBlock>
            <IndicatorWrapper>
              <Indicator currentXP="2" levelXP="10" color={greenIndicator} />
            </IndicatorWrapper>

            <Typography
              fz={9}
              lh={11}
              mr={5}
              ls="-0.02em"
              color={grayInnerHint}
            >
              {label1}
            </Typography>
            <Typography fz={9} lh={11} ls="-0.02em" color={grayInnerHint}>
              {label2}
            </Typography>
          </IndicatorBottomBlock>
        </IndicatorInfoBlock>
      </IndicatorsBlock>
      <ArrowBlock {...{ blackSidebar }}>
        <ArrowRightIcon width={4} />
      </ArrowBlock>
    </TaskItem>
  );
}
