import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { SelectData } from '../../models';
import {
  achievementService,
  seasonService,
  actionService,
} from '../../services';
import { theme } from '../../theme';
import { useCheckIsSuperAdmin } from '../../hooks/useSuperAdminCheck';
import {
  ButtonsWrapper,
  Level,
  LevelsWrapper,
  ProductLabel,
  RewardCongratulationPreview,
  RewardShortPreview,
  SavedLabel,
  StarsWrapper,
  StyledPageLeftContent,
} from './Styled';
import {
  PageContent,
  PageMain,
} from '../../components/common/pageLayout/Shared';
import { Stars } from '../../assets/icons/Stars';
import gamificationMedal from '../../assets/images/gamificationMedal.png';
import sberagentReward from '../../assets/images/sberagentReward.png';
import {
  InputSelect,
  SelectList,
} from '../../components/common/inputComp/InputSelect';
import { IMG_URL } from '../../http';
import sberSound from '../../assets/images/sberSound.png';
import { RewardCreateCheckIcon } from '../../assets/icons/RewardCreateCheckIcon';
import { SelectMapAwords } from '../../components/common/SelectMapAwords';
import {
  PageHeader,
  PageLeftHalf,
  PageRightHalf,
  PageWrapper,
} from '../../components/common/pageLayout';
import { Typography } from '../../components/common/Typography';
import { InputText } from '../../components/common/inputComp/InputText';
import { ConfirmButton } from '../../components/common/buttonComp/ConfirmButton';

const defaultAward = {
  description: '',
  promocode: '',
  activation: '',
  fileUrl: '',
  imageFileKey: '',
  awardDescription: '',
};

const selectedAwardsInit = {
  id: '0',
  name: 'Выбрать награды',
};

const hasAwardsData = [
  {
    id: '0',
    name: 'Нет',
  },
  {
    id: '1',
    name: 'Да',
  },
];

type PlaceData = {
  name: string;
  awardDescription: string;
  congratulation: string;
  file: File | null;
  fileUrl: string;
  status: string;
  saved: boolean;
  awardsIds: number[];
  awardIndex?: number;
  action: {
    id: string;
    name: string;
  };
  actionsCount: number;
  description: string;
  description2: string;
  hasAwards: boolean;
};

export const MapRewardCreatePage = () => {
  const navigate = useNavigate();
  const {
    graySubtitle,
    grayInnerHint,
    blackSidebar,
    greenBg,
    statusBlueText,
    blackLabel,
    redBg,
    greenLightText,
    grayDarkBgButton,
  } = theme.colors;
  const isSuperAdmin = useCheckIsSuperAdmin();
  const reader = new FileReader();

  const [actionsData, setActionsData] = useState<SelectData[]>([]);

  const [mounted, setMounted] = useState(false);

  const [places, setPlaces] = useState<PlaceData[]>([]);

  const [placeIndex, setPlaceIndex] = useState(-1);
  const [previewBtnIdx, setPreviewBtnIdx] = useState(0);

  const [awardsData, setAwardsData] = useState<
    { id: string; name: string; imageUrl: string; selected: boolean }[]
  >([]);

  useEffect(() => {
    (async function fetchActions() {
      setMounted(true);
      const actions = (await actionService.getAll('4')).data;
      setActionsData(actions.map((el) => ({ id: el.actionId, name: el.name })));
    })();
  }, []);

  useEffect(() => {
    async function fetchData() {
      achievementService.getAll().then(({ data }) =>
        setAwardsData(
          data.map((t) => ({
            id: String(t.elementId),
            name: t.name,
            imageUrl: t.imageUrl,
            selected: false,
          }))
        )
      );
    }
    if (mounted) {
      fetchData();
    }
  }, [isSuperAdmin, mounted]);

  useEffect(() => {
    const newPlaces = new Array<PlaceData>(3);
    newPlaces.fill({
      status: 'FREE',
      name: '',
      awardDescription: '',
      congratulation: '',
      file: null,
      fileUrl: '',
      saved: false,
      awardsIds: [],
      action: {
        id: '0',
        name: 'Выберите действие',
      },
      actionsCount: 0,
      description: '',
      description2: '',
      hasAwards: false,
    });
    setPlaces(newPlaces);
  }, [mounted]);

  const handleTrophySelect = (v: SelectData) => {
    const newAwardsData = awardsData.map((award) => ({
      ...award,
      selected: award.id === v.id ? !award.selected : award.selected,
    }));
    setAwardsData(newAwardsData);
    setPlaces((prev) =>
      prev.map((pl, index) =>
        index === placeIndex
          ? {
              ...pl,
              awardsIds: newAwardsData
                .filter((el) => el.selected)
                .map((el) => +el.id),
            }
          : pl
      )
    );
  };

  const handleRewardCreate = () =>
    new Promise<void>((resolve) => {
      // try {
      //   if (!playbookId) return;
      //   Promise.all(
      //     awardData.map(async (el, index) => {
      //       const idx = places.findIndex((place) => place.awardIndex === index);
      //       const award = {
      //         awards: [
      //           {
      //             description: el.description,
      //             product: { id: +product.id, name: product.name },
      //             promocode: el.promocode,
      //             imageFileKey: el.imageFileKey,
      //             activationLink: el.activation,
      //             awardDescription: el.awardDescription,
      //           },
      //         ],
      //         level: idx !== -1 ? idx + 1 : 1,
      //       };
      //       return seasonService.createAward(award, playbookId);
      //     })
      //   )
      //     .then((data) => {
      //       if (data.every((el) => el.status === 200)) {
      //         resolve();
      //       }
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // } catch (error) {
      //   console.log(error);
      // }
    });

  const toggleSavePlace = (value: boolean) =>
    new Promise<void>((resolve) => {
      setPlaces((prev) =>
        prev.map((el, index) =>
          index === placeIndex ? { ...el, saved: value } : el
        )
      );
      resolve();
    });

  const handleChangePlaceField = (v: string | null, field: string) =>
    setPlaces((prev) =>
      prev.map((el, index) =>
        index === placeIndex ? { ...el, [field]: v } : el
      )
    );

  const handleAddFile = (fileData: any) => {
    const form = new FormData();
    let imageFileKey: string;
    form.append('file', fileData);

    seasonService.uploadImage(form).then((response) => {
      imageFileKey = response.data;
      handleChangePlaceField(`${IMG_URL}${imageFileKey}`, 'fileUrl');
    });

    if (fileData) {
      handleChangePlaceField(fileData, 'file');
      reader.readAsDataURL(fileData);
    }
  };

  const handleDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const fileData = e.dataTransfer.files[0];
    reader.readAsDataURL(fileData);
  };

  const handlePlaceSelect = (index: number) => {
    if (places[index].status === 'FREE') {
      setPlaceIndex(index);
      setAwardsData((prev) =>
        prev.map((el) => ({
          ...el,
          selected: places[index].awardsIds.includes(+el.id),
        }))
      );
      if (!places[index].saved) {
        setPlaces((prev) =>
          prev.map((p, i) =>
            i === index
              ? {
                  awardDescription: '',
                  name: '',
                  congratulation: '',
                  file: null,
                  fileUrl: '',
                  status: 'FREE',
                  saved: false,
                  awardsIds: [],
                  action: {
                    id: '0',
                    name: 'Выберите действие',
                  },
                  actionsCount: 0,
                  description: '',
                  description2: '',
                  hasAwards: false,
                }
              : p
          )
        );
      }
    }
  };

  const isValid = () => true;

  function getFormattedDate(date: string) {
    const years = date.slice(0, 4);
    const months = date.slice(5, 7);
    const days = date.slice(8, 10);
    return `${days}.${months}.${years}`;
  }

  return (
    <PageWrapper>
      <PageContent maxWidth={864}>
        <PageHeader
          onClick={() => navigate('/gamification')}
          title="Создание задания для карты"
          navButtons={[
            { to: '/map-reward-create', label: 'Создание' },
            { to: '/map-reward-archive', label: 'Уже созданные' },
          ]}
        />
        <PageMain>
          <PageLeftHalf>
            <StyledPageLeftContent>
              <>
                <Typography
                  ff="SB Sans Text"
                  lh={18}
                  ls="-0.02em"
                  color={blackSidebar}
                  mb={6}
                >
                  Выбор задания
                </Typography>
                <LevelsWrapper mb={placeIndex === -1 ? 20 : 7}>
                  {places.map((el, index) => (
                    <Level
                      key={uuidv4()}
                      type="button"
                      free={el.status === 'FREE'}
                      selected={index === placeIndex}
                      onClick={() => handlePlaceSelect(index)}
                    >
                      {index + 1}
                      {el.status === 'TAKEN' && (
                        <ProductLabel bgUrl={sberSound} />
                      )}
                      {index !== placeIndex && el.saved && (
                        <SavedLabel>
                          <RewardCreateCheckIcon />
                        </SavedLabel>
                      )}
                    </Level>
                  ))}
                </LevelsWrapper>
                {placeIndex === -1 && (
                  <Typography
                    fz={12}
                    lh={16}
                    ff="SB Sans Text"
                    ls="-0.02em"
                    color={graySubtitle}
                  >
                    Каждая цифра — это одно задание в карте. Выберите цифру,
                    затем заполните формы ниже, чтобы создать задание.
                  </Typography>
                )}
                <InputText
                  label="Название задания"
                  inputValue={places[placeIndex] ? places[placeIndex].name : ''}
                  placeholder="Например, залайкай все на свете"
                  innerHint="максимум Х символов"
                  setInputValue={(v) => handleChangePlaceField(v, 'name')}
                  maxLength={500}
                  multiple
                  mb={20}
                />
                <InputSelect
                  data={actionsData}
                  inputValue={
                    places[placeIndex]
                      ? places[placeIndex].action
                      : {
                          id: '0',
                          name: 'Выберите действие',
                        }
                  }
                  setInputValue={(v) => handleChangePlaceField(v, 'action')}
                  label="Действия"
                  hint="Какое действие нужно выполнить"
                  mb={20}
                />
                <InputText
                  inputValue={
                    places[placeIndex]
                      ? `${places[placeIndex].actionsCount}`
                      : ''
                  }
                  setInputValue={(v) =>
                    handleChangePlaceField(v, 'actionsCount')
                  }
                  placeholder="Количество повторов"
                  hint="Сколько раз пользователь должен будет выполнить задание, чтобы получить награду."
                  min={1}
                  max={999999}
                  mb={20}
                />
                <InputText
                  label="Описание"
                  inputValue={
                    places[placeIndex] ? places[placeIndex].description : ''
                  }
                  placeholder="Нарративное описание"
                  innerHint="максимум Х символов"
                  setInputValue={(v) =>
                    handleChangePlaceField(v, 'description')
                  }
                  maxLength={500}
                  minHeight={120}
                  multiple
                  mb={6}
                />
                <Typography
                  fz={12}
                  lh={16}
                  ff="SB Sans Text"
                  ls="-0.02em"
                  color={graySubtitle}
                  mb={20}
                >
                  Напишите, как по сюжету это задание влияет на путь
                  пользователя
                </Typography>
                <InputText
                  inputValue={
                    places[placeIndex] ? places[placeIndex].description2 : ''
                  }
                  placeholder="Сутевое описание"
                  innerHint="максимум Х символов"
                  setInputValue={(v) =>
                    handleChangePlaceField(v, 'description2')
                  }
                  maxLength={500}
                  minHeight={120}
                  multiple
                  mb={6}
                />
                <Typography
                  fz={12}
                  lh={16}
                  ls="-0.02em"
                  color={graySubtitle}
                  mb={16}
                >
                  Напишите, как по сюжету это задание влияет на путь
                  пользователя
                </Typography>
                <InputSelect
                  data={hasAwardsData}
                  inputValue={
                    places[placeIndex] && places[placeIndex].hasAwards
                      ? hasAwardsData[1]
                      : hasAwardsData[0]
                  }
                  setInputValue={(v) => handleChangePlaceField(v, 'hasAwards')}
                  label="Награда за задание"
                  mb={6}
                />
                {/* <InputText
                  label="Поздравление после получения награды"
                  inputValue={places[placeIndex].congratulation}
                  placeholder="Например, Футболка уже в гардеробе вашего аватара. Примерим её?"
                  innerHint="максимум Х символов"
                  setInputValue={(v) =>
                    handleChangePlaceField(v, 'congratulation')
                  }
                  maxLength={500}
                  minHeight={120}
                  multiple
                  mb={6}
                /> */}
                <Typography
                  fz={12}
                  lh={16}
                  ls="-0.02em"
                  color={graySubtitle}
                  mb={19}
                >
                  Решите будет ли награда за задание
                </Typography>
                {places[placeIndex] && places[placeIndex].hasAwards && (
                  <SelectMapAwords />
                )}
                {/* <InputFile
                  label="Иллюстрация"
                  placeholder="Прикрепите изображение награды
                        в формате PNG или JPG"
                  handleAddFile={(e) => handleAddFile(e)}
                  handleDropFile={handleDropFile}
                  fileUrl={places[placeIndex].fileUrl}
                  file={places[placeIndex].file}
                  handleFileRemove={() => {
                    handleChangePlaceField('', 'fileUrl');
                    handleChangePlaceField(null, 'file');
                  }}
                  mb={20}
                /> */}
                <ButtonsWrapper>
                  <ConfirmButton
                    onClick={() => toggleSavePlace(true)}
                    text="Сохранить"
                    background={greenBg}
                    disable={false}
                  />
                  <ConfirmButton
                    onClick={() => toggleSavePlace(false)}
                    text="Отменить"
                    paddingBlock={9}
                    background="transparent"
                    border={redBg}
                    color={redBg}
                    disable={false}
                  />
                </ButtonsWrapper>
                <ConfirmButton
                  onClick={handleRewardCreate}
                  text="Создать все сохранённые награды"
                  modalMessage="Сезонная награда
                создана и добавлена в виджет"
                  modalBtnText="Хорошо"
                  mt={10}
                  disable={!isValid()}
                />
              </>
            </StyledPageLeftContent>
          </PageLeftHalf>
          <PageRightHalf
            maxWidth={472}
            description="Так награда будет выглядеть в виджете"
            buttons={[
              {
                label: 'Название',
                callback: () => setPreviewBtnIdx(0),
              },
              {
                label: 'Описание',
                callback: () => setPreviewBtnIdx(1),
              },
              {
                label: 'Поздравление',
                callback: () => setPreviewBtnIdx(2),
              },
            ]}
            currentBtnIdx={previewBtnIdx}
          >
            {previewBtnIdx === 0 && (
              <RewardShortPreview>
                <div>
                  <StarsWrapper>
                    <Stars w="124" h="124" />
                  </StarsWrapper>
                  <img
                    src={
                      placeIndex === -1 || !places[placeIndex].fileUrl.length
                        ? gamificationMedal
                        : places[placeIndex].fileUrl
                    }
                    alt="RewardPreview"
                  />
                </div>
                <div>
                  <Typography fz={20} lh={25} ls="-0.02em" color={blackSidebar}>
                    {placeIndex === -1 || places[placeIndex].name.length === 0
                      ? 'Название награды'
                      : places[placeIndex].name}
                  </Typography>
                  <Typography
                    fz={12}
                    lh={15}
                    ls="-0.02em"
                    color={grayInnerHint}
                  >
                    100 XP
                  </Typography>
                </div>
              </RewardShortPreview>
            )}
            {/* {previewBtnIdx === 1 && <RewardDetailedPreview />} */}
            {previewBtnIdx === 2 && (
              <RewardCongratulationPreview>
                <img src={sberagentReward} alt="rewardPreview" />
                <Typography
                  ff="SB Sans Text"
                  fw={600}
                  fz={24}
                  ls="-0.02em"
                  lh="110%"
                  mb={24}
                  center
                >
                  Футболка «СберАгент» для аватара
                </Typography>
                <Typography
                  ff="SB Sans Text"
                  fz={16}
                  ls="-0.02em"
                  lh={20}
                  mb={24}
                  center
                >
                  Отличное начало! Вы получили эту награду за выполнение первого
                  задания.
                </Typography>
                <Typography
                  ff="SB Sans Text"
                  fz={16}
                  ls="-0.02em"
                  lh={20}
                  center
                  color={grayInnerHint}
                >
                  Получено 20 июля 2022
                </Typography>
              </RewardCongratulationPreview>
            )}
          </PageRightHalf>
        </PageMain>
      </PageContent>
    </PageWrapper>
  );
};
