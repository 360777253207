import styled, { css } from 'styled-components';
import { Stars } from '../../assets/icons/Stars';
import { Shape } from '../../assets/icons/Shape';
import sberagentTShirt from '../../assets/images/sberagentTShirt.png';
import sberagentRewardMini from '../../assets/images/sberagentRewardMini.png';
import { CheckIcon } from '../../assets/icons/CheckIcon';
import Season2logo from '../../assets/images/season2logo.png';
import Season3logo from '../../assets/images/season3logo.png';
import { theme } from '../../theme';
import { Logo } from './Logo';
import { Typography } from './Typography';

type Props = { isSeason?: boolean };

const RewardWrapper = styled.div`
  position: relative;
  height: 353px;
  display: flex;
  flex-direction: row;
  width: 242px;
  align-items: flex-start;
  overflow: hidden;
`;

const Gradient = styled.div`
  position: absolute;
  top: 104px;
  z-index: 1;
  width: 242px;
  left: 0;
  height: 180px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) -4.13%,
    #ffffff 85.49%
  );
`;

const LeftColumn = styled.div`
  width: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  margin-top: 52px;
`;

const RightColumn = styled.div`
  margin-top: 33px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`;

const Reward = styled.div<{ opacity?: number }>`
  opacity: ${({ opacity }) => (opacity ? `${opacity}` : 1)};
  height: 72px;
  width: 100%;
  position: relative;
`;

const Tint = styled.div`
  background: #d9d9d9;
  mix-blend-mode: color;
  width: 72px;
  height: 72px;
`;

const Vertical = styled.div`
  position: absolute;
  left: 77px;
  top: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 124px;
`;

const Circle = styled.div<{
  color?: string;
  border?: string;
  isSeason?: boolean;
}>`
  background: ${({ color }) => color ?? '#90d128'};
  border: ${({ border }) => border ?? 'none'};
  display: flex;
  border-radius: 100px;
  width: 23px;
  height: 23px;
  ${({ isSeason = false }) =>
    isSeason &&
    css`
      transform: scale(0.7);
    `};
`;

const GreenXPStepBlock = styled.div<{ color: string; isSeason?: boolean }>`
  background: ${({ color }) => color ?? '#90d128'};
  display: flex;
  border-radius: 25.8169px;
  width: 21.24px;
  height: 11.46px;
  color: #fff;
  ${({ isSeason = false }) =>
    isSeason &&
    css`
      transform: scale(0.85);
    `};
`;

const Centred = styled.div`
  margin: auto;
`;

const Filter = styled.div<{ brightness?: string }>`
  filter: brightness(${({ brightness }) => brightness ?? '130%'}) grayscale(1);
  opacity: 0.6;
`;

const Line = styled.div<{ color?: string; height?: number }>`
  width: 0;
  height: ${({ height }) => (height ? `${height}px` : '24px')};
  border-left: 1.15px solid ${({ color }) => color ?? '#90d128'};
  box-sizing: border-box;
`;

const Divider = styled.div<{ mb?: number }>`
  width: 184px;
  height: 0;
  border-top: 1px solid ${(props) => props.theme.colors.grayInputBorder};

  margin-bottom: ${({ mb }) => mb ?? '14px'};
`;

export function GamificationRewardImg({ isSeason = false }: Props) {
  const {
    grayBg,
    greenIndicator,
    grayInnerHint,
    blackSidebar,
    grayProcess,
    grayInputBorder,
  } = theme.colors;
  return (
    <RewardWrapper>
      <Gradient />
      <LeftColumn>
        <Circle color={greenIndicator} isSeason={isSeason}>
          <Centred>
            <CheckIcon />
          </Centred>
        </Circle>
        <Line />
        <GreenXPStepBlock color={greenIndicator} isSeason={isSeason}>
          <Centred>
            <Typography fw={600} fz={6} lh={8} ls="-0.02em">
              100
            </Typography>
          </Centred>
        </GreenXPStepBlock>
        <Line color={grayInputBorder} />
        <Circle
          color={grayBg}
          border={`2px solid ${grayProcess};
border-radius: 100px;`}
          isSeason={isSeason}
        >
          <Centred>
            <Typography
              fw={600}
              fz={8}
              lh={10}
              ls="-0.02em"
              color={grayProcess}
            >
              2
            </Typography>
          </Centred>
        </Circle>
        <Line color={grayInputBorder} height={60} />
        <Circle
          color={grayBg}
          border={`2px solid ${grayProcess};
border-radius: 100px;`}
          isSeason={isSeason}
        >
          <Centred>
            <Typography
              fw={600}
              fz={8}
              lh={10}
              ls="-0.02em"
              color={grayProcess}
            >
              3
            </Typography>
          </Centred>
        </Circle>
        <Line color={grayInputBorder} />
      </LeftColumn>
      <RightColumn>
        <Reward>
          <Logo left={6} top={-8}>
            <Stars />
          </Logo>

          {isSeason ? (
            <Logo top={10} left={7}>
              <img
                src={sberagentRewardMini as string}
                alt="sberagentReward"
                width="65px"
                // height="65px"
              />
            </Logo>
          ) : (
            <Logo top={7} left={-3}>
              <img
                src={sberagentTShirt as string}
                alt="sberagent"
                width="65px"
                height="65px"
              />
            </Logo>
          )}

          <Vertical>
            <Typography
              fz={8.08}
              lh={10.14}
              ls="-0.02em"
              mb={3}
              color={blackSidebar}
            >
              {isSeason
                ? ' Футболка «СберАгент» для аватара'
                : 'Диджитал-футболка «Пульс» для аватара'}
            </Typography>
            <Typography fz={7} lh={9} ls="-0.02em" color={grayInnerHint}>
              100 XP
            </Typography>
          </Vertical>
        </Reward>
        <Divider />
        <Reward>
          <Logo left={4} top={-10}>
            <Filter brightness="100%">
              <Shape />
            </Filter>
          </Logo>
          <Logo left={-6} top={6}>
            <Filter>
              <img
                src={Season2logo as string}
                alt="season level 2 reward"
                width="67px"
                height="60px"
              />
            </Filter>
          </Logo>
          <Logo left={6}>{/* <Tint /> */}</Logo>
          <Vertical>
            <Typography fz={8} lh={10} ls="-0.02em" mb={3} color={blackSidebar}>
              500 рублей от СберАгента
            </Typography>
            <Typography fz={7} lh={8.5} ls="-0.02em" color={grayInnerHint}>
              100 XP
            </Typography>
          </Vertical>
        </Reward>

        <Divider mb={5} />
        <Reward>
          <Logo left={6}>
            <Stars />
          </Logo>
          <Logo top={11} left={-3}>
            <img
              src={Season3logo as string}
              alt="season level 3 reward"
              width="72px"
            />
          </Logo>{' '}
          <Logo left={6}>
            <Tint />
          </Logo>
          <Vertical>
            <Typography fz={8} lh={10} ls="-0.02em" mb={3} color={blackSidebar}>
              Комплект одежды Playground для аватара
            </Typography>
            <Typography fz={7} lh={8.5} ls="-0.02em" color={grayInnerHint}>
              100 XP
            </Typography>
          </Vertical>
        </Reward>
      </RightColumn>
    </RewardWrapper>
  );
}
