import { useState, useEffect, Dispatch, SetStateAction } from 'react';

type ValidationRules = {
  required: true;
  format: null | 'email';
};

export function useStateValidation(
  initialState: string | (() => string)
): [
  string,
  Dispatch<SetStateAction<string>>,
  boolean,
  Dispatch<SetStateAction<boolean>>,
  Dispatch<SetStateAction<ValidationRules>>,
  Dispatch<SetStateAction<string[]>>,
  string[]
] {
  const [value, setValue] = useState<string>(initialState);
  const [touched, setTouched] = useState<boolean>(false);
  const [validationRules, setValidationRules] = useState<ValidationRules>({
    required: true,
    format: null,
  });
  const [errors, setErrors] = useState<string[]>([]);

  const isEmailFormat = (val: string) => {
    const reg = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i; // формат email
    return reg.test(val);
  };

  useEffect(() => {
    setErrors([]);

    if (validationRules.format === 'email' && !isEmailFormat(value))
      setErrors(['Неверный формат почты']);
    if (validationRules.required && value === '')
      setErrors(['Обязательный реквизит']);
  }, [value, validationRules]);

  return [
    value,
    setValue,
    touched,
    setTouched,
    setValidationRules,
    setErrors,
    errors,
  ];
}
