import styled, { css } from 'styled-components';

export const LevelsWrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 398px;
  margin-bottom: 24px;
`;

export const Level = styled.button`
  background: ${(props) => props.theme.colors.grayBgButton};
  border: 2px solid ${(props) => props.theme.colors.grayBorderButton};
  ${(props: { free?: boolean; selected?: boolean }) =>
    props.free &&
    css`
      cursor: pointer;
      background: #fff;
    `}
  ${(propList: { free?: boolean; selected?: boolean }) =>
    propList.selected &&
    css`
      background: ${(props) => props.theme.colors.greenBg};
      cursor: pointer;
    `}
  border-radius: 37px;
  width: 50px;
  height: 50px;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
