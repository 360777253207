import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectData } from '../models';
import { productService, mapService, seasonService } from '../services';
import { useCheckIsSuperAdmin } from '../hooks/useSuperAdminCheck';
import {
  PageHeader,
  PageLeftHalf,
  PageWrapper,
} from '../components/common/pageLayout';
import { Typography } from '../components/common/Typography';
import { InputSelect } from '../components/common/inputComp/InputSelect';
import { InputText } from '../components/common/inputComp/InputText';
import { ConfirmButton } from '../components/common/buttonComp/ConfirmButton';
import { InputFile } from '../components/common/inputComp/InputFile';

const TaskActiveCheckbox = styled.div`
  display: flex;
  margin-bottom: 40px;
  input {
    cursor: pointer;
    margin-right: 10px;
  }
  label {
    margin-bottom: -2px;
    font-weight: 600;
    font-size: 18px;
    cursor: pointer;
    margin-top: -4px;
  }
`;
const StyledPageLeftContent = styled.div`
  max-width: 436px;
`;

export const MapAwardCreatePage = () => {
  const isSuperAdmin = useCheckIsSuperAdmin();
  const navigate = useNavigate();
  const reader = new FileReader();
  const [productsData, setProductsData] = useState<SelectData[]>([]);
  const [product, setProduct] = useState({ id: '0', name: 'Выберите продукт' });
  const [name, setName] = useState('');
  const [descriptionAfterReceive, setDescriptionAfterReceive] = useState('');
  const [descriptionToReceive, setDescriptionToReceive] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [mounted, setMounted] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [isPrime, setIsPrime] = useState(false);
  const [message, setMessage] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  reader.onload = (e: ProgressEvent<FileReader>) => {
    if (typeof e.target?.result === 'string') {
      setFileUrl(e.target?.result);
    }
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const productsRes = (await productService.getAll()).data;
      const products = productsRes.map((el) => ({
        id: String(el.id),
        name: el.name,
        disabled: el.id !== 4,
      }));
      setProductsData(products);
      if (!isSuperAdmin)
        setProduct(products[products.findIndex((el) => el.id === '1')]);
    }
    if (mounted) fetchData();
  }, [isSuperAdmin, mounted]);

  const handleAddFile = (fileData: any) => {
    setMessage('Изображение загружается на сервер, пожалуйста подождите.');
    const form = new FormData();
    form.append('file', fileData);
    seasonService.uploadImage(form).then((response) => {
      setImageUrl(response.data);
      setIsEnabled(true);
      setMessage('Изображение загружено.');
    });
    if (fileData) {
      setFile(fileData);
      reader.readAsDataURL(fileData);
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setFileUrl(null);
  };

  const handleDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const fileData = e.dataTransfer.files[0];
    reader.readAsDataURL(fileData);
    handleAddFile(fileData);
  };

  const clearState = () => {
    setProduct({ id: '0', name: 'Выберите продукт' });
    setDescriptionToReceive('');
    setDescriptionAfterReceive('');
    setIsPrime(false);
    setName('');
    setFile(null);
    setFileUrl(null);
  };

  const handleAchievementCreate = () =>
    new Promise<void>((resolve) => {
      setIsEnabled(false);
      const MapAwardDto = {
        congratulation: descriptionAfterReceive,
        description: descriptionToReceive,
        imageFileKey: imageUrl,
        isPrime,
        name,
        productId: Number(product.id),
      };
      mapService.createMapAward(MapAwardDto).then((response) => {
        if (response.status === 200) {
          resolve();
          clearState();
        }
      });
    });

  const isValid = () =>
    product.id !== '0' &&
    descriptionToReceive &&
    descriptionAfterReceive &&
    !!name.length &&
    isEnabled;

  return (
    <PageWrapper>
      <PageLeftHalf>
        <StyledPageLeftContent>
          <PageHeader
            onClick={() => navigate('/gamification')}
            title="Создание награды за цепочку заданий"
            mb={isSuperAdmin === false ? 16 : undefined}
          />
          {!isSuperAdmin && (
            <Typography
              color="#000000"
              ff="SB Sans Text"
              fw={600}
              fz={20}
              lh={30}
              mb={24}
            >
              Сберзвук
            </Typography>
          )}
          {isSuperAdmin && (
            <InputSelect
              data={productsData}
              inputValue={product}
              setInputValue={setProduct}
              label="Продукт"
              mb={23}
            />
          )}
          <InputText
            label="Название награды"
            inputValue={name}
            setInputValue={setName}
            placeholder="Введите текст"
            mb={23}
          />

          <InputText
            label="Цель задания"
            inputValue={descriptionToReceive}
            setInputValue={setDescriptionToReceive}
            placeholder="Что нужно сделать для получения"
            mb={23}
          />
          <InputText
            label="За что получил"
            inputValue={descriptionAfterReceive}
            setInputValue={setDescriptionAfterReceive}
            placeholder="Что сделал чтобы получить"
            mb={40}
          />
          <TaskActiveCheckbox>
            <input
              id="isActive"
              type="checkbox"
              value={String(isPrime)}
              onChange={() => setIsPrime((prev) => !prev)}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="isActive">Прайм</label>
          </TaskActiveCheckbox>
          <InputFile
            label="Иллюстрация"
            placeholder="Загрузите в это поле изображение в формате PNG или JPG"
            handleAddFile={handleAddFile}
            handleDropFile={handleDropFile}
            fileUrl={fileUrl}
            file={file}
            handleFileRemove={handleFileRemove}
            mb={10}
          />
          {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
          {message !== '' && <>{message}</>}
          <ConfirmButton
            onClick={handleAchievementCreate}
            text="Создать награду"
            mt={40}
            modalMessage="Награда создана"
            modalBtnText="Хорошо"
            disable={!isValid()}
          />
        </StyledPageLeftContent>
      </PageLeftHalf>
    </PageWrapper>
  );
};
