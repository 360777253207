export const Shape = ({ w = '71', h = '71' }: { w?: string; h?: string }) => (
  <svg
    width={w}
    height={h}
    viewBox="0 0 73 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.3171 12.965C37.517 10.7637 41.2201 11.3234 42.6712 14.0766L49.1456 26.3612C49.3619 26.7715 49.6387 27.1469 49.9668 27.4748L59.7889 37.2909C61.9902 39.4909 61.4304 43.1939 58.6772 44.645L46.3927 51.1194C45.9823 51.3357 45.6069 51.6125 45.279 51.9406L35.4629 61.7627C33.263 63.964 29.5599 63.4042 28.1089 60.651L21.6344 48.3665C21.4181 47.9562 21.1413 47.5807 20.8132 47.2529L10.9912 37.4368C8.78985 35.2368 9.34961 31.5337 12.1028 30.0827L24.3874 23.6082C24.7977 23.392 25.1731 23.1151 25.501 22.7871L35.3171 12.965Z"
      fill="#90D128"
    />
  </svg>
);
