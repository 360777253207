import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

type Props = {
  text: string;
  onClick?: () => void;
  color?: string;
  link?: string;
  noBorder?: boolean;
};

const StyledButton = styled.button`
  background-color: ${(props: Props) => props.color ?? '#fff'};
  border: 1px solid
    ${(props) =>
      props.noBorder
        ? props.color ?? '#fff'
        : props.theme.colors.grayBorderButton};
  align-items: center;
  padding: 14px 32px;
  /* width: 175px;
  height: 48px; */
  border-radius: 10px;
  cursor: pointer;
`;

const Text = styled.div`
  color: #ffffff;
  display: inline-block;
  line-height: 20px;
  letter-spacing: -0.02em;
`;

export const GamificationTaskButton = (props: Props) => {
  const { text, link, onClick } = props;
  const navigate = useNavigate();

  const buttonClickHandler = () => {
    if (onClick) {
      onClick();
    }
    if (link) {
      navigate(link);
    }
  };

  return (
    <StyledButton {...props} onClick={buttonClickHandler}>
      <Text>{text}</Text>
    </StyledButton>
  );
};
