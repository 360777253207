export const theme = {
  colors: {
    greenTitle: '#21A038',
    greenBg: '#27C498',
    greenLightBg: '#CEFFE8',
    greenLightText: '#008D4B',
    greenIndicator: '#90D128',
    grayTitle: '#999999',
    graySubtitle: '#8E8E8E',
    grayBorderButton: '#E4E5E7',
    grayInputBorder: '#D4D4D4',
    grayBorderBox: '#D0D0D0',
    grayBgButton: '#F6F6F6',
    grayDarkBgButton: '#3E4149',
    grayBgBox: '#080808',
    blackcurrant: '#060207',
    grayText: '#EBEBEB',
    grayHint: '#AEB1B0',
    grayDark: '#85858F',
    grayBg: '#f4f4f4',
    grayMainBg: '#f3f3f3',
    grayInnerHint: '#BDBDBD',
    grayPreviewContent: '#FBFBFB',
    grayProcess: '#D5D5D5',
    grayHeaderText: '#9D9D9D',
    whiteBg: '#ffffff',
    grayPreviewSubtitle: 'rgba(80, 57, 57, 0.6)',
    dateArrowIcon: '#BEBEBE',
    blackLabel: '#323232',
    blackBgButton: '#3E4149',
    blackSidebar: '#212121',
    blackPlaygroundTitle: '#020814',
    blackPlaygroundLinkTitle: '#171725',
    blackPlaygroundLinkText: 'rgba(23, 23, 37, 0.6)',
    orange: '#FF7866',
    orangeLightBg: '#FFEFE0',
    orangeLightText: '#FF881B',
    redBg: '#FA4444',
    questionPurpleBg: '#572FC8',
    questionDarkPurpleBg: '#54008C',
    questionRedBg: '#FF335F',
    previewSubtitle: 'rgba(0, 0, 0, 0.6)',
    redBanner: '#F74949',
    yellowBanner: '#FFCF72',
    statusBlueText: '#4657F3',
  },
};
