import api from '../http';
import { Achievement, UpdateAchievement } from '../models';

export const achievementService = {
  create: (achievement: FormData) =>
    api.post<Achievement>('playbooks/achievements/', achievement),
  update: (achievement: UpdateAchievement) =>
    api.put<Achievement>('playbooks/achievements/', achievement),
  getAll: (page = 1, size = 10) =>
    api.get<Achievement[]>(
      `playbooks/achievements/all?page=${page}&size=${size}`
    ),
  getByPlaybookId: (playbookId: number) =>
    api.get<Achievement>(`playbooks/achievements/playbookId=${playbookId}`),
  deleteByPlaybookId: (playbookId: number) =>
    api.delete(`playbooks/tasks/playbookId=${playbookId}`),
};
