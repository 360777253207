import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { mapService } from '../services';
import { getClaimDateFormat, stringToTimestamp } from '../utils';
import { MapArchive, MapArchiveField } from '../models';
import { PageHeader, PageWrapper } from '../components/common/pageLayout';
import { InputControls } from '../components/common/inputComp/InputControls';

const StyledMapWrapper = styled.div`
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 1440px;
  min-height: 100vh;
  overflow: auto;
  background: #f3f3f3;
`;

const PageMain = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Thead = styled.thead`
  background-color: ${(props) => props.theme.colors.whiteBg};
  tr {
    color: ${(props) => props.theme.colors.grayHeaderText};
    background-color: ${(props) => props.theme.colors.whiteBg};
    th {
      font-style: normal;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: ${(props) => props.theme.colors.grayHeaderText};
    }
    th:first-child {
      border-radius: 8px 0 0 8px;
    }
    th:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

const Tbody = styled.tbody`
  tr {
    background-color: ${(props) => props.theme.colors.whiteBg};
    cursor: pointer;
    line-height: 24px;
    letter-spacing: -0.01em;
    td:first-child {
      border-radius: 10px 0 0 10px;
    }
    td:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
`;

const Tr = styled.tr`
  text-align: left;
`;

const Th = styled.th`
  padding: 9px 16px;
  & > div {
    position: relative;
    width: fit-content;
    padding-right: 12px;
  }
`;

const Td = styled.td`
  padding: 15px 16px;
  color: ${(props) => props.theme.colors.blackLabel};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
`;

const columns = [
  {
    field: 'name',
    label: 'Название',
    controls: true,
    sortType: 'lowCaseString',
  },
  {
    field: 'createdDate',
    label: 'Дата создания',
    controls: true,
    sortType: 'lowCaseString',
  },
  {
    field: 'elementCount',
    label: 'Количество заданий',
    controls: true,
    sortType: 'number',
  },
] as {
  field: MapArchiveField;
  label: string;
  controls: boolean;
  sortType: string;
}[];

type MapListItem = MapArchive & {
  id: number;
};

const PageContent = styled.div`
  max-width: 1300px;
  margin: 0 auto;
`;

export const MapArchivePage = () => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState<{
    field: MapArchiveField;
    dir: 'desc' | 'asc';
    sortType: string;
  }>({
    field: 'createdDate',
    dir: 'desc',
    sortType: 'date',
  });
  const [maps, setMap] = useState<MapListItem[]>([]);

  useEffect(() => {
    mapService.getAllMaps().then(({ data }) => {
      setMap(
        data.map((map) => ({
          id: map.id,
          name: map.name,
          createdDate: getClaimDateFormat(map.createdDate),

          elementCount: map.elementCount,
        }))
      );
    });
  }, []);

  const filteredAndSortedMap = useCallback(() => {
    const sorted = maps.sort((a, b) => {
      if (filter.sortType === 'number') {
        if (+a[filter.field] > +b[filter.field])
          return filter.dir === 'desc' ? -1 : 1;
        if (+a[filter.field] < +b[filter.field])
          return filter.dir === 'desc' ? 1 : -1;
      }

      if (filter.sortType === 'lowCaseString') {
        if (
          (a[filter.field] as string).toLowerCase() >
          (b[filter.field] as string).toLowerCase()
        )
          return filter.dir === 'desc' ? -1 : 1;
        if (
          (a[filter.field] as string).toLowerCase() <
          (b[filter.field] as string).toLowerCase()
        )
          return filter.dir === 'desc' ? 1 : -1;
      }

      if (filter.field === 'createdDate') {
        if (
          stringToTimestamp(a[filter.field]) <
          stringToTimestamp(b[filter.field])
        )
          return filter.dir === 'desc' ? -1 : 1;
        if (
          stringToTimestamp(a[filter.field]) >
          stringToTimestamp(b[filter.field])
        )
          return filter.dir === 'desc' ? 1 : -1;
      }
      if (a[filter.field] < b[filter.field])
        return filter.dir === 'desc' ? -1 : 1;
      if (a[filter.field] > b[filter.field])
        return filter.dir === 'desc' ? 1 : -1;
      return 0;
    });
    console.log(sorted);
    return sorted;
  }, [maps, filter]);

  const buttonClickHandler = (id: string) => {
    console.log(id);
    // navigate(`${id}`);
  };

  return (
    <PageWrapper>
      <PageContent>
        <PageHeader
          onClick={() => navigate('/gamification')}
          title="Созданные карты"
          archivePage
          navButtons={[
            { to: '/map-create', label: 'Создание' },
            { to: '/map-archive', label: 'Уже созданные' },
          ]}
        />
        <PageMain>
          <StyledMapWrapper>
            <Table>
              <Thead>
                <Tr>
                  {columns.map((column) => (
                    <Th key={uuidv4()}>
                      <div>
                        {column.label}
                        {column.controls && (
                          <InputControls
                            handleStepUpClick={() =>
                              setFilter({
                                field: column.field,
                                dir: 'desc',
                                sortType: column.sortType,
                              })
                            }
                            handleStepDownClick={() =>
                              setFilter({
                                field: column.field,
                                dir: 'asc',
                                sortType: column.sortType,
                              })
                            }
                          />
                        )}
                      </div>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {filteredAndSortedMap().map((map) => (
                  <Tr
                    key={uuidv4()}
                    onClick={() => buttonClickHandler(String(map.id))}
                  >
                    <Td>{map.name}</Td>
                    <Td>{map.createdDate}</Td>
                    <Td>{map.elementCount}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </StyledMapWrapper>
        </PageMain>
      </PageContent>
    </PageWrapper>
  );
};
