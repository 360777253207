import { ReactNode } from 'react';
import styled from 'styled-components';
import { GamificationTaskButton } from './GamificationTaskButton';
import { Typography } from './Typography';

type Props = {
  children: ReactNode;
  mt: number;
  taskType: string;
  taskDescription: string;
  buttonLink: string;
  buttonText: string;
  headerHeight?: number;
};

const Card = styled.div`
  width: 360px;
  height: 507px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 20px 0 28px;
  position: relative;
`;
const CardHeader = styled.div`
  width: 312px;
  height: ${(props: Props) => `${props.headerHeight ?? 132}px`};
  margin-top: ${(props: Props) => `${props.mt}px`};
  background: #ffffff;
`;

const CardBody = styled.div`
  width: 304px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  top: 305px;
  background: transparent;
  position: absolute;
`;

export function GamificationTask(props: Props) {
  const { children, taskType, taskDescription, buttonLink, buttonText } = props;
  return (
    <Card>
      <CardHeader {...props}>{children}</CardHeader>
      <CardBody>
        <Typography fw={600} fz={20} lh={26} ls="-0.01em" color="#000000">
          {taskType}
        </Typography>
        <div style={{ textAlign: 'center' }}>
          <Typography lh={18} ls="-0.01em" color="#323232">
            {taskDescription}
          </Typography>
        </div>
        <GamificationTaskButton
          color="#3E4149"
          link={buttonLink}
          text={buttonText}
          noBorder
        />
      </CardBody>
    </Card>
  );
}
