import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import createMedalName from '../assets/images/createMedalName.png';
import createMedalDescription from '../assets/images/createMedalDescription.png';
import createMedalCongratulation from '../assets/images/createMedalCongratulation.png';
import { SelectData } from '../models';
import { productService, actionService, achievementService } from '../services';
import { useCheckIsSuperAdmin } from '../hooks/useSuperAdminCheck';
import {
  IndicatorWrapper,
  PageContent,
  PageMain,
} from '../components/common/pageLayout/Shared';
import { theme } from '../theme';
import {
  PageHeader,
  PageLeftHalf,
  PageRightHalf,
  PageWrapper,
} from '../components/common/pageLayout';
import { InputSelect } from '../components/common/inputComp/InputSelect';
import { InputText } from '../components/common/inputComp/InputText';
import { Typography } from '../components/common/Typography';
import { ConfirmButton } from '../components/common/buttonComp/ConfirmButton';
import { InputFile } from '../components/common/inputComp/InputFile';
import { Indicator } from '../components/common/Indicator';

const StyledPageLeftContent = styled.div`
  max-width: 436px;
`;

const AchievementNamePreview = styled.div`
  margin: 40px auto 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  img {
    width: 150px;
    height: 150px;
    margin-bottom: 8px;
  }
`;

const AchievementDescriptionPreview = styled.div`
  margin: 24px auto 122px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  max-width: 320px;
  img {
    width: 232px;
    height: 232px;
    margin-bottom: 18px;
    filter: grayscale(1);
  }
  div:nth-child(3) {
    width: 156px;
    margin-bottom: 56px;
  }
`;

const AchievementCongratulationPreview = styled.div`
  margin: 0 auto 122px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  max-width: 320px;
`;

const Banner = styled.div`
  background-color: ${(props) => props.theme.colors.yellowBanner};
  border-radius: 0px 0px 24px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 156px;
  min-height: 263px;
  margin-bottom: 56px;
  img {
    object-fit: contain;
    min-width: 232px;
    width: 232px;
    height: 187px;
    filter: drop-shadow(0px 103px 29px rgba(0, 0, 0, 0.01))
      drop-shadow(0px 66px 26px rgba(0, 0, 0, 0.07))
      drop-shadow(0px 37px 22px rgba(0, 0, 0, 0.22))
      drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.38))
      drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.43))
      drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.44));
  }
`;

export const AchievementPage = () => {
  const { graySubtitle, grayInnerHint, blackSidebar, greenIndicator } =
    theme.colors;

  const isSuperAdmin = useCheckIsSuperAdmin();
  const navigate = useNavigate();
  const reader = new FileReader();

  const [productsData, setProductsData] = useState<SelectData[]>([]);
  const [actionsData, setActionsData] = useState<SelectData[]>([]);

  const [product, setProduct] = useState({ id: '0', name: 'Выберите продукт' });
  const [action, setAction] = useState({ id: '0', name: 'Выберите действие' });
  const [name, setName] = useState('');
  const [descriptionAfterReceive, setDescriptionAfterReceive] = useState('');
  const [descriptionToReceive, setDescriptionToReceive] = useState('');
  const [actionsCount, setActionsCount] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [mounted, setMounted] = useState(false);
  const [previewBtnIdx, setPreviewBtnIdx] = useState(0);

  reader.onload = (e: ProgressEvent<FileReader>) => {
    if (typeof e.target?.result === 'string') {
      setFileUrl(e.target?.result);
    }
  };
  
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const productsRes = (await productService.getAll()).data;
      const products = productsRes.map((el) => ({
        id: String(el.id),
        name: el.name,
      }));
      setProductsData(products);
      if (!isSuperAdmin)
        setProduct(products[products.findIndex((el) => el.id === '1')]);
    }
    if (mounted) fetchData();
  }, [isSuperAdmin, mounted]);

  useEffect(() => {
    (async function fetchActions() {
      if (product.id === '0') return;
      const actions = (await actionService.getAll(product.id)).data;
      setActionsData(actions.map((el) => ({ id: el.actionId, name: el.name })));
      setAction({ id: '0', name: 'Выберите действие' });
    })();
  }, [product]);

  const handleAddFile = (fileData: File | null) => {
    if (fileData) {
      setFile(fileData);
      reader.readAsDataURL(fileData);
    }
  };

  const handleFileRemove = () => {
    setFile(null);
    setFileUrl(null);
  };

  const handleDropFile = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const fileData = e.dataTransfer.files[0];
    reader.readAsDataURL(fileData);
    handleAddFile(fileData);
  };

  const clearState = () => {
    setProduct({ id: '0', name: 'Выберите продукт' });
    setAction({ id: '0', name: 'Выберите действие' });

    setName('');
    setActionsCount('');
    setFile(null);
    setFileUrl(null);
  };

  const handleAchievementCreate = () =>
    new Promise<void>((resolve) => {
      const form = new FormData();
      if (file) form.append('file', file);
      form.append(
        'playbook',
        JSON.stringify({
          product: { id: +product.id },
          name,
          actionId: String(action.id),
          actionsCount: +actionsCount,
          descriptionAfterReceive,
          descriptionToReceive,
        })
      );

      achievementService.create(form).then((response) => {
        if (response.status === 200) {
          resolve();
          clearState();
        }
      });
    });

  const isValid = () =>
    product.id !== '0' &&
    name.length &&
    action.id !== '0' &&
    actionsCount.length &&
    descriptionToReceive.length &&
    descriptionAfterReceive !== '' &&
    file;

  return (
    <PageWrapper>
      <PageContent>
        <PageHeader
          onClick={() => navigate('/gamification')}
          navButtons={[
            { to: '/achievement', label: 'Создание' },
            { to: '/achievement-archive', label: 'Уже созданные' },
          ]}
          title="Создание медали"
        />
        <PageMain>
          <PageLeftHalf>
            <StyledPageLeftContent>
              {isSuperAdmin && (
                <InputSelect
                  data={productsData}
                  inputValue={product}
                  setInputValue={setProduct}
                  label="Продукт"
                  mb={23}
                />
              )}
              <InputText
                label="Название медали"
                inputValue={name}
                placeholder="Например, Укротитель треков"
                innerHint="максимум Х символов"
                setInputValue={setName}
                maxLength={500}
                multiple
                mb={6}
              />
              <Typography
                fz={12}
                lh={16}
                ls="-0.02em"
                ff="SB Sans Text"
                color={graySubtitle}
                mb={20}
              >
                Название должно отражать суть действия, которое нужно выполнить,
                чтобы получить медаль.
              </Typography>
              <InputSelect
                data={actionsData}
                inputValue={action}
                setInputValue={setAction}
                label="Действия"
                hint="Какое действие нужно выполнить, чтобы получить медаль."
                mb={20}
              />
              <InputText
                label="Повторы"
                inputValue={actionsCount}
                setInputValue={setActionsCount}
                placeholder="Введите количество повторов"
                hint="Сколько раз нужно выполнить действие, чтобы получить медаль."
                min={1}
                mb={20}
              />
              <InputText
                label="Описание до получения медали"
                inputValue={descriptionToReceive}
                placeholder="Например, Создайте 5 плейлистов — поделитесь своим идеальным музыкальным вкусом."
                innerHint="максимум Х символов"
                setInputValue={setDescriptionToReceive}
                maxLength={500}
                minHeight={120}
                multiple
                mb={6}
              />
              <Typography
                fz={12}
                lh={16}
                ls="-0.02em"
                ff="SB Sans Text"
                color={graySubtitle}
                mb={20}
              >
                Небольшой текст, мотивирующий пользователя получить медаль.
              </Typography>
              <InputText
                label="Поздравление после получения медали"
                inputValue={descriptionAfterReceive}
                placeholder="Например, Вы собрали столько песен в одном месте. Это музыкальная революция!"
                innerHint="максимум Х символов"
                setInputValue={setDescriptionAfterReceive}
                maxLength={500}
                minHeight={120}
                multiple
                mb={6}
              />
              <Typography
                fz={12}
                lh={16}
                ls="-0.02em"
                ff="SB Sans Text"
                color={graySubtitle}
                mb={19}
              >
                Мини-поздравление, которое пользователь увидит после получения
                новой медали.
              </Typography>
              <InputFile
                label="Иллюстрация"
                placeholder="Прикрепите изображение медали
                в формате PNG или JPG"
                handleAddFile={handleAddFile}
                handleDropFile={handleDropFile}
                fileUrl={fileUrl}
                file={file}
                handleFileRemove={handleFileRemove}
              />
              <ConfirmButton
                onClick={handleAchievementCreate}
                text="Создать медаль"
                mt={40}
                maxWidth={175}
                modalMessage="Медаль создана и добавлена в виджет"
                modalBtnText="Хорошо"
                disable={!isValid()}
              />
            </StyledPageLeftContent>
          </PageLeftHalf>
          <PageRightHalf
            description="Так медаль будет выглядеть в виджете"
            buttons={[
              {
                label: 'Название',
                callback: () => setPreviewBtnIdx(0),
              },
              {
                label: 'Описание',
                callback: () => setPreviewBtnIdx(1),
              },
              {
                label: 'Поздравление',
                callback: () => setPreviewBtnIdx(2),
              },
            ]}
            currentBtnIdx={previewBtnIdx}
          >
            {previewBtnIdx === 0 && (
              <AchievementNamePreview>
                <img
                  className="medal-name-img"
                  src={fileUrl ?? createMedalName}
                  alt="medal-name-img"
                />
                <Typography
                  ff="SB Sans Text"
                  fz={16}
                  lh={20}
                  ls="-0.02em"
                  color={name.length ? blackSidebar : grayInnerHint}
                >
                  {name.length ? name : 'Названия пока нет'}
                </Typography>
              </AchievementNamePreview>
            )}
            {previewBtnIdx === 1 && (
              <AchievementDescriptionPreview>
                <img
                  className="medal-description-img"
                  src={fileUrl ?? createMedalDescription}
                  alt="medal-description-img"
                />
                <Typography
                  ff="SB Sans Text"
                  fz={16}
                  lh={20}
                  ls="-0.02em"
                  mb={11}
                  color={blackSidebar}
                >
                  {actionsCount
                    ? `${Math.ceil(+actionsCount / 2)} из ${actionsCount}`
                    : 'Выполнено 10 из 15'}
                </Typography>
                <IndicatorWrapper>
                  <Indicator
                    currentXP={String(
                      actionsCount.length ? Math.ceil(+actionsCount / 2) : 10
                    )}
                    levelXP={String(actionsCount.length ? actionsCount : 15)}
                    color={greenIndicator}
                  />
                </IndicatorWrapper>
                <Typography
                  ff="SB Sans Text"
                  fz={24}
                  fw={600}
                  ls="-0.02em"
                  mb={24}
                  center
                  color={name.length ? blackSidebar : grayInnerHint}
                >
                  {name.length ? name : 'Названия пока нет'}
                </Typography>
                <Typography
                  ff="SB Sans Text"
                  fz={16}
                  ls="-0.02em"
                  center
                  color={
                    descriptionToReceive.length ? blackSidebar : grayInnerHint
                  }
                >
                  {descriptionToReceive.length
                    ? descriptionToReceive
                    : 'Описания пока нет'}
                </Typography>
              </AchievementDescriptionPreview>
            )}
            {previewBtnIdx === 2 && (
              <AchievementCongratulationPreview>
                <Banner>
                  <img
                    className="medal-congratulation-img"
                    src={fileUrl ?? createMedalCongratulation}
                    alt="medal-congratulation-img"
                  />
                </Banner>
                <Typography
                  ff="SB Sans Text"
                  fw={600}
                  fz={24}
                  ls="-0.02em"
                  lh="110%"
                  mb={24}
                  color={name.length ? blackSidebar : grayInnerHint}
                >
                  {name.length ? name : 'Названия пока нет'}
                </Typography>
                <Typography
                  ff="SB Sans Text"
                  fz={16}
                  ls="-0.02em"
                  lh={20}
                  mb={24}
                  center
                  color={
                    descriptionAfterReceive.length
                      ? blackSidebar
                      : grayInnerHint
                  }
                >
                  {descriptionAfterReceive.length
                    ? descriptionAfterReceive
                    : 'Поздравления пока нет'}
                </Typography>
                <Typography
                  ff="SB Sans Text"
                  fz={16}
                  ls="-0.02em"
                  lh={20}
                  center
                  color={grayInnerHint}
                >
                  Получено 20 июля 2022
                </Typography>
              </AchievementCongratulationPreview>
            )}
          </PageRightHalf>
        </PageMain>
      </PageContent>
    </PageWrapper>
  );
};
