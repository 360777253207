import React from 'react';
import styled, { css } from 'styled-components';
import { theme } from '../../../theme';
import { Typography } from '../Typography';

type Props = {
  placeholder: string;
  inputValue: string;
  setInputValue: (val: string) => void;
  setTouched?: () => void;
  minHeight?: number;
  maxLength?: number;
  label?: string;
  multiple?: boolean;
  innerHint?: string;
  hint?: string;
  min?: number;
  max?: number;
  mb?: number;
  color?: string;
  type?: string;
  disabled?: boolean;
  borderColor?: string;
  errorMessage?: string | boolean;
};

const StyledInputText = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${(props: Props) => (props.mb ? `${props.mb}px;` : 'unset')};
  ${(props: Props) =>
    props.hint &&
    css`
      input {
        margin-bottom: 6px;
      }
    `};
  & > input,
  & > textarea {
    border: 1px solid
      ${(props) => props.borderColor ?? props.theme.colors.grayInputBorder};
    outline: none;
    resize: none;
    width: 100%;
    padding: 10px 12px;
    border-radius: 10px;
    font-family: 'SB Sans Text';
    line-height: 20px;
    letter-spacing: -0.02em;
    ${(propsList: Props) =>
      propsList.color &&
      css`
        color: ${(props: Props) => props.color};
      `};
    &:disabled {
      background-color: ${(props) => props.theme.colors.whiteBg};
    }

    &::placeholder {
      font-size: 14px;
      color: ${(props) => props.theme.colors.graySubtitle};
    }
    &:focus {
      border: 1px solid ${(props) => props.theme.colors.greenBg};
    }
  }
  .inner-hint {
    position: absolute;
    bottom: 15px;
    left: 14px;
    letter-spacing: -0.02em;
    font-family: 'SB Sans Text';
  }
  .error-message {
    margin-top: 6px;
    /* position: absolute;
    bottom: 0;
    left: 0;
    letter-spacing: -0.02em;
    font-family: 'SB Sans Text'; */
  }
`;

const Label = styled.div`
  color: ${(props) => props.theme.colors.blackSidebar};
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  font-family: 'SB Sans Text';
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #212121;
`;

const Textarea = styled.textarea<any>`
  min-height: ${(props: Props) => `${props.minHeight}px`};
  max-height: ${(props: Props) =>
    props.minHeight ? `${props.minHeight}px` : '60px'};
`;

export const InputText = (props: Props) => {
  const {
    label,
    placeholder,
    multiple = false,
    inputValue,
    setInputValue,
    min,
    max,
    maxLength,
    minHeight,
    mb,
    hint,
    innerHint,
    disabled,
    errorMessage,
    setTouched,
    type,
  } = props;
  const { graySubtitle, grayInnerHint } = theme.colors;

  const handleInputChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    if (
      (!min || (min && +e.target.value >= min) || e.target.value === '') &&
      (!max || (max && +e.target.value <= max) || e.target.value === '')
    ) {
      setInputValue(e.target.value);
    }
  };

  const handleBlur = () => {
    if (setTouched) setTouched();
  };

  return (
    <StyledInputText {...props}>
      {label && <Label>{label}</Label>}
      {multiple ? (
        <Textarea
          maxLength={maxLength}
          value={inputValue}
          onChange={handleInputChange}
          placeholder={placeholder}
          minHeight={minHeight}
          disabled={disabled}
          onBlur={handleBlur}
        />
      ) : (
        <>
          <input
            type={type ?? 'text'}
            maxLength={maxLength}
            value={inputValue}
            onChange={handleInputChange}
            placeholder={placeholder}
            onBlur={handleBlur}
          />
          {hint && (
            <Typography
              fz={12}
              lh={16}
              color={graySubtitle}
              mb={mb ?? 24}
              ff="SB Sans Text"
            >
              {hint}
            </Typography>
          )}
        </>
      )}
      {innerHint && (
        <Typography
          className="inner-hint"
          fz={11}
          lh={12}
          color={grayInnerHint}
        >
          {innerHint}
        </Typography>
      )}
      {errorMessage && (
        <Typography
          className="error-message"
          fz={12}
          lh={16}
          ls="-0.02%"
          color="#FB3737"
        >
          {errorMessage}
        </Typography>
      )}
    </StyledInputText>
  );
};
