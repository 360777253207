import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { seasonService } from '../services';
import { getClaimDateFormat, stringToTimestamp } from '../utils';
import {
  SeasonAwardArchive,
  SeasonAwardArchiveField,
  SelectData,
} from '../models';
import { PageHeader, PageWrapper } from '../components/common/pageLayout';
import { InputSelect } from '../components/common/inputComp/InputSelect';
import { InputControls } from '../components/common/inputComp/InputControls';

export type Difficulties = {
  id: number;
  name: string;
};

const StyledSeasonAwardsWrapper = styled.div`
  /* padding: 143px 32px 30px 108px; */
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 1440px;
  height: 1153px;
  max-height: 1153px;
  background: #f3f3f3;
`;
const StyledSelectSeasonField = styled.div`
  position: relative;
  max-width: 360px;
  margin-bottom: 16px;
`;

const Thead = styled.thead`
  background-color: ${(props) => props.theme.colors.whiteBg};
  tr {
    color: ${(props) => props.theme.colors.grayHeaderText};
    background-color: ${(props) => props.theme.colors.whiteBg};
    th {
      font-style: normal;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: ${(props) => props.theme.colors.grayHeaderText};
    }
    th:first-child {
      border-radius: 8px 0 0 8px;
    }
    th:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

const Tbody = styled.tbody`
  tr {
    background-color: ${(props) => props.theme.colors.whiteBg};
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.01em;
    cursor: pointer;
    td:first-child {
      border-radius: 10px 0 0 10px;
    }
    td:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
`;

const Tr = styled.tr`
  text-align: left;
`;

const Th = styled.th`
  padding: 9px 16px;
  & > div {
    position: relative;
    width: fit-content;
    padding-right: 12px;
  }
`;

const Td = styled.td`
  padding: 15px 16px;
  color: ${(props) => props.theme.colors.blackLabel};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
`;

const PageContent = styled.div`
  max-width: 1300px;
  margin: 0 auto;
`;

export const PageMain = styled.div`
  display: flex;
  align-items: flex-start;
`;

const columns = [
  {
    field: 'startDate',
    label: 'Дата создания',
    controls: true,
    sortType: 'date',
  },
  {
    field: 'name',
    label: 'Название награды',
    controls: true,
    sortType: 'lowCaseString',
  },
  {
    field: 'seasonName',
    label: 'Сезон',
    controls: true,
    sortType: 'string',
  },
  {
    field: 'level',
    label: 'Уровень сезона',
    controls: true,
    sortType: 'number',
  },
  {
    field: 'promocode',
    label: 'Промокод',
    controls: true,
    sortType: 'lowCaseString',
  },
] as {
  field: SeasonAwardArchiveField;
  label: string;
  controls: boolean;
  sortType: string;
}[];

type SeasonAwardListItem = SeasonAwardArchive & {
  awardId: number;
};

export const RewardArchivePage = () => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState<{
    field: SeasonAwardArchiveField;
    dir: 'desc' | 'asc';
    sortType: string;
  }>({
    field: 'startDate',
    dir: 'desc',
    sortType: 'date',
  });
  const [seasonAwards, setSeasonAwards] = useState<SeasonAwardListItem[]>([]);
  const [seasonsData, setSeasonsData] = useState<SelectData[]>([]);
  const [mounted, setMounted] = useState(false);
  const [season, setSeason] = useState({
    id: '0',
    name: 'Выберите Сезон',
    startDate: '',
    endDate: '',
  });

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const seasonsRes = (await seasonService.getAll()).data;

      const seasons = seasonsRes.map((el) => ({
        id: String(el.playbookId),
        name: el.name,
        startDate: el.startDate,
        endDate: el.endDate,
      }));
      if (!seasons || !seasons.length) return;

      setSeasonsData(seasons);

      const actualSeasons = seasons.filter((s) => {
        const today = new Date().getTime();
        const startDate = new Date(s.startDate).getTime();
        const endDate = new Date(s.endDate).getTime();
        return startDate <= today && endDate >= today;
      });
      setSeason(actualSeasons.length ? actualSeasons[0] : seasons[0]);
    }
    if (mounted) {
      fetchData();
    }
  }, [mounted]);

  useEffect(() => {
    if (+season.id) {
      seasonService.getByPlaybookId(season.id).then(({ data: seasons }) => {
        seasons.seasonElementsGetDtos.forEach((seasonElement) => {
          if (seasonElement.awards && seasonElement.awards.length) {
            const awards = seasonElement.awards.map((seasonAward) => ({
              seasonName: season.name,
              name: seasonAward.description,
              startDate: getClaimDateFormat(season.startDate),
              level: seasonElement.level,
              promocode: seasonAward.promocode,
              awardId: seasonAward.awardId,
              playbookId: season.id,
            }));
            setSeasonAwards(awards);
          }
        });
      });
    }
  }, [season]);

  const filteredAndSortedSeasonAwards = useCallback(() => {
    const sorted = seasonAwards.sort((a, b) => {
      if (filter.sortType === 'number') {
        if (+a[filter.field] > +b[filter.field])
          return filter.dir === 'desc' ? -1 : 1;
        if (+a[filter.field] < +b[filter.field])
          return filter.dir === 'desc' ? 1 : -1;
      }

      if (filter.sortType === 'lowCaseString') {
        if (
          (a[filter.field] as string).toLowerCase() >
          (b[filter.field] as string).toLowerCase()
        )
          return filter.dir === 'desc' ? -1 : 1;
        if (
          (a[filter.field] as string).toLowerCase() <
          (b[filter.field] as string).toLowerCase()
        )
          return filter.dir === 'desc' ? 1 : -1;
      }

      if (filter.field === 'startDate') {
        if (
          stringToTimestamp(a[filter.field]) <
          stringToTimestamp(b[filter.field])
        )
          return filter.dir === 'desc' ? -1 : 1;
        if (
          stringToTimestamp(a[filter.field]) >
          stringToTimestamp(b[filter.field])
        )
          return filter.dir === 'desc' ? 1 : -1;
      }
      if (a[filter.field] < b[filter.field])
        return filter.dir === 'desc' ? -1 : 1;
      if (a[filter.field] > b[filter.field])
        return filter.dir === 'desc' ? 1 : -1;
      return 0;
    });
    return sorted;
  }, [seasonAwards, filter]);

  return (
    <PageWrapper>
      <PageContent>
        <PageHeader
          onClick={() => navigate('/gamification')}
          title="Создание медали"
          archivePage
          navButtons={[
            { to: '/reward-create', label: 'Создание' },
            { to: '/reward-archive', label: 'Уже созданные' },
          ]}
        />
        <PageMain>
          <StyledSeasonAwardsWrapper>
            <StyledSelectSeasonField>
              <InputSelect
                data={seasonsData}
                inputValue={season}
                setInputValue={setSeason}
                label="Сезон"
                mb={23}
              />
            </StyledSelectSeasonField>
            <Table>
              <Thead>
                <Tr>
                  {columns.map((column) => (
                    <Th key={uuidv4()}>
                      <div>
                        {column.label}
                        {column.controls && (
                          <InputControls
                            handleStepUpClick={() =>
                              setFilter({
                                field: column.field,
                                dir: 'desc',
                                sortType: column.sortType,
                              })
                            }
                            handleStepDownClick={() =>
                              setFilter({
                                field: column.field,
                                dir: 'asc',
                                sortType: column.sortType,
                              })
                            }
                          />
                        )}
                      </div>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {filteredAndSortedSeasonAwards().map((SeasonAward) => (
                  <Tr
                    key={uuidv4()}
                    // onClick={() => buttonClickHandler(String(SeasonAward.playbookId))}
                  >
                    <Td>{SeasonAward.startDate}</Td>
                    <Td>{SeasonAward.name}</Td>
                    <Td>{SeasonAward.seasonName}</Td>
                    <Td>{SeasonAward.level}</Td>
                    <Td>{SeasonAward.promocode}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </StyledSeasonAwardsWrapper>
        </PageMain>
      </PageContent>
    </PageWrapper>
  );
};
