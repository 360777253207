import styled, { css, DefaultTheme } from 'styled-components';
import { useState } from 'react';
import { Modal } from '../Modal';

type Props = {
  theme: DefaultTheme;
  disable: boolean;
  mt?: number;
};

const StyledConfirmButton = styled.div`
  margin-top: ${(props: Props) => (props.mt ? `${props.mt}px;` : 'unset')};
  background-color: ${(props: Props) =>
    props.disable
      ? props.theme.colors.grayBgButton
      : props.theme.colors.questionRedBg};
  color: ${(props: Props) =>
    props.disable ? props.theme.colors.graySubtitle : '#fff'};
  ${(props: Props) =>
    !props.disable &&
    css`
      cursor: pointer;
    `};
  border-radius: 8px;
  padding: 10px 16px;
  line-height: 20px;
  border: none;
  width: fit-content;
`;

export const DeleteButton = ({
  onClick,
  text,
  disable,
  mt,
  modalMessage,
  modalBtnText,
}: {
  onClick: any;
  text: string;
  disable: boolean;
  mt?: number;
  modalMessage: string;
  modalBtnText: string;
}) => {
  const [showModal, setModal] = useState(false);

  const handleModalSuccess = () => {
    setModal(false);
  };

  const handleToggleModal = () => {
    setModal((prev) => !prev);
  };

  const handleConfirmClick = () => {
    if (disable) return;
    onClick().then(() => handleToggleModal());
  };

  return (
    <StyledConfirmButton disable={disable} mt={mt} onClick={handleConfirmClick}>
      <>
        {text}
        {showModal && (
          <Modal
            message={modalMessage}
            btnText={modalBtnText}
            onClose={handleToggleModal}
            onSuccess={handleModalSuccess}
          />
        )}
      </>
    </StyledConfirmButton>
  );
};
