import styled from "styled-components";

export const StyledPageLeftContent = styled.div`
  max-width: 436px;
`;

export const UploaderWrapper = styled.div`
  margin-bottom: 20px;
`

export const UploaderList = styled.ol`
  counter-reset: count-list;
  list-style-type: none;
  margin: 0 0 6px 0;
`

export const UploaderListItem = styled.li`
  position: relative;
  padding-top: 6px;

  &::before {
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    line-height: 20px;
    counter-increment: count-list;
    content: counter(count-list) " ";
    color: ${(props) => props.theme.colors.graySubtitle};
    list-style-position: outside;
    padding-right: 5px;
  }
`