import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { metricService } from '../../services/metricService';
import {medalByIdMetric, rankByIdMetric, taskByIdMetric} from '../../models';

const StyledModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100vh;
`;

const ContentWrapper = styled.div`
  position: fixed;
  width: 454px;
  min-height: 320px;
  padding: 10px;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background: #ffffff;
  box-shadow: 0 2px 14px 7px rgba(52, 51, 91, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Message = styled.div`
  margin-top: 11px;
  margin-bottom: 16px;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
`;

const Button = styled.button`
  background: ${(props) => props.theme.colors.greenBg};
  cursor: pointer;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-top: auto;
`;

export const MetricsModal = ({
  taskName,
  actionId,
  type,
  onClose,
  onSuccess,
  taskId,
}: {
  taskId: number;
  taskName: string;
  type: string;
  actionId: string;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const [taskData, setTaskData] = useState<taskByIdMetric>();
  const [medalData, setMedalData] = useState<medalByIdMetric>();
  const [rankData, setRankData] = useState<rankByIdMetric>();

  const handleContentClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
  };

  const handleCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClose();
  };
  useEffect(() => {
    if (type === 'task') {
      metricService.getTaskMetricsByPlaybookId(taskId).then((response) => {
        setTaskData(response.data);
      });
    }
    if (type === 'medal') {
      metricService.getMedalsMetricByPlaybookId(taskId).then((response) => {
        setMedalData(response.data);
      });
    }
    if (type === 'rank') {
      metricService.getRankMetricById(taskId).then((response)=>{
        setRankData(response.data)
      });
    }
  }, [taskId]);

  return (
    <StyledModalWrapper onClick={handleCloseClick}>
      <ContentWrapper onClick={handleContentClick}>
        <Message>
          {taskName}
          <div>{actionId}</div>
          {type === 'task' && (
            <>
              {' '}
              <div>Всего завершили: {taskData?.countCompleted}</div>
              <div>Всего забрали награду: {taskData?.countFinished}</div>
              <div>
                Всего открыли подробное описание:{' '}
                {taskData?.countOpenDescription}
              </div>
            </>
          )}
          {type === 'rank' && (
              <>
                <div>Получили это звание: {rankData?.countSpgpIdByRankId} пользователей</div>
                <div>Выбрали это звание: {rankData?.countSetRankIdByUser} пользователей</div>
              </>
          )}
          {type === 'medal' && (
            <>
              <div>ID: {medalData?.playbookId}</div>
              <div>Тип награды: {medalData?.achievementType}</div>
              <div>Посмотрели подробно: {medalData?.countSeen}</div>
              <div>Получили:{medalData?.countTaken}</div>
            </>
          )}
        </Message>
        <Button type="button" onClick={onSuccess}>
          Закрыть
        </Button>
      </ContentWrapper>
    </StyledModalWrapper>
  );
};
