import styled from 'styled-components';
import { DateArrowIcon } from '../../../assets/icons/DateArrowIcon';

type Props = {
  handleStepDownClick: () => void;
  handleStepUpClick: () => void;
  right?: string;
  top?: string;
};

const StyledInputControls = styled.div`
  right: ${(props: Props) => (props.right ? `${props.right}` : '0px')};
  top: ${(props: Props) => (props.top ? `${props.top}` : '50%')};
  position: absolute;
  transform: translateY(-50%);
  height: 11px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & > svg {
    cursor: pointer;
  }
  & > svg:first-child {
    transform: rotateX(180deg);
  }
`;

export const InputControls = (props: Props) => {
  const { handleStepDownClick, handleStepUpClick } = props;

  return (
    <StyledInputControls {...props}>
      <DateArrowIcon onClick={handleStepUpClick} />
      <DateArrowIcon onClick={handleStepDownClick} />
    </StyledInputControls>
  );
};
