import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageContent } from '../components/common/pageLayout/Shared';
import { addDays, addMonths, dateToString } from '../utils';
import { seasonService } from '../services';
import {
  PageHeader,
  PageLeftHalf,
  PageWrapper,
} from '../components/common/pageLayout';
import { InputText } from '../components/common/inputComp/InputText';
import { InputDate } from '../components/common/inputComp/InputDate';
import { ConfirmButton } from '../components/common/buttonComp/ConfirmButton';

const today = new Date();

export const SeasonCreatePage = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [levels, setLevels] = useState('');
  const [startDate, setStartDate] = useState({ value: today, touched: false });
  const [endDate, setEndDate] = useState({
    value: addDays(today, 1),
    touched: false,
  });

  useEffect(() => {
    const start = startDate.value.setMilliseconds(0);
    const end = endDate.value.setMilliseconds(0);
    if (start >= end) {
      setEndDate((prev) => ({
        ...prev,
        value: addDays(startDate.value, 1),
      }));
    } else if (startDate.value < addMonths(endDate.value, -2)) {
      setEndDate((prev) => ({
        ...prev,
        value: addMonths(startDate.value, 2),
      }));
    }
  }, [startDate, endDate.value]);

  const clearState = () => {
    setName('');
    setLevels('');
    setStartDate({ value: today, touched: false });
    setEndDate({ value: addDays(today, 1), touched: false });
  };

  const handleSeasonCreate = () =>
    new Promise<void>((resolve) => {
      const season = {
        endDate: dateToString(endDate.value),
        name,
        numberSeasonElements: +levels,
        startDate: dateToString(startDate.value),
      };

      seasonService.create(season).then((response) => {
        if (response.status === 200) {
          resolve();
          clearState();
        }
      });
    });

  const isValid = () => !!name.length && !!levels.length;

  return (
    <PageWrapper>
      <PageContent>
        <PageHeader
          onClick={() => navigate('/gamification')}
          title="Создание сезона"
          navButtons={[
            { to: '/season-create', label: 'Создание' },
            { to: '/season-archive', label: 'Уже созданные' },
          ]}
        />
        <PageLeftHalf>
          <InputText
            label="Название сезона"
            inputValue={name}
            placeholder="Например, Игра началась!"
            innerHint="максимум Х символов"
            setInputValue={setName}
            maxLength={500}
            multiple
            mb={24}
          />

          <InputDate
            min={today}
            date={startDate}
            setDate={setStartDate}
            label="Дата начала сезона"
            mb={6}
          />
          <InputDate
            min={addDays(startDate.value, 1)}
            max={addMonths(startDate.value, 2)}
            date={endDate}
            setDate={setEndDate}
            label="Дата конца сезона"
            mb={24}
          />

          <InputText
            inputValue={levels}
            setInputValue={setLevels}
            label="Количество уровней в сезоне"
            placeholder="Ограничений по количеству уровней нет"
          />
          <ConfirmButton
            onClick={handleSeasonCreate}
            text="Создать сезон"
            mt={40}
            modalMessage="Новый сезон создан"
            modalBtnText="Хорошо"
            maxWidth={162}
            disable={!isValid()}
          />
        </PageLeftHalf>
      </PageContent>
    </PageWrapper>
  );
};
