export const QuestionIcon = () => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.63866 7.18322C4.57143 7.61958 4.21849 8.00559 3.73109 8.00559H3.59664C3.10924 8.00559 2.7563 7.63636 2.68908 7.18322L2.52101 6.04196C2.43698 5.48811 2.7563 5.1021 3.29412 5.03497C4.7563 4.85035 5.42857 4.3972 5.42857 3.57483V3.54126C5.42857 2.78601 4.85714 2.34965 3.88235 2.34965C3.15966 2.34965 2.53782 2.56783 1.93277 3.02098C1.73109 3.17203 1.46218 3.28951 1.17647 3.28951C0.537815 3.28951 0 2.75245 0 2.11469C0 1.7958 0.134454 1.46014 0.436975 1.20839C1.32773 0.46993 2.45378 0 3.93277 0C6.35294 0 8 1.24196 8 3.47413V3.50769C8 5.63916 6.52101 6.51189 4.68908 6.88112L4.63866 7.18322Z"
      fill="white"
    />
    <path
      d="M2.13445 10.5734V10.5399C2.13445 9.73427 2.7563 9.11329 3.59664 9.11329C4.43697 9.11329 5.05882 9.73427 5.05882 10.5399V10.5734C5.05882 11.379 4.43697 12 3.59664 12C2.7563 12 2.13445 11.379 2.13445 10.5734Z"
      fill="white"
    />
  </svg>
);
