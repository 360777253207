import api from '../http';
import { Specproject, SpecprojectApprove } from '../models';

export const specprojectService = {
  create: (specproject: FormData) =>
    api.post<Specproject>('playbooks/specprojects/', specproject),
  update: (specproject: FormData) =>
    api.put<Specproject>('playbooks/specprojects/', specproject),
  getAll: (page = 1, size = 50) =>
    api.get<Specproject[]>(
      `playbooks/specprojects/all?page=${page}&size=${size}`
    ),
  approve: (approve: SpecprojectApprove) =>
    api.put<Specproject>('playbooks/specprojects/approve', approve),
  getByPlaybookId: (playbookId: number) =>
    api.get<Specproject>(`playbooks/specprojects/playbookId=${playbookId}`),
  getAttachment: (attachmentId: number) =>
    api.get(`playbooks/specprojects/attachments/attachmentId=${attachmentId}`),
  delete: (playbookId: number) =>
    api.delete(`playbooks/specprojects/playbookId=${playbookId}`),
};
