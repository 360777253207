import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import { PrivateRoutes } from './PrivateRoutes';
import { useCheckIsSuperAdmin } from '../hooks/useSuperAdminCheck';
import { useSkipLogin } from '../hooks/useSkipLoginCheck';
import { SelectAdminTypePage } from '../pages/SelectAdminPage';
import { LoginPage } from '../pages/LoginPage';

export const AuthMiddleware = () => {
  const skipLoginPage = useSkipLogin(); // is current url should skip loginpage on not logged in user

  // const isSuperAdmin = useCheckIsSuperAdmin();

  // const [isLoggedIn, setIsLoggedIn] = useState(
  //   JSON.parse(localStorage.getItem('PG_is_logged_in')!) || false
  // );

  // хардкор для показа, вернуть то что закомичено выше и в хуке useSuperAdmin, в api вернуть релоад страницы при ошибке
  const isSuperAdmin = true;
  const [isLoggedIn, setIsLoggedIn] = useState(true);

  useEffect(() => {
    window.localStorage.setItem('PG_is_logged_in', JSON.stringify(isLoggedIn));
  }, [isLoggedIn]);

  if (!isLoggedIn && !skipLoginPage)
    return <LoginPage onLogin={() => setIsLoggedIn(true)} />;

  if (skipLoginPage)
    return (
      <PrivateRoutes isLoggedIn={isLoggedIn} isSuperAdmin={isSuperAdmin} />
    );

  return isSuperAdmin !== null ? (
    <>
      <Sidebar />
      <PrivateRoutes isLoggedIn={isLoggedIn} isSuperAdmin={isSuperAdmin} />
    </>
  ) : (
    <Routes>
      <Route path="*" element={<SelectAdminTypePage />} />
    </Routes>
  );
};
