import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { theme } from '../theme';
import { useCheckIsSuperAdmin } from '../hooks/useSuperAdminCheck';
import { Typography } from '../components/common/Typography';
import { EditIcon } from '../assets/icons/EditIcon';
import { DocsIcon } from '../assets/icons/DocsIcon';
import { SmartphoneIcon } from '../assets/icons/SmartphoneIcon';
import { PersonIcon } from '../assets/icons/PersonIcon';
import { FingerIcon } from '../assets/icons/FingerIcon';

const StyledMainPageWrapper = styled.div`
  margin: 77px 143px;
  max-width: 584px;
  width: 100%;
  & > div > a {
    text-decoration: none;
  }
`;

const Subtitle = styled.div`
  max-width: 334px;
`;

const LabelsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

const StyledPageLabel = styled.div`
  border: 1px solid ${(props) => props.theme.colors.grayBorderButton};
  max-width: 284px;
  border-radius: 8px;
  padding: 24px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${(props) => props.theme.colors.greenBg};
  }
`;

const IconWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.grayBgButton};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 16px;
  width: 44px;
  height: 44px;
`;

export const MainPage = () => {
  const isSuperAdmin = useCheckIsSuperAdmin();

  const {
    blackPlaygroundTitle,
    blackPlaygroundLinkText,
    blackPlaygroundLinkTitle,
  } = theme.colors;

  return (
    <StyledMainPageWrapper>
      <Typography
        fw={700}
        fz={36}
        lh={30}
        ff="SB Sans Text"
        color={blackPlaygroundTitle}
        ls="-0.02em"
        mb={20}
      >
        Playground
      </Typography>
      <Subtitle>
        <Typography fz={16} lh={22} color={blackPlaygroundLinkText} mb={28}>
          Платформа для создания и управления геймификацией продуктов Сбера
        </Typography>
      </Subtitle>
      <LabelsWrapper>
        <Link to="/gamification">
          <StyledPageLabel>
            <IconWrapper>
              <EditIcon fill="#000" />
            </IconWrapper>
            <Typography fz={16} lh={20} color={blackPlaygroundLinkTitle} mb={8}>
              Создать геймификацию
            </Typography>
            <Typography lh={20} color={blackPlaygroundLinkText}>
              Создайте собственную геймификацию в Playground
            </Typography>
          </StyledPageLabel>
        </Link>
        {isSuperAdmin && (
          <>
            <Link to="/claims">
              <StyledPageLabel>
                <IconWrapper>
                  <DocsIcon fill="#000" />
                </IconWrapper>
                <Typography
                  fz={16}
                  lh={20}
                  color={blackPlaygroundLinkTitle}
                  mb={8}
                >
                  Заявки
                </Typography>
                <Typography lh={20} color={blackPlaygroundLinkText}>
                  Просмотр заявок, которые прислали локальные админы
                </Typography>
              </StyledPageLabel>
            </Link>
            <Link to="/admins">
              <StyledPageLabel>
                <IconWrapper>
                  <SmartphoneIcon fill="#000" />
                </IconWrapper>
                <Typography
                  fz={16}
                  lh={20}
                  color={blackPlaygroundLinkTitle}
                  mb={8}
                >
                  Администраторы
                </Typography>
                <Typography lh={20} color={blackPlaygroundLinkText}>
                  Список администраторов, которые создают геймификацию
                </Typography>
              </StyledPageLabel>
            </Link>
            <Link to="/employees">
              <StyledPageLabel>
                <IconWrapper>
                  <PersonIcon fill="#000" />
                </IconWrapper>
                <Typography
                  fz={16}
                  lh={20}
                  color={blackPlaygroundLinkTitle}
                  mb={8}
                >
                  Сотрудники
                </Typography>
                <Typography lh={20} color={blackPlaygroundLinkText}>
                  Выберите сотрудников, которым можно выдать права админа
                </Typography>
              </StyledPageLabel>
            </Link>
            <Link to="/metrics">
              <StyledPageLabel>
                <IconWrapper>
                  <FingerIcon fill="#000" />
                </IconWrapper>
                <Typography
                  fz={16}
                  lh={20}
                  color={blackPlaygroundLinkTitle}
                  mb={8}
                >
                  Метрики
                </Typography>
                <Typography lh={20} color={blackPlaygroundLinkText}>
                  Количественные данные по использованию геймификации
                </Typography>
              </StyledPageLabel>
            </Link>
          </>
        )}
      </LabelsWrapper>
    </StyledMainPageWrapper>
  );
};
