export const EditIcon = ({ fill = '#85858F' }: { fill?: string }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.75 13.66C19.75 13.2458 19.4142 12.91 19 12.91C18.5858 12.91 18.25 13.2458 18.25 13.66H19.75ZM1 19H0.25H1ZM3 3V2.25V3ZM8.34 3.75C8.75421 3.75 9.09 3.41421 9.09 3C9.09 2.58579 8.75421 2.25 8.34 2.25V3.75ZM17 1L17.5303 0.46967C17.2374 0.176777 16.7626 0.176777 16.4697 0.46967L17 1ZM21 5L21.5303 5.53033C21.8232 5.23744 21.8232 4.76256 21.5303 4.46967L21 5ZM11 15V15.75C11.1989 15.75 11.3897 15.671 11.5303 15.5303L11 15ZM7 15H6.25C6.25 15.4142 6.58579 15.75 7 15.75V15ZM7 11L6.46967 10.4697C6.32902 10.6103 6.25 10.8011 6.25 11H7ZM18.25 13.66V19H19.75V13.66H18.25ZM18.25 19C18.25 19.3315 18.1183 19.6495 17.8839 19.8839L18.9445 20.9445C19.4603 20.4288 19.75 19.7293 19.75 19H18.25ZM17.8839 19.8839C17.6495 20.1183 17.3315 20.25 17 20.25V21.75C17.7293 21.75 18.4288 21.4603 18.9445 20.9445L17.8839 19.8839ZM17 20.25H3V21.75H17V20.25ZM3 20.25C2.66848 20.25 2.35054 20.1183 2.11612 19.8839L1.05546 20.9445C1.57118 21.4603 2.27065 21.75 3 21.75V20.25ZM2.11612 19.8839C1.8817 19.6495 1.75 19.3315 1.75 19H0.25C0.25 19.7293 0.539731 20.4288 1.05546 20.9445L2.11612 19.8839ZM1.75 19V5H0.25V19H1.75ZM1.75 5C1.75 4.66848 1.8817 4.35054 2.11612 4.11612L1.05546 3.05546C0.539731 3.57118 0.25 4.27065 0.25 5H1.75ZM2.11612 4.11612C2.35054 3.8817 2.66848 3.75 3 3.75V2.25C2.27065 2.25 1.57118 2.53973 1.05546 3.05546L2.11612 4.11612ZM3 3.75H8.34V2.25H3V3.75ZM16.4697 1.53033L20.4697 5.53033L21.5303 4.46967L17.5303 0.46967L16.4697 1.53033ZM20.4697 4.46967L10.4697 14.4697L11.5303 15.5303L21.5303 5.53033L20.4697 4.46967ZM11 14.25H7V15.75H11V14.25ZM7.75 15V11H6.25V15H7.75ZM7.53033 11.5303L17.5303 1.53033L16.4697 0.46967L6.46967 10.4697L7.53033 11.5303Z"
      fill={fill}
    />
  </svg>
);
