import styled, { css } from 'styled-components';

type Props = { right?: boolean };

const StyledWarning = styled.div`
  top: 5px;
  ${(propList: Props) =>
    propList.right &&
    css`
      right: 70px;
      top: 33px;
    `};
  position: absolute;
  padding-right: 15px;
  font-size: 12px;
`;

export const Warning = (props: Props) => (
  <StyledWarning {...props}>
    Пожалуйста, отключите расширения для правильной работы сервиса
  </StyledWarning>
);
