import api from '../http';
import { Difficulty } from '../models';

export const difficultyService = {
  create: (difficulty: Difficulty) =>
    api.post<Difficulty>('difficulties/', difficulty),
  update: (difficulty: Difficulty) =>
    api.put<Difficulty>('difficulties/', difficulty),
  getAll: () => api.get<Difficulty[]>('difficulties/all'),
  getById: (id: number) => api.get<Difficulty>(`difficulties/id=${id}`),
  deleteById: (id: number) => api.delete(`difficulties/id=${id}`),
};
