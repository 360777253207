import styled, { css } from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { getClaimDateFormat, stringToTimestamp } from '../utils';
import { Claim, ClaimField } from '../models';
import { specprojectService } from '../services';
import { Typography } from '../components/common/Typography';
import { InputControls } from '../components/common/inputComp/InputControls';

const StyledClaimsWrapper = styled.div`
  padding: 31px 32px 30px 108px;
  min-height: 100vh;
  overflow: hidden;
  background: #f3f3f3;
`;

const PageContent = styled.div`
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Thead = styled.thead`
  background-color: ${(props) => props.theme.colors.whiteBg};
  tr {
    color: ${(props) => props.theme.colors.grayHeaderText};
    background-color: ${(props) => props.theme.colors.whiteBg};
    th {
      font-style: normal;
      line-height: 24px;
      letter-spacing: -0.01em;
      color: ${(props) => props.theme.colors.grayHeaderText};
    }
    th:first-child {
      border-radius: 8px 0 0 8px;
    }
    th:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

const Tbody = styled.tbody`
  tr {
    background-color: ${(props) => props.theme.colors.whiteBg};
    cursor: pointer;
    line-height: 24px;
    letter-spacing: -0.01em;
    td:first-child {
      border-radius: 10px 0 0 10px;
    }
    td:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
`;

const Tr = styled.tr`
  text-align: left;
`;

const Th = styled.th`
  padding: 9px 16px;
  & > div {
    position: relative;
    width: fit-content;
    padding-right: 12px;
  }
`;

const Td = styled.td`
  padding: 15px 16px;
  color: ${(props) => props.theme.colors.blackLabel};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
`;

const StyledStatusLabel = styled.div`
  color: ${(props) => props.theme.colors.orangeLightText};
  background-color: ${(props) => props.theme.colors.orangeLightBg};
  padding: 2px 12px;
  border-radius: 6px;
  line-height: 16px;
  width: fit-content;
  text-align: center;
  ${(propList: { status?: string }) =>
    propList.status?.toLowerCase() === 'прочитано' &&
    css`
      color: ${(props) => props.theme.colors.greenLightText};
      background-color: ${(props) => props.theme.colors.greenLightBg};
    `}
`;

const columns = [
  {
    field: 'number',
    label: 'Номер заявки',
    controls: false,
  },
  {
    field: 'date',
    label: 'Дата получения',
    controls: true,
  },
  {
    field: 'product',
    label: 'Продукт',
    controls: true,
  },
  {
    field: 'status',
    label: 'Статус',
    controls: true,
  },
] as {
  field: ClaimField;
  label: string;
  controls: boolean;
}[];

type ClaimListItem = Claim & {
  playgroundId: number;
};

export const ClaimsPage = () => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState<{
    field: ClaimField;
    dir: 'desc' | 'asc';
  }>({
    field: 'number',
    dir: 'desc',
  });
  const [claims, setClaims] = useState<ClaimListItem[]>([]);

  useEffect(() => {
    specprojectService.getAll().then(({ data }) => {
      setClaims(
        data.map((claim) => ({
          playgroundId: claim.playbookId,
          number: String(claim.elementId),
          date: getClaimDateFormat(claim.dateOfReciving),
          product: `${claim.product.name}`,
          status: claim.playbookStatus,
        }))
      );
    });
  }, []);

  const filteredAndSortedClaims = useCallback(() => {
    const sorted = claims.sort((a, b) => {
      if (filter.field === 'number') {
        if (+a[filter.field] > +b[filter.field])
          return filter.dir === 'desc' ? -1 : 1;
        if (+a[filter.field] < +b[filter.field])
          return filter.dir === 'desc' ? 1 : -1;
      }
      if (filter.field === 'date') {
        if (
          stringToTimestamp(a[filter.field]) <
          stringToTimestamp(b[filter.field])
        )
          return filter.dir === 'desc' ? -1 : 1;
        if (
          stringToTimestamp(a[filter.field]) >
          stringToTimestamp(b[filter.field])
        )
          return filter.dir === 'desc' ? 1 : -1;
      }
      if (a[filter.field] < b[filter.field])
        return filter.dir === 'desc' ? -1 : 1;
      if (a[filter.field] > b[filter.field])
        return filter.dir === 'desc' ? 1 : -1;
      return 0;
    });
    return sorted;
  }, [claims, filter]);

  const buttonClickHandler = (id: string) => {
    navigate(`${id}`);
  };

  return (
    <StyledClaimsWrapper>
      <PageContent>
        <Typography fw={600} fz={28} lh={34}>
          Архив заявок
        </Typography>
        <Table>
          <Thead>
            <Tr>
              {columns.map((column) => (
                <Th key={uuidv4()}>
                  <div>
                    {column.label}
                    {column.controls && (
                      <InputControls
                        handleStepUpClick={() =>
                          setFilter({ field: column.field, dir: 'desc' })
                        }
                        handleStepDownClick={() =>
                          setFilter({ field: column.field, dir: 'asc' })
                        }
                      />
                    )}
                  </div>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {filteredAndSortedClaims().map((claim) => (
              <Tr
                key={uuidv4()}
                onClick={() => buttonClickHandler(String(claim.playgroundId))}
              >
                <Td>{`#${claim.number}`}</Td>
                <Td>{claim.date}</Td>
                <Td>{claim.product}</Td>
                <Td>
                  <StyledStatusLabel status={claim.status}>
                    {claim.status}
                  </StyledStatusLabel>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </PageContent>
    </StyledClaimsWrapper>
  );
};
