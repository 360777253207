import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const skipLoginUrls = ['/registration', '/password-recovery'];

export function useSkipLogin() {
  const [skipLogin, setSkipLogin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setSkipLogin(skipLoginUrls.includes(location.pathname));
  }, [location.pathname]);

  return skipLogin;
}
