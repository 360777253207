import styled from 'styled-components';
import { GamificationTaskButton } from './GamificationTaskButton';
import { theme } from '../../theme';
import { GamificationRewardImg } from './GamificationRewardImg';
import { Typography } from './Typography';

const Card = styled.div<{ bgColor: string }>`
  position: relative;
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 186px;
  overflow-y: hidden;
`;

const CardBody = styled.div`
  width: 582px;
  padding: 28px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 8px;
  z-index: 2;
`;

const Header = styled.div`
  width: 100%;
`;

const ImgContainer = styled.div`
  margin-top: 15px;
  display: flex;
  min-width: 500px;
  height: 186px;
`;

const ImgWrapper = styled.div`
  /* max-height: 158px; */
  overflow-y: hidden;
  transform: scale(1.45);
  margin: 0 auto;
`;

export function GamificationCreateSeason() {
  const { whiteBg, blackLabel, grayDarkBgButton } = theme.colors;
  return (
    <Card bgColor={whiteBg}>
      <CardBody>
        <Header>
          <Typography fw={600} fz={20} lh={26} ls="-0.01em" color="#000000">
            Создание сезона
          </Typography>
        </Header>
        <Typography lh={20} ls="-0.02em" color={blackLabel}>
          Тематический и лимитированный игровой период, к которому привязываются
          задания от продуктов, медали, звания и сезонный награды.
        </Typography>
        <GamificationTaskButton
          color={grayDarkBgButton}
          link="/season-create"
          text="Создать сезон"
          noBorder
        />
      </CardBody>
      <ImgContainer>
        <ImgWrapper>
          <GamificationRewardImg isSeason />
        </ImgWrapper>
      </ImgContainer>
    </Card>
  );
}
