import { ReactNode } from 'react';
import styled from 'styled-components';

const StyledPageWrapper = styled.div`
  background: ${(props) => props.theme.colors.grayMainBg};
  padding: 22px 32px;
  margin-left: 76px;
  overflow: hidden;
  min-height: 100vh;
`;

export const PageWrapper = ({ children }: { children: ReactNode }) => (
  <StyledPageWrapper>{children}</StyledPageWrapper>
);
