import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { ClipIcon } from '../../../assets/icons/ClipIcon';
import { FileLabel } from '../FileLabel';

type Props = {
  file: File | null;
  label?: string;
  fileUrl?: string | null;
  // eslint-disable-next-line no-unused-vars
  handleAddFile?: (fileValue: File | null) => void;
  // eslint-disable-next-line no-unused-vars
  handleDropFile?: (e: React.DragEvent<HTMLDivElement>) => void;
  handleFileRemove?: () => void;
  placeholder?: string;
  mb?: number;
};

const Label = styled.div`
  color: ${(props) => props.theme.colors.blackLabel};
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
`;

const DropFileWrapper = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23D5D5D5' stroke-width='1' stroke-dasharray='4' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 6px;
  border-radius: 8px;
  position: relative;
  padding: 0 10px;
  width: 100%;
  height: 250px;
  display: grid;
  place-content: center;
  background-color: #fff;
  & > div {
    cursor: pointer;
    position: absolute;
    right: 14px;
    bottom: 12px;
  }
  span {
    color: ${(props) => props.theme.colors.grayHeaderText};
    max-width: 280px;
    text-align: center;
    line-height: 20px;
  }
`;

const DropImageWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.greenBg};
  position: relative;
  max-width: 100%;
  border-radius: 6px;
  min-height: 250px;
  max-height: 250px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
`;

const DropImage = styled.img`
  max-width: 100%;
  max-height: 250px;
`;

const FileLabelWrapper = styled.div`
  position: absolute;
  right: 12px;
  bottom: 13px;
`;

const StyledInputFile = styled.div`
  margin-bottom: ${(props: Props) => (props.mb ? `${props.mb}px;` : 'unset')};
  input {
    border: 1px solid ${(props) => props.theme.colors.grayBorderButton};
    font-size: 16px;
    outline: none;
    resize: none;
    width: 100%;
    padding: 10px 16px;
    border-radius: 6px;
    &::placeholder {
      font-size: 16px;
    }
    &[type='file'] {
      display: none;
    }
    &:focus {
      border: 1px solid ${(props) => props.theme.colors.greenBg};
    }
  }
`;

export const InputFile = (props: Props) => {
  const {
    label,
    fileUrl,
    file,
    placeholder,
    handleDropFile,
    handleFileRemove,
    handleAddFile,
  } = props;
  const maxAllowedFileSize = 20 * 1024 * 1024;
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClipClick = () => hiddenFileInput.current?.click();

  const handleFileInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (
      handleAddFile &&
      files &&
      files?.length > 0 &&
      files[0].size < maxAllowedFileSize
    ) {
      handleAddFile(files && files[0]);
      e.target.value = '';
    }
  };

  return (
    <StyledInputFile {...props}>
      {label && <Label>{label}</Label>}
      {fileUrl && (
        <DropImageWrapper>
          <DropImage src={fileUrl} alt="drop" />
          {file && handleFileRemove && (
            <FileLabelWrapper>
              <FileLabel file={file} onRemove={handleFileRemove} />
            </FileLabelWrapper>
          )}
        </DropImageWrapper>
      )}
      {!fileUrl && handleDropFile && (
        <DropFileWrapper
          onDragStart={(e) => e.preventDefault()}
          onDragLeave={(e) => e.preventDefault()}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => handleDropFile(e)}
        >
          <span>{placeholder}</span>
          {handleAddFile && (
            <div>
              <ClipIcon onClick={handleClipClick} />
              <input
                type="file"
                ref={hiddenFileInput}
                onChange={handleFileInputChange}
              />
            </div>
          )}
        </DropFileWrapper>
      )}
      {!fileUrl && !handleDropFile && (
        <div>
          <ClipIcon onClick={handleClipClick} />
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleFileInputChange}
          />
        </div>
      )}
    </StyledInputFile>
  );
};
