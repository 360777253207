import styled, { css } from 'styled-components';

export const StyledPageLeftContent = styled.div`
  max-width: 436px;
`;

export const ChooseReward = styled.div`
  ${(props: { marginTop?: boolean }) =>
    props.marginTop &&
    css`
      margin-top: 40px;
    `}
  font-family: 'SB Sans Text';
  line-height: 18px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 5px;
`;

export const RewardTitle = styled.div`
  font-family: 'SB Sans Text';
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #000000;
  margin-bottom: 18px;
`;

export const RewardSubtitle = styled.div`
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #2e2e30;
  margin-bottom: 16px;
`;

export const LevelsWrapper = styled.div`
  display: flex;
  gap: 6px;
  flex-wrap: wrap;
  margin-bottom: 6px;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  overflow-y: auto;
  max-height: 216px;
`;

export const Level = styled.button`
  position: relative;
  background: ${(props) => props.theme.colors.blackSidebar};
  color: #fff;
  border: 2px solid ${(props) => props.theme.colors.blackSidebar};
  ${(propsList: { free?: boolean; selected?: boolean }) =>
    propsList.free &&
    css`
      cursor: pointer;
      background: #fff;
      color: #000;
      border: 2px solid ${(props) => props.theme.colors.grayInputBorder};
    `}
  ${(propList: { free?: boolean; selected?: boolean }) =>
    propList.selected &&
    css`
      background: ${(props) => props.theme.colors.greenBg};
      border: 2px solid ${(props) => props.theme.colors.greenBg};
      color: #fff;
      cursor: pointer;
    `}
  border-radius: 37px;
  width: 42px;
  height: 42px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ProductLabel = styled.div<{ bgUrl: string }>`
  position: absolute;
  right: -4px;
  top: -4px;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  border: 1px solid ${(props) => props.theme.colors.grayInputBorder};
  background: ${({ bgUrl }: { bgUrl: string }) => `url(${bgUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
`;

export const SavedLabel = styled.div`
  position: absolute;
  right: -4px;
  top: -4px;
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background: ${(props) => props.theme.colors.greenBg};
  svg {
    position: absolute;
    top: 47%;
    left: 45%;
    transform: scale(0.9) translate(-45%, -45%);
  }
`;

export const Hint = styled.div`
  display: flex;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 24px;
  }
  & > div:first-child {
    width: 24px;
    height: 24px;
    border-radius: 24px;
  }
`;

export const HintLabel = styled.div`
  color: ${(props) => props.theme.colors.graySubtitle};
  margin-left: 10px;
  font-size: 16px;
  line-height: 24px;
`;

export const Gray = styled.div`
  background-color: ${(props) => props.theme.colors.grayBorderButton};
`;

export const White = styled.div`
  border: 2px solid rgba(0, 0, 0, 0.2);
`;

export const Green = styled.div`
  background-color: ${(props) => props.theme.colors.greenBg};
`;

export const StyledRewardBtn = styled.div`
  background-color: ${(props) => props.theme.colors.grayBgButton};
  color: ${(props) => props.theme.colors.blackLabel};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  height: 40px;
  padding: 10px 16px;
  line-height: 20px;
  cursor: pointer;
  div {
    margin-right: 13px;
  }
`;

export const StyledRewardRemoveBtn = styled(StyledRewardBtn)`
  background-color: ${(props) => props.theme.colors.orange};
  margin-bottom: ${(props: { mb: number }) => `${props.mb}px;`};
  color: #ffffff;
`;

export const StyledRightContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
`;

export const TitleWrapper = styled.div`
  max-width: 397px;
  text-align: center;
`;

export const Box = styled.div`
  width: 544px;
  border: 1px solid ${(props) => props.theme.colors.grayBorderBox};
  border-radius: 8px;
  padding: 76px;
  margin-top: 8px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
`;

export const RewardShortPreview = styled.div`
  width: 100%;
  min-height: 160px;
  position: relative;
  padding: 23px 28px 23px 7px;
  display: flex;
  gap: 21.32px;
  & > div:first-child {
    min-width: 113px;
    min-height: 113px;
    width: 113px;
    height: 113px;
  }
  & > div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
  }
  img {
    position: relative;
    width: 113px;
    height: 113px;
    object-position: top;
  }
`;

export const StarsWrapper = styled.div`
  position: absolute;
  top: 18px;
  left: 2px;
  width: 124px;
  height: 124px;
`;

export const RewardDetailedPreview = styled.div``;

export const RewardCongratulationPreview = styled.div`
  margin: 0 auto 122px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  max-width: 320px;
  img {
    object-position: top;
    width: 244px;
    height: 203px;
    margin-bottom: 78px;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const SeasonSelect = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border: 1px solid ${(props) => props.theme.colors.grayInputBorder};
  border-radius: 10px;
  background-color: #fff;
  padding: 14px 16px;
  margin-bottom: 16px;
  & > div {
    display: flex;
    flex-direction: column;
    &:nth-child(2) {
      padding-top: 2px;
      align-items: flex-end;
      svg {
        margin-right: 5px;
      }
    }
  }
`;
