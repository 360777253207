import styled from 'styled-components';

import { getNoun } from '../../utils';
import { Typography } from './Typography';

type Props = {
  name?: string;
  description?: string;
  elementCount?: number;
  backgroundSrc?: string;
};

const StyledMap = styled.div`
  background: ${(props) => props.theme.colors.whiteBg};
  width: 100%;
  padding: 0;
`;
const Footer = styled.div`
  position: absolute;
  bottom: 15px;
  left: 20px;
`;

const Container = styled.div<{ backgroundSrc?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  height: 192px;
  border-radius: 14px;
  overflow: hidden;
  padding: 19px 20px 15px;
  background: ${({ backgroundSrc }) => `url(${backgroundSrc})`};
  background-position: right top;
  background-repeat: no-repeat;
  color: rgba(255, 255, 255, 0.96);
`;

export const MapPreview = (props: Props) => {
  const { elementCount, description, name, backgroundSrc } = props;

  return (
    <StyledMap {...props}>
      <Container backgroundSrc={backgroundSrc}>
        <Typography
          ff="SB Sans Display"
          fw={600}
          fz={32}
          lh={36}
          color="rgba(255, 255, 255, 0.96)"
        >
          {name || 'Приключения в Индии '}
        </Typography>
        <Typography fw={500} fz={18} lh="-0.19em">
          {description || 'Насладись приключением сполна'}
        </Typography>
        <Footer>
          <Typography fw={500} lh={19} color="rgba(255, 255, 255, 0.56)">
            {`${elementCount ?? 0} ${getNoun(
              elementCount ?? 0,
              'задание',
              'задания',
              'заданий'
            )}`}
          </Typography>
        </Footer>
      </Container>
    </StyledMap>
  );
};
