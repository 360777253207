import styled from 'styled-components';
import { InputSelect } from './inputComp/InputSelect';

type Props = {
  handleAwardTypeChange: (val: any) => void;
  awardType: any;
};

const awardTypeData = [
  {
    id: '1',
    name: 'Медаль',
  },
  {
    id: '2',
    name: 'Вещь',
  },
];

const StyledMap = styled.div`
  width: 100%;
  padding: 0;
`;
const Footer = styled.div`
  position: absolute;
  bottom: 15px;
  left: 20px;
`;

const Container = styled.div`
  width: 100%;
  padding: 17px 14px 24px 13px;
  border: 1px solid #bdbdbd;
  border-radius: 12px;
`;

export const MapAwordItem = (props: Props) => {
  const { handleAwardTypeChange, awardType } = props;

  return (
    <StyledMap {...props}>
      <Container>
        <InputSelect
          data={awardTypeData}
          inputValue={awardType}
          setInputValue={handleAwardTypeChange}
          label="Тип награды"
          mb={6}
        />
      </Container>
    </StyledMap>
  );
};
