import styled from 'styled-components';
import gamificationMedal from '../assets/images/gamificationMedal.png';
import { GamificationCreateSeason } from '../components/common/GamificationCreateSeason';
import { GamificationFeedback } from '../components/common/GamificationFeedback';
import { GamificationTreasureMap } from '../components/common/GamificationTreasureMap';
import { GamificationRewardImg } from '../components/common/GamificationRewardImg';
import { GamificationTask } from '../components/common/GamificationTask';
import { GamificationTaskItem } from '../components/common/GamificationTaskItem';
import { theme } from '../theme';
import { Typography } from '../components/common/Typography';
import { OldButton } from '../components/common/buttonComp/OldButton';

const StyledGamificationWrapper = styled.div<{ bgColor: string }>`
  padding: 27px 0 161px 172px;
  overflow: hidden;

  background: ${({ bgColor }) => bgColor ?? '#f3f3f3'};
`;

const PageContent = styled.div`
  max-width: 1108px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const StyledDownContainer = styled.div`
  max-width: 1108px;
  height: 186px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 14px;
`;

const GamificationTaskItemsContainer = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 12.5px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  padding: 20px 10px;
  gap: 15px;
`;

const TaskContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 14px;
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;
const Medal = styled.div<{ src: string }>`
  margin-top: 42px;
  width: 100%;
  height: 475px;
  background-image: ${({ src }) => `url(${src})`};
`;

const LeftHalf = styled.div`
  max-width: 294px;
`;

const Title = styled.div`
  font-family: 'SB Sans Text';
  margin-top: 36px;
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 16px;
`;

const Label = styled.div`
  color: ${(props) => props.theme.colors.graySubtitle};
  font-family: 'SB Sans Text';
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  margin-bottom: 12px;
`;

export function GamificationPage({
  isSuperAdmin,
}: {
  isSuperAdmin: boolean | null;
}) {
  const { grayBgButton, blackSidebar, blackLabel, grayMainBg } = theme.colors;

  return (
    <StyledGamificationWrapper bgColor={grayMainBg}>
      <PageContent>
        <Header>
          <Typography
            fw={600}
            fz={34}
            lh={43}
            ls="-0.02em"
            color={blackSidebar}
            mb={8}
          >
            Создание геймификации
          </Typography>
          <Typography fz={16} lh={22} color={blackLabel}>
            Выберите игровую механику, которую хотите добавить в виджет
            Playground
          </Typography>
        </Header>
        <TaskContainer>
          <GamificationTask
            taskType="Задание"
            taskDescription="Простое действие, которое пользователи смогут выполнять каждый день, чтобы зарабатывать очки опыта."
            buttonLink="/task"
            buttonText="Создать"
            mt={90}
          >
            <GamificationTaskItemsContainer>
              <GamificationTaskItem
                xpColor="#9a57f2"
                xpCount={100}
                title="Заполните свой профиль"
                label1="10 дней"
                label2="Пульс"
                theme={theme}
              />
              <GamificationTaskItem
                xpColor="#3935FF"
                xpCount={200}
                title="Пройдите курс Агент Кибербезопасности"
                label1="30 дней"
                label2="Пульс"
                theme={theme}
              />
            </GamificationTaskItemsContainer>
          </GamificationTask>
          <GamificationTask
            taskType="Медаль"
            taskDescription="Ачивка, которую получают пользователи, если совершают какое-то действие определенное количество раз."
            buttonLink="/achievement"
            buttonText="Создать"
            mt={0}
            headerHeight={400}
          >
            <Medal src={gamificationMedal as string} />
            {/* <img alt="medal" src={gamificationMedal as string} /> */}
          </GamificationTask>
          <GamificationTask
            taskType="Сезонная награда"
            taskDescription="Мотивирующее вознаграждение, ради которого пользователи будут стараться выполнять больше заданий."
            buttonLink="/reward-create"
            buttonText="Создать"
            mt={9}
          >
            <GamificationRewardImg />
          </GamificationTask>
        </TaskContainer>
        {isSuperAdmin ? (
          <>
            <GamificationCreateSeason />
            <GamificationTreasureMap isSuperAdmin={isSuperAdmin}/>
          </>
        ) : (
          <StyledDownContainer>
            <GamificationTreasureMap isSuperAdmin={Boolean(isSuperAdmin)}/>
            <GamificationFeedback />
          </StyledDownContainer>
        )}
        <LeftHalf>
          <Label>Выберите тип геймификации</Label>

          <OldButton
            color={grayBgButton}
            text="Цепочка заданий"
            link="/task-chain"
            noBorder
          />
          <OldButton
            color={grayBgButton}
            text="Активация цепочки заданий"
            link="/task-chain-activation"
            noBorder
          />
          <OldButton
            color={grayBgButton}
            text="Награда за цепочку заданий"
            link="/map-reward-create"
            noBorder
          />

          {isSuperAdmin && (
            <OldButton
              color={grayBgButton}
              text="Метрики"
              link="/metrics"
              noBorder
              // disable={isDevices}
            />
          )}
        </LeftHalf>
      </PageContent>
    </StyledGamificationWrapper>
  );
}
