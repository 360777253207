import { Route, Routes } from 'react-router-dom';
import { AchievementArchivePage } from '../pages/AchievementArchivePage';
import { AchievementPage } from '../pages/AchievementPage';
import { ClaimCreatePage } from '../pages/ClaimCreatePage';
import { ClaimPage } from '../pages/ClaimPage';
import { ClaimsPage } from '../pages/ClaimsPage';
import { GamificationPage } from '../pages/GamificationPage';
import { MainPage } from '../pages/MainPage';
import { MapArchivePage } from '../pages/MapArchivePage';
import { MapAwardCreatePage } from '../pages/MapAwardCreatePage';
import { MapCreatePage } from '../pages/MapCreatePage/MapCreatePage';
import { MapRewardCreatePage } from '../pages/MapRewardCreate';
import { MapTaskCreatePage } from '../pages/MapTaskCreatePage';
import { MetricsPage } from '../pages/MetricsOldPage';
import { PasswordRecoveryPage } from '../pages/PasswordRecoveryPage';
import { PersonalAccountPage } from '../pages/PersonalAccountPage';
import { RegisterPage } from '../pages/RegisterPage';
import { RewardArchivePage } from '../pages/RewardArchivePage';
import { RewardCreatePage } from '../pages/RewardCreate';
import { SeasonArchivePage } from '../pages/SeasonArchivePage';
import { SeasonCreatePage } from '../pages/SeasonCreatePage';
import { SeasonManagement } from '../pages/SeasonManagement';
import { SpecprojectPage } from '../pages/SpecprojectPage';
import { TaskActivationPage } from '../pages/TaskActivationPage';
import { TaskArchivePage } from '../pages/TaskArchivePage';
import { TaskChainActivationPage } from '../pages/TaskChainActivationPage';
import { TaskChainPage } from '../pages/TaskChainPage';
import { TaskPage } from '../pages/TaskPage';

export const RouteNames = {
  REGISTRATION: '/registration',
  PASSWORD_RECOVERY: '/password-recovery',
  ACCOUNT: '/account',
  MAIN: '/main',
  GAMIFICATION: '/gamification',
  TASK: '/task',
  TASK_ARCHIVE: '/task-archive',
  TASK_ACTIVATION: '/task-activation',
  ACHIEVEMENT: '/achievement',
  ACHIEVEMENT_ARCHIVE: '/achievement-archive',
  REWARD_CREATE: '/reward-create',
  REWARD_ARCHIVE: '/reward-archive',
  SEASON_CREATE: '/season-create',
  SEASON_ARCHIVE: '/season-archive',
  CLAIM_CREATE: '/claim-create',
  CLAIMS: '/claims',
  CLAIM: '/claims/:id',
  SPECPROJECT: '/specproject',
  SEASON_MANAGEMENT: '/management',
  METRICS: '/metrics',
  MAP_REWARD_PAGE: '/map-reward-create',
  MAP_CREATE: '/map-create',
  MAP_ARCHIVE: '/map-archive',
  MAP_AWARD_CREATE: '/map-reward-create',
  MAP_AWARD_ARCHIVE: '/map-reward-archive',
  MAP_TASK_CREATE: '/map-task-create',
  TASK_CHAIN: '/task-chain',
  TASK_CHAIN_ACTIVATION: '/task-chain-activation',
};

export const PrivateRoutes = ({
  isSuperAdmin,
  isLoggedIn = false,
}: {
  isSuperAdmin: boolean | null;
  isLoggedIn?: boolean;
}) => (
  <Routes>
    <Route
      path="*"
      element={<GamificationPage isSuperAdmin={isSuperAdmin} />}
    />
    <Route path={RouteNames.MAIN} element={<MainPage />} />
    <Route
      path={RouteNames.REGISTRATION}
      element={
        isLoggedIn ? (
          <GamificationPage isSuperAdmin={isSuperAdmin} />
        ) : (
          <RegisterPage />
        )
      }
    />
    <Route
      path={RouteNames.PASSWORD_RECOVERY}
      element={
        isLoggedIn ? (
          <GamificationPage isSuperAdmin={isSuperAdmin} />
        ) : (
          <PasswordRecoveryPage />
        )
      }
    />
    <Route path={RouteNames.ACCOUNT} element={<PersonalAccountPage />} />
    <Route path={RouteNames.MAP_REWARD_PAGE} element={<MapAwardCreatePage />} />
    <Route
      path={RouteNames.GAMIFICATION}
      element={<GamificationPage isSuperAdmin={isSuperAdmin} />}
    />
    <Route path={RouteNames.TASK} element={<TaskPage />} />
    <Route path={RouteNames.TASK_ARCHIVE} element={<TaskArchivePage />} />
    <Route path={RouteNames.ACHIEVEMENT} element={<AchievementPage />} />
    <Route
      path={RouteNames.ACHIEVEMENT_ARCHIVE}
      element={<AchievementArchivePage />}
    />
    <Route path={RouteNames.REWARD_CREATE} element={<RewardCreatePage />} />
    <Route path={RouteNames.REWARD_ARCHIVE} element={<RewardArchivePage />} />
    <Route
      path={RouteNames.MAP_AWARD_CREATE}
      element={<MapAwardCreatePage />}
    />
    <Route
      path={RouteNames.MAP_AWARD_ARCHIVE}
      element={<MapRewardCreatePage />}
    />
    <Route
      path={RouteNames.MAP_TASK_CREATE}
      element={<MapTaskCreatePage />}
    />
    {/* {!isSuperAdmin && ( */}
    <Route path={RouteNames.SPECPROJECT} element={<SpecprojectPage />} />
    {/* )} */}
    {/* {isSuperAdmin && (
      <> */}
    <Route path={RouteNames.CLAIM_CREATE} element={<ClaimCreatePage />} />
    <Route path={RouteNames.SEASON_CREATE} element={<SeasonCreatePage />} />
    <Route path={RouteNames.SEASON_ARCHIVE} element={<SeasonArchivePage />} />

    <Route path={RouteNames.CLAIMS} element={<ClaimsPage />} />
    <Route path={RouteNames.CLAIM} element={<ClaimPage />} />
    <Route path={RouteNames.SEASON_MANAGEMENT} element={<SeasonManagement />} />
    <Route path={RouteNames.TASK_ACTIVATION} element={<TaskActivationPage />} />
    <Route path={RouteNames.METRICS} element={<MetricsPage />} />

    <Route path={RouteNames.MAP_CREATE} element={<MapCreatePage />} />
    <Route path={RouteNames.MAP_ARCHIVE} element={<MapArchivePage />} />
    <Route path={RouteNames.TASK_CHAIN} element={<TaskChainPage />} />
    <Route
      path={RouteNames.TASK_CHAIN_ACTIVATION}
      element={<TaskChainActivationPage />}
    />
    {/* </> */}
    {/* )} */}
  </Routes>
);
