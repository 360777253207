import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  productService,
  actionService,
  taskService,
  difficultyService,
} from '../services';
import { SelectData } from '../models';
import { theme } from '../theme';
import { useCheckIsSuperAdmin } from '../hooks/useSuperAdminCheck';
import {
  IndicatorWrapper,
  InfoBlock,
  PageContent,
  PageMain,
} from '../components/common/pageLayout/Shared';
import {
  PageHeader,
  PageLeftHalf,
  PageRightHalf,
  PageWrapper,
} from '../components/common/pageLayout';
import { InputSelect } from '../components/common/inputComp/InputSelect';
import { InputText } from '../components/common/inputComp/InputText';
import { Typography } from '../components/common/Typography';
import { SberXP } from '../components/common/SberXP';
import { ConfirmButton } from '../components/common/buttonComp/ConfirmButton';
import { Indicator } from '../components/common/Indicator';

const TaskActiveCheckbox = styled.div`
  font-weight: 600;
  font-size: 18px;
  input {
    margin-right: 10px;
  }
`;

const StyledPageLeftContent = styled.div`
  max-width: 436px;
`;

const Banner = styled.div`
  background-color: ${(props) => props.theme.colors.redBanner};
  border-radius: 0 0 18.4545px 18.4545px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
  max-width: 120px;
  min-height: 235.3px;
  margin-bottom: 49.21px;
`;

const PreviewIcon = styled.div`
  width: 25px;
  height: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 2.5px;
  background-color: #9a57f2;
  border-radius: 4.98204px;
  color: #ffffff;
  font-family: 'SB Sans Text';
  margin-right: 6.85px;
  span {
    font-weight: 600;
    font-size: 7.47305px;
    line-height: 9px;
    text-align: center;
    letter-spacing: -0.02em;
    &:last-child {
      letter-spacing: 0.02em;
      font-size: 4.98204px;
    }
  }
`;

const TaskShortPreview = styled.div`
  width: calc(100% - 24px);
  margin: 55px 20px 80px;
  background-color: #fff;
  padding: 20px 10px;
  border: 1px solid #f3f3f3;
  border-radius: 12.4551px;
  display: flex;
  align-items: center;
`;

const TaskDetailedPreview = styled.div`
  width: 100%;
  max-width: 246px;
  margin: 0 auto 54.89px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const awardXp = [50, 200, 700, 1500];

export const TaskPage = () => {
  const { graySubtitle, blackSidebar, grayInnerHint, greenIndicator, grayBg } =
    theme.colors;
  const navigate = useNavigate();
  const isSuperAdmin = useCheckIsSuperAdmin();

  const [actionsData, setActionsData] = useState<SelectData[]>([]);
  const [productsData, setProductsData] = useState<SelectData[]>([]);
  const [difficultyData, setDifficultyData] = useState<SelectData[]>([]);

  const [product, setProduct] = useState({ id: '0', name: 'Выберите продукт' });
  const [action, setAction] = useState({ id: '0', name: 'Выберите действие' });
  const [difficulty, setDifficulty] = useState({
    id: '0',
    name: 'Уровень сложности',
  });
  const [actionsCount, setActionsCount] = useState('');
  const [duration, setDuration] = useState('');
  const [description, setDescription] = useState('');
  const [linkToTask, setLinkToTask] = useState('');
  const [motivatingPrompt, setMotivatingPrompt] = useState('');
  const [previewBtnIdx, setPreviewBtnIdx] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [nameInSmallCard, setNameInSmallCard] = useState('');
  const [buttonText, setButtonText] = useState('');

  useEffect(() => {
    async function fetchData() {
      const productsRes = (await productService.getAll()).data;
      const products = productsRes.map((el) => ({
        id: String(el.id),
        name: el.name,
      }));
      setProductsData(products);
      if (!isSuperAdmin)
        setProduct(products[products.findIndex((el) => el.id === '1')]);

      const difficulties = (await difficultyService.getAll()).data;
      setDifficultyData(
        difficulties.map((el) => ({ id: String(el.id), name: el.name }))
      );
    }
    if (isSuperAdmin !== null) fetchData();
  }, [isSuperAdmin]);

  useEffect(() => {
    (async function fetchActions() {
      if (product.id === '0') return;
      const actions = (await actionService.getAll(product.id)).data;
      setActionsData(actions.map((el) => ({ id: el.actionId, name: el.name })));
      setAction({ id: '0', name: 'Выберите действие' });
    })();
  }, [product]);

  const clearState = () => {
    setProduct({ id: '0', name: 'Выберите продукт' });
    setAction({ id: '0', name: 'Выберите действие' });
    setDifficulty({
      id: '0',
      name: 'Выберите сложность',
    });
    setActionsCount('');
    setDuration('');
    setDescription('');
  };

  const handleTaskCreate = () =>
    new Promise<void>((resolve) => {
      const task = {
        description,
        motivatingPrompt,
        actionId: action.id,
        duration: +duration,
        actionsCount: +actionsCount,
        difficultyId: +difficulty.id,
        linkToTask,
        product: { id: +product.id, name: product.name },
        isActive,
        //
        buttonText,
        nameInSmallCard,
      };
      taskService.create(task).then((response) => {
        if (response.status === 200) {
          resolve();
          clearState();
        }
      });
    });

  const isValid = () =>
    description.length &&
    motivatingPrompt.length &&
    action.id !== '0' &&
    duration.length &&
    actionsCount.length &&
    difficulty.id !== '0' &&
    linkToTask.length;

  return (
    <PageWrapper>
      <PageContent>
        <PageHeader
          onClick={() => navigate('/gamification')}
          navButtons={[
            { to: '/task', label: 'Создание' },
            { to: '/task-archive', label: 'Уже созданные' },
          ]}
          title="Создание задания"
        />
        <PageMain>
          <PageLeftHalf>
            <StyledPageLeftContent>
              {isSuperAdmin && (
                <InputSelect
                  data={productsData}
                  inputValue={product}
                  setInputValue={setProduct}
                  label="Продукт"
                  mb={23}
                />
              )}
              <InputText
                inputValue={description}
                setInputValue={setDescription}
                label="Название задания"
                placeholder="Например, Поставьте лайк статье"
                innerHint="максимум Х символов"
                maxLength={500}
                multiple
                mb={20}
              />
              <InputText
                inputValue={motivatingPrompt}
                setInputValue={setMotivatingPrompt}
                multiple
                maxLength={500}
                minHeight={120}
                placeholder="Мотивирующая подсказка"
                innerHint="максимум Х символов"
                hint="Напишите, какую пользу получит пользователь, если выполнит задание. Например, какие навыки приобретет или сколько бонусов получит."
                mb={6}
              />
              <Typography
                fz={12}
                lh={16}
                ls="-0.02em"
                ff="SB Sans Text"
                color={graySubtitle}
                mb={20}
              >
                Напишите, какую пользу получит пользователь, если выполнит
                задание. Например, какие навыки приобретет или сколько бонусов
                получит.
              </Typography>
              <InputSelect
                data={actionsData}
                inputValue={action}
                setInputValue={setAction}
                label="Действия"
                hint="Какое действие нужно выполнить"
                mb={20}
              />
              <InputText
                inputValue={duration}
                setInputValue={setDuration}
                label="Характеристики задания"
                placeholder="Срок жизни"
                min={1}
                hint="Сколько времени есть у пользователя, чтобы выполнить задание. Минимальный срок жизни задания — один день."
                max={1000}
                mb={20}
              />
              <InputText
                inputValue={actionsCount}
                setInputValue={setActionsCount}
                placeholder="Количество повторов"
                hint="Сколько раз пользователь должен будет выполнить задание, чтобы получить награду."
                min={1}
                max={999999}
                mb={20}
              />
              <InputSelect
                data={difficultyData}
                inputValue={difficulty}
                setInputValue={setDifficulty}
                hint="За сложные задания пользователь получит больше XP, чем за выполнение более простых."
                mb={20}
              />
              <InputText
                inputValue={linkToTask}
                setInputValue={setLinkToTask}
                label="Ссылка для перехода на страницу продукта"
                placeholder="Вставьте ссылку"
                mb={20}
              />
              <SberXP currentXP="42800" levelXP="50000" mb={20} />
              <TaskActiveCheckbox>
                <input
                  onChange={() => {
                    setIsActive(!isActive);
                  }}
                  type="checkbox"
                />
                {isActive && <>Задание активно</>}
                {!isActive && <>Задание не активно</>}
              </TaskActiveCheckbox>
              <ConfirmButton
                onClick={handleTaskCreate}
                text="Создать задание"
                modalMessage="Задание создано и 
                добавлено в виджет"
                modalBtnText="Хорошо"
                mt={40}
                maxWidth={175}
                disable={!isValid()}
              />
            </StyledPageLeftContent>
          </PageLeftHalf>
          <PageRightHalf
            description="Так задание будет выглядеть в виджете"
            buttons={[
              {
                label: 'Задание',
                callback: () => setPreviewBtnIdx(0),
              },
              {
                label: 'Описание',
                callback: () => setPreviewBtnIdx(1),
              },
            ]}
            currentBtnIdx={previewBtnIdx}
          >
            {previewBtnIdx === 0 ? (
              <TaskShortPreview>
                <PreviewIcon>
                  <span>
                    {awardXp[+difficulty.id === 0 ? 0 : +difficulty.id - 1]}
                  </span>
                  <span>XP</span>
                </PreviewIcon>
                <InfoBlock>
                  <Typography
                    ff="SB Sans Text"
                    fz={12.46}
                    lh={13.7}
                    ls="-0.02em"
                    color={description.length ? blackSidebar : grayInnerHint}
                  >
                    {description.length ? description : 'Названия пока нет'}
                  </Typography>
                  <div>
                    <IndicatorWrapper>
                      <Indicator
                        currentXP="2"
                        levelXP="10"
                        color={greenIndicator}
                      />
                    </IndicatorWrapper>
                    <span>
                      {duration.length ? `${duration} дней` : '30 дней'}
                    </span>
                    <span>{product.name}</span>
                  </div>
                </InfoBlock>
              </TaskShortPreview>
            ) : (
              <TaskDetailedPreview>
                <Banner>
                  <Typography
                    ff="SB Sans Text"
                    fw={600}
                    fz={30.757}
                    lh={39}
                    ls="-0.02em"
                    color={grayBg}
                    mb={0.22}
                  >
                    {awardXp[+difficulty.id === 0 ? 0 : +difficulty.id - 1]}
                  </Typography>
                  <Typography
                    ff="SB Sans Text"
                    fw={600}
                    fz={20.76}
                    lh={26}
                    ls="-0.02em"
                    color={grayBg}
                    mb={30.9}
                  >
                    XP
                  </Typography>
                </Banner>
                <Typography
                  ff="SB Sans Text"
                  fw={600}
                  fz={18.4545}
                  ls="-0.02em"
                  lh="110%"
                  mb={24.6}
                >
                  {description.length ? description : 'Название'}
                </Typography>
                <Typography
                  ff="SB Sans Text"
                  fz={12.303}
                  ls="-0.02em"
                  lh={15}
                  mb={54.89}
                  center
                >
                  {motivatingPrompt.length
                    ? motivatingPrompt
                    : 'Мотивирующая подсказка'}
                </Typography>
              </TaskDetailedPreview>
            )}
          </PageRightHalf>
        </PageMain>
      </PageContent>
    </PageWrapper>
  );
};
