import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { SelectData, MapTaskDto } from '../models';
import { Level, LevelsWrapper } from '../components/common/RewardCreate';
import { taskService, mapService, productService } from '../services';
import {
  PageHeader,
  PageLeftHalf,
  PageWrapper,
} from '../components/common/pageLayout';
import { InputSelect } from '../components/common/inputComp/InputSelect';
import { ConfirmButton } from '../components/common/buttonComp/ConfirmButton';

const StyledPageLeftContent = styled.div`
  max-width: 436px;
  .taskSelect button > div:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const mapInit = {
  id: '0',
  name: 'Выберите карту',
  mapElements: [],
};
const taskInit = {
  id: '0',
  name: 'Выберите задание',
};

type MapSelect = SelectData & {
  mapElements: number[];
};

export const TaskChainPage = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [productsData, setProductsData] = useState<SelectData[]>([]);
  const [product, setProduct] = useState({ id: '0', name: 'Выберите продукт' });
  const [map, setMap] = useState<MapSelect>(mapInit);
  const [mapsData, setMapsData] = useState<MapSelect[]>([]);
  const [task, setTask] = useState<SelectData>(taskInit);
  const [taskIndex, setTaskIndex] = useState(0);
  const [tasksData, setTasksData] = useState<SelectData[]>([]);
  const [taskChain, setTaskChain] = useState<MapTaskDto>({
    mapElement: [
      {
        mapAwardIds: [],
        mapElementId: 0,
        mapTaskId: 0,
      },
    ],
    mapId: 0,
    productId: 0,
  });
  const [awardsData, setAwardsData] = useState<
    { id: string; name: string; imageUrl: string; selected: boolean }[]
  >([]);

  const selectedAwards = useCallback(() => {
    const selected = awardsData.filter((award) => award.selected);
    return {
      id: '0',
      name: selected.length
        ? selected.map((award) => award.name).join(', ')
        : 'Выберите награды',
    };
  }, [awardsData]);

  useEffect(() => {
    async function fetchData() {
      taskService
        .getAll()
        .then(({ data }) =>
          setTasksData(
            data.map((t) => ({ id: String(t.elementId), name: t.actionId }))
          )
        );

      mapService.getAllMaps().then(({ data }) =>
        setMapsData(
          data.map((t) => ({
            id: String(t.id),
            name: t.name,
            mapElements: t.mapElements.map((el) => el.id),
          }))
        )
      );

      mapService.getAllAwards().then(({ data }) =>
        setAwardsData(
          data.map((t) => ({
            id: String(t.id),
            name: t.name,
            imageUrl: `https://static.playground.sysdyn.ru/${t.imageFileKey}`,
            selected: false,
          }))
        )
      );

      const productsRes = (await productService.getAll()).data;
      const products = productsRes.map((el) => ({
        id: String(el.id),
        name: el.name,
        disabled: el.id !== 4,
      }));
      setProductsData(products);
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (map.id !== '0') {
      const newChain = {
        mapId: +map.id,
        productId: +product.id,
        mapElement: new Array(map.mapElements.length).fill({
          mapAwardIds: [],
          mapElementId: 0,
          mapTaskId: 0,
        }) as MapTaskDto['mapElement'],
      };
      newChain.mapElement = newChain.mapElement.map((el, index) => {
        const currentMap = mapsData.find((m) => m.id === map.id)!!;
        return {
          ...el,
          mapElementId: currentMap.mapElements[index],
        };
      });
      setTaskChain(newChain);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  useEffect(() => {
    const taskId =
      taskChain.mapElement.length > 0
        ? taskChain.mapElement[taskIndex]?.mapTaskId
        : null;
    setTask(taskId ? tasksData.find((t) => +t.id === taskId)!! : taskInit);
  }, [taskIndex, taskChain, tasksData]);

  useEffect(() => {
    setAwardsData((prev) =>
      prev.map((award) => ({
        ...award,
        selected: taskChain.mapElement[taskIndex].mapAwardIds.includes(
          +award.id
        ),
      }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskIndex]);

  const handleChainCreate = () =>
    new Promise<void>((resolve) => {
      setLoading(true);

      mapService.createTaskChain(taskChain).then((response) => {
        if (response.status === 200) {
          resolve();
        }
      });

      setLoading(false);
    });

  const handleTaskSelect = (v: SelectData) => {
    setTaskChain((prev) => ({
      ...prev,
      mapElement: prev.mapElement.map((t, i) =>
        i === taskIndex ? { ...t, mapTaskId: +v.id } : t
      ),
    }));
    setTask(v);
  };

  const handleTrophySelect = useCallback(
    (v: SelectData) => {
      setAwardsData((prev) =>
        prev.map((award) => ({
          ...award,
          selected: award.id === v.id ? !award.selected : award.selected,
        }))
      );

      setTaskChain((prev) => ({
        ...prev,
        mapElement: prev.mapElement.map((t, i) =>
          i === taskIndex
            ? {
                ...t,
                mapAwardIds:
                  t.mapAwardIds.length && t.mapAwardIds.includes(+v.id)
                    ? t.mapAwardIds.filter((el) => el !== +v.id)
                    : [...t.mapAwardIds, +v.id],
              }
            : t
        ),
      }));
    },
    [taskIndex]
  );

  const handleProductSelect = useCallback((v: SelectData) => {
    setProduct(v);
    setTaskChain((prev) => ({ ...prev, productId: +v.id }));
  }, []);

  const isValid = () =>
    !loading &&
    map !== mapInit &&
    product.id !== '0' &&
    taskChain.mapElement.every((t) => t.mapTaskId && t.mapAwardIds.length > 0);

  return (
    <PageWrapper>
      <PageLeftHalf>
        <StyledPageLeftContent>
          <PageHeader
            onClick={() => navigate('/gamification')}
            title="Создание цепочки заданий"
          />
          <InputSelect
            data={productsData}
            inputValue={product}
            setInputValue={handleProductSelect}
            label="Продукт"
            mb={23}
          />
          <InputSelect
            data={mapsData}
            inputValue={map}
            setInputValue={setMap}
            label="Карта"
            mb={23}
          />
          {map.id !== '0' && (
            <>
              <LevelsWrapper>
                {taskChain.mapElement.map((t, placeIndex) => (
                  <Level
                    key={uuidv4()}
                    type="button"
                    free
                    selected={placeIndex === taskIndex}
                    onClick={() => setTaskIndex(placeIndex)}
                  >
                    {placeIndex + 1}
                  </Level>
                ))}
              </LevelsWrapper>
              <InputSelect
                // className="taskSelect"
                data={tasksData}
                inputValue={task}
                setInputValue={handleTaskSelect}
                label="Задание"
                mb={23}
              />
              <InputSelect
                data={awardsData}
                inputValue={selectedAwards()}
                setInputValue={handleTrophySelect}
                multiple
                label="Награды"
                mb={23}
              />
            </>
          )}
          <ConfirmButton
            onClick={handleChainCreate}
            text="Создать цепочку"
            modalMessage="Цепочка создана"
            modalBtnText="Хорошо"
            disable={!isValid()}
            mt={40}
          />
        </StyledPageLeftContent>
      </PageLeftHalf>
    </PageWrapper>
  );
};
