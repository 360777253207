import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { ArrowIcon } from '../../../assets/icons/ArrowIcon';

type Props = {
  onClick?: () => void;
  title: string;
  mb?: number;
  archivePage?: boolean;
  navButtons?: any[];
};

const PageHeaderWrapper = styled.div<{ archivePage?: boolean }>`
  position: relative;
  width: 100%;
  max-width: ${({ archivePage }) => (archivePage ? '792px' : 'unset')};
  margin: ${({ archivePage }) => (archivePage ? 'auto' : 'unset')};
  padding-bottom: ${({ archivePage }) => (archivePage ? '14px' : '40px')};
  .header-buttons {
    display: flex;
    gap: 6px;
  }
`;

const MainBlock = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  color: ${(props) => props.theme.colors.graySubtitle};
  font-family: 'SB Sans Text';
  margin-left: 5px;
  margin-top: -2px;
  line-height: 14px;
  letter-spacing: -0.02em;
`;

const Title = styled.div`
  margin: 8px 0 16px;
  ${(propList: Props) =>
    propList.mb &&
    css`
      margin: 14px 0 ${(props: Props) => `${props.mb ?? 0}px`};
      border-bottom: 1px solid ${(props) => props.theme.colors.grayBorderButton};
      padding-bottom: 16px;
    `};
  font-family: 'SB Sans Text';
  font-weight: 500;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.02em;
`;

export const HeaderButton = styled.button<{ active?: boolean }>`
  color: ${(props) => props.theme.colors.blackSidebar};
  border: 1px solid #d5d5d5;
  padding: 8px 10px;
  background-color: #f4f4f4;
  border-radius: 36px;
  font-family: 'SB Sans Text';
  line-height: 110%;
  cursor: pointer;
  ${(propList: { active?: boolean }) =>
    propList.active &&
    css`
      background: #3e4149;
      border: 1px solid #3e4149;
      color: #fff;
    `};
`;
export const StyledNavLink = styled(NavLink)`
  color: ${(props) => props.theme.colors.blackSidebar};
  text-decoration: none;
  border: 1px solid #d5d5d5;
  padding: 8px 10px;
  background-color: #f4f4f4;
  border-radius: 36px;
  font-family: 'SB Sans Text';
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  cursor: pointer;
  &.active {
    background: #3e4149;
    border: 1px solid #3e4149;
    color: #fff;
  }
`;

const Divider = styled.div`
  background-color: #e4e5e7;
  height: 1px;
  position: absolute;
  width: 200vw;
  left: -100vw;
  bottom: 24px;
`;

export const PageHeader = (props: Props) => {
  const { onClick, title, mb, archivePage, navButtons = [] } = props;
  return (
    <PageHeaderWrapper archivePage={archivePage}>
      {onClick && (
        <MainBlock type="button" onClick={onClick}>
          <ArrowIcon small />
          <Label>вернуться</Label>
        </MainBlock>
      )}
      <Title mb={mb} title={title}>
        {title}
      </Title>
      {!!navButtons.length && (
        <div className="header-buttons">
          {/* {!navButtons.length && (
            <div>
              <HeaderButton active>Создание</HeaderButton>
              <HeaderButton>Уже созданные</HeaderButton>
            </div>
          )} */}

          {navButtons.map((button) => (
            <StyledNavLink key={uuidv4()} to={button.to}>
              {button.label}
            </StyledNavLink>
          ))}
        </div>
      )}
      {!archivePage && <Divider />}
    </PageHeaderWrapper>
  );
};
