export const ArrowIcon = ({ small }: { small?: true }) => (
  <svg
    width={small ? 6 : 14}
    height={small ? 10 : 8}
    viewBox={small ? '0 0 6 10' : '0 0 14 8'}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d={small ? 'M5 9L1 5L5 1' : 'M1 1L7 7L13 1'}
      stroke={small ? '#6F6F6F' : '#8E8E8E'}
      strokeWidth={small ? '1.5' : '2'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
