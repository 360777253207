export const DateArrowIcon = ({ onClick }: { onClick: () => void }) => (
  <svg
    onClick={onClick}
    width="8"
    height="4"
    viewBox="0 0 8 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.35355 3.64645L7.14645 0.853553C7.46143 0.538571 7.23835 -6.42027e-08 6.79289 -1.01751e-07L1.20711 -5.72598e-07C0.761655 -6.10147e-07 0.538571 0.53857 0.853553 0.853552L3.64645 3.64645C3.84171 3.84171 4.15829 3.84171 4.35355 3.64645Z"
      fill="#BEBEBE"
    />
  </svg>
);
