import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { theme } from '../../../theme';
import { Typography } from '../Typography';
import { HeaderButton } from './PageHeader';

type Props = { 
  maxWidth?: number,
  minHeight?: number,
};

type ContentProps = {
  bgColor? : string;
  flexDirection?: 'row';
}

export const StyledRightHalf = styled.div<Props>`
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  max-width: ${(props: Props) => `${props.maxWidth ?? 400}px`};
  width: 100%;
  ${(propList: Props) =>
    propList.minHeight &&
    css`
      min-height: ${(props: Props) => `${props.minHeight}px`};
  `};
  margin-top: 24px;
  padding: 24px;
  background: #ffffff;
  border-radius: 16px;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
`;

const ContentWrapper = styled.div<ContentProps>`
  padding: 0 8px 8px;
  background-color: ${(props: ContentProps) => props.bgColor ?? theme.colors.grayPreviewContent};
  display: flex;
  flex-grow: 1;
  flex-direction: ${(props: ContentProps) => props.flexDirection ?? 'column'};
  /* flex-direction: column; */
  align-items: center;
  border-radius: 12px;
  .header-buttons {
    display: flex;
    gap: 6px;
    margin-right: auto;
  }
`;

export const PageRightHalf = ({
  children,
  buttons,
  currentBtnIdx,
  maxWidth,
  description,
  minHeight,
  bgColor,
  flexDirection,
}: {
  children: ReactNode;
  buttons?: { label: string; callback: () => void }[];
  currentBtnIdx?: number;
  maxWidth?: number;
  description?: string;
  minHeight?: number;
  bgColor?: string;
  flexDirection? : 'row';
}) => {
  const { graySubtitle } = theme.colors;
  
  const renderButtons = () => (
      buttons ? (
        <div className="header-buttons">
          {buttons.map(({label,callback}, index) => (
            <HeaderButton
              key={uuidv4()}
              active={currentBtnIdx === index}
              onClick={callback}
            >
              {label}
            </HeaderButton>
          ))}
        </div>
      ) : (null)
    )

  return (
    <StyledRightHalf maxWidth={maxWidth} minHeight={minHeight}>
      <Typography ff="SB Sans Text" fw={500} fz={14} ls="-0.02em" center mb={6}>
        Предварительный просмотр
      </Typography>
      <Typography
        ff="SB Sans Text"
        fz={12}
        lh={16}
        ls="-0.02em"
        center
        mb={20}
        color={graySubtitle}
      >
        {description ?? 'НЕТ ОПИСАНИЯ'}
      </Typography>
      <ContentWrapper bgColor={bgColor} flexDirection={flexDirection}>
        {children}
        {renderButtons()}
      </ContentWrapper>
    </StyledRightHalf>
  );
};
