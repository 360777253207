import { useEffect, useState } from "react";

type useHandlePreviewImageProps = {
  userImages: (File | null)[],
}

const reader = new FileReader();

export const useHandlePreviewImage = ({userImages}: useHandlePreviewImageProps) => {
  const [previewImage, setPreviewImage] = useState<string | null>(null);

  useEffect(() => {
    const handlePreview = (file: File | null) => {
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = (e: ProgressEvent<FileReader>) => {
          if (typeof e.target?.result === 'string') {
            setPreviewImage(e.target.result);
          };
        };
      } else {
        setPreviewImage(null)
      }
    };
  
    handlePreview(userImages[0])
  },[userImages])

  return({previewImage})
}