import styled from 'styled-components';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import { QuestionLabel } from '../components/common/QuestionLabel';
import { mapService } from '../services';
import { MapRes } from '../models';
import { Warning } from '../components/common/Warning';
import { getClaimDateFormat } from '../utils';
import { theme } from '../theme';
import { PageHeader } from '../components/common/pageLayout';
import { SearchIcon } from '../assets/icons/SearchIcon';
import { ConfirmButton } from '../components/common/buttonComp/ConfirmButton';

const StyledClaimsWrapper = styled.div`
  margin: 10px 32px;
  width: 100%;
`;

const StyledSearchField = styled.div`
  position: relative;
  max-width: 360px;
  margin-bottom: 16px;
  & > svg {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Thead = styled.thead`
  background-color: ${(props) => props.theme.colors.grayBgButton};
  border-radius: 8px;
  tr {
    color: ${(props) => props.theme.colors.graySubtitle};
    background-color: ${(props) => props.theme.colors.grayBgButton};
    th:first-child {
      border-radius: 8px 0 0 8px;
    }
    th:last-child {
      border-radius: 0 8px 8px 0;
      min-width: 180px;
    }
  }
`;

const Tbody = styled.tbody`
  &:before {
    content: '@';
    display: block;
    line-height: 6px;
    text-indent: -99999px;
  }
  tr:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.grayBgButton};
  }
`;

const Tr = styled.tr`
  text-align: left;
`;

const Th = styled.th`
  padding: 12px 16px;
  & > div {
    position: relative;
    width: fit-content;
    padding-right: 12px;
  }
`;

const Td = styled.td`
  padding: 14px 16px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const SearchInput = styled.input`
  background-color: ${(props) => props.theme.colors.grayBgButton};
  color: ${(props) => props.theme.colors.graySubtitle};
  border: 1px solid ${(props) => props.theme.colors.grayBgButton};
  line-height: 22px;
  width: 100%;
  border-radius: 8px;
  padding: 8px 45px 8px 16px;
  outline: none;
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.greenBg};
  }
`;

const columns = [
  {
    field: 'id',
    label: 'Номер карты',
    controls: false,
  },
  {
    field: 'name',
    label: 'Название',
    controls: false,
  },
  {
    field: 'productId',
    label: 'Продукт ',
    controls: false,
  },
  {
    field: 'mapElementsLength',
    label: 'Кол-во заданий',
    controls: false,
  },
  {
    field: 'startDate',
    label: 'Дата старта',
    controls: false,
  },
  {
    field: 'duration',
    label: 'Продолжительность',
    controls: false,
  },
  {
    field: 'awardName',
    label: 'Название награды',
    controls: false,
  },
  {
    field: 'activation',
    label: '',
    controls: false,
  },
];

export const TaskChainActivationPage = () => {
  const [search, setSearch] = useState('');
  const [maps, setMaps] = useState<MapRes[]>([]);
  const [busyMaps, setBusyMaps] = useState<number[]>([]);
  const navigate = useNavigate();

  const { greenBg } = theme.colors;

  useEffect(() => {
    mapService.getAllMaps().then(({ data }) => {
      setMaps(data);
    });
  }, []);

  const filteredAndSortedClaims = useCallback(() => {
    const filtered = maps.filter((el) => String(el.name).includes(search));
    return filtered;
  }, [search, maps]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleTaskActivate = (id: number) =>
    new Promise<void>((resolve) => {
      const map = maps.find((t) => t.playbook.id === id);

      if (map) {
        const playbookId = map.playbook.id;
        setBusyMaps((prev) => [...prev, playbookId]);
        mapService
          .activateMap(playbookId)
          .then((response) => {
            if (response.status === 200) {
              resolve();
            }
          })
          .finally(() =>
            setBusyMaps((prevData) => prevData.filter((t) => t !== playbookId))
          );
      }
    });

  return (
    <StyledClaimsWrapper>
      <Warning right />
      <PageHeader
        onClick={() => navigate('/gamification')}
        title="Активация цепочки заданий"
      />
      <StyledSearchField>
        <SearchInput
          type="text"
          placeholder="Поиск по цепочкам заданий"
          value={search}
          onChange={handleSearchChange}
        />
        <SearchIcon />
      </StyledSearchField>
      <Table>
        <Thead>
          <Tr>
            {columns.map((column) => (
              <Th key={uuidv4()}>
                <div>{column.label}</div>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {filteredAndSortedClaims().map((map) => (
            <Tr key={map.playbook.id}>
              <Td>{`№${map.playbook.id}`}</Td>
              <Td>{map.name}</Td>
              <Td>{map.playbook.productId}</Td>
              <Td>{map.mapElements.length}</Td>
              <Td>{map.startDate ? getClaimDateFormat(map.startDate) : ''}</Td>
              <Td>{map.duration}</Td>
              <Td>{map.mapAward.name}</Td>
              <Td>
                <ConfirmButton
                  onClick={() => handleTaskActivate(map.playbook.id)}
                  background={greenBg}
                  text="Активировать"
                  modalMessage="Карта активирована"
                  modalBtnText="Хорошо"
                  disable={
                    busyMaps.includes(map.playbook.id) || !!map.startDate
                  }
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <QuestionLabel />
    </StyledClaimsWrapper>
  );
};
