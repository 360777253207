export const SearchIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.66634 6.91663C0.66634 10.3684 3.46456 13.1666 6.91634 13.1666C8.35937 13.1666 9.68818 12.6776 10.7461 11.8562C10.7859 11.9405 10.8407 12.0195 10.9104 12.0892L15.9104 17.0892C16.2359 17.4147 16.7635 17.4147 17.0889 17.0892C17.4144 16.7638 17.4144 16.2361 17.0889 15.9107L12.0889 10.9107C12.0192 10.841 11.9402 10.7862 11.8559 10.7464C12.6773 9.68847 13.1663 8.35966 13.1663 6.91663C13.1663 3.46485 10.3681 0.666626 6.91634 0.666626C3.46456 0.666626 0.66634 3.46485 0.66634 6.91663ZM2.33301 6.91663C2.33301 4.38532 4.38504 2.33329 6.91634 2.33329C9.44765 2.33329 11.4997 4.38532 11.4997 6.91663C11.4997 9.44793 9.44765 11.5 6.91634 11.5C4.38504 11.5 2.33301 9.44793 2.33301 6.91663Z"
      fill="black"
    />
  </svg>
);
