import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import avatar from '../assets/images/avatar.png';
import { theme } from '../theme';
import { productService, specprojectService } from '../services';

import { getClaimDateFormat } from '../utils';
import { PageContent } from '../components/common/pageLayout/Shared';
import { SelectData } from '../models';
import {
  PageHeader,
  PageLeftHalf,
  PageWrapper,
} from '../components/common/pageLayout';
import { Typography } from '../components/common/Typography';
import { InputSelect } from '../components/common/inputComp/InputSelect';
import { InputText } from '../components/common/inputComp/InputText';

const TextareaContainer = styled.div<any>`
  color: '#000';
`;

const AuthorInfoWrapper = styled.div`
  padding: 16px 32px 16px 16px;
  height: 156px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 14px;
  margin-bottom: 27px;
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 27;
`;

const AuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
`;
const Line = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  line-height: 24px;
  gap: 4px;
`;

const ClaimInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Avatar = styled.img`
  width: 52px;
  height: 52px;
`;

export const ClaimPage = () => {
  const { grayHeaderText, blackLabel, blackSidebar, grayDarkBgButton } =
    theme.colors;
  const navigate = useNavigate();
  const location = useLocation();

  const [number, setNumber] = useState('');
  const [date, setDate] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone] = useState('');
  const [product, setProduct] = useState({
    id: '0',
    name: 'Продукт не выбран',
  });
  const [description, setDescription] = useState('');
  const [productsData, setProductsData] = useState<SelectData[]>([]);

  useEffect(() => {
    async function fetchData() {
      const productsRes = (await productService.getAll()).data;
      const products = productsRes.map((el) => ({
        id: String(el.id),
        name: el.name,
      }));
      setProductsData(products);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const id = location.pathname.split('/').slice(-1)[0];
    specprojectService.getByPlaybookId(+id).then(({ data }) => {
      setDate(getClaimDateFormat(data.dateOfReciving));
      setFullName(data.fullName);
      setEmail(data.email);
      setProduct({ id: `${data.product.id}`, name: data.product.name });
      setDescription(data.description);
      setNumber(String(data.elementId));
    });
  }, [location.pathname]);

  return (
    <PageWrapper>
      <PageContent>
        <PageHeader onClick={() => navigate('/claims')} title="Заявка" />
        <PageLeftHalf>
          <AuthorInfoWrapper>
            <Avatar src={avatar as string} alt="avatar" />
            <AuthorInfo>
              <Typography color="#000" mb={4} lh={20} fz={16} fw={600}>
                {fullName}
              </Typography>
              <Typography mb={6} color={grayHeaderText} lh={20} ls="-0.01em">
                {email}
              </Typography>
              <Typography mb={6} color={grayHeaderText} lh={20} ls="-0.01em">
                {phone}
              </Typography>
              <Line>
                <Typography color={grayHeaderText}>Номер заявки</Typography>
                <Typography color={blackLabel}>#{number}</Typography>
              </Line>
              <Line>
                <Typography color={grayHeaderText}>Дата отправки</Typography>
                <Typography color={blackLabel}>{date}</Typography>
              </Line>
            </AuthorInfo>
          </AuthorInfoWrapper>
          <ClaimInfo>
            <InputSelect
              data={productsData}
              inputValue={product}
              setInputValue={setProduct}
              label="Продукт"
              mb={6}
              disabled
            />
            <TextareaContainer>
              <InputText
                borderColor={grayDarkBgButton}
                inputValue={description}
                setInputValue={setDescription}
                multiple
                maxLength={500}
                minHeight={120}
                placeholder="Напишите свой вопрос, идею или пожелание в свободной форме."
                innerHint=""
                hint="Напишите свой вопрос, идею или пожелание в свободной форме."
                mb={6}
                color={blackSidebar}
                disabled
              />
            </TextareaContainer>
          </ClaimInfo>
        </PageLeftHalf>
      </PageContent>
    </PageWrapper>
  );
};
