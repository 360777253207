import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SelectData } from '../models';
import { mapService, productService, } from '../services';
import { PageContent, PageMain } from '../components/common/pageLayout/Shared';
import { MapPreview } from '../components/common/MapPreviewOld';
import deltaNila from '../assets/images/deltaNila.png';
import {
  PageHeader,
  PageLeftHalf,
  PageRightHalf,
  PageWrapper,
} from '../components/common/pageLayout';
import { InputSelect } from '../components/common/inputComp/InputSelect';
import { ConfirmButton } from '../components/common/buttonComp/ConfirmButton';

const StyledPageLeftContent = styled.div`
  max-width: 436px;
`;

const mapInit = {
  id: '0',
  name: 'Выберите карту',
  mapElements: [],
};

type MapSelect = SelectData & {
  mapElements: number[];
};

export const MapTaskCreatePage = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [productsData, setProductsData] = useState<SelectData[]>([]);
  const [product, setProduct] = useState({ id: '0', name: 'Выберите продукт' });
  const [mapSelected, setMapSelected] = useState<MapSelect>(mapInit)
  const [maps, setMaps] = useState<MapSelect[]>([]);

  useEffect(() => {
      productService.getAll().then(({data}) => {
        setProductsData(data.map(({id, name}) => ({
          id: String(id),
          name
        })))
      })

      mapService.getAllMaps().then(({ data }) =>
      setMaps(
        data.map(({id, name, mapElements}) => ({
          id: String(id),
          name,
          mapElements: mapElements.map((el) => el.id),
        }))
      )
    );
  }, []);

  const handleMapCreate = () =>
    new Promise<void>((resolve) => {
      setLoading(true);
      const mapDto = {
        description: '',
        duration: 2,
        mapAwardId: 4,
        name: 'test',
        elementCount: 5,
        productId: 20,
      };

      mapService.createMap(mapDto).finally(() => {
        resolve();
        setLoading(false);
      });
    });

  const isValid = () =>
    !loading

  // const isValid = () =>
  //   !loading &&
  //   name.length &&
  //   description.length &&
  //   elementsCount.id !== '0';

  return (
    <PageWrapper>
      <PageContent>
        <PageHeader
          onClick={() => navigate('/gamification')}
          navButtons={[
            { to: '/map-create', label: 'Создание' },
            { to: '/map-archive', label: 'Уже созданные' },
          ]}
          title="Карта сокровищ"
        />
        <PageMain>
          <PageLeftHalf>
            <StyledPageLeftContent>
              <InputSelect
                data={maps}
                inputValue={mapSelected}
                setInputValue={setMapSelected}
                label="Карта"
                mb={23}
              />
              <InputSelect
                data={productsData}
                inputValue={product}
                setInputValue={setProduct}
                label="Продукт"
                mb={6}
                />
              <ConfirmButton
                onClick={handleMapCreate}
                text="Создать задание"
                modalMessage="Задание создано и 
                добавлено в виджет"
                modalBtnText="Хорошо"
                mt={40}
                maxWidth={175}
                disable={!isValid()}
              />
            </StyledPageLeftContent>
          </PageLeftHalf>
          <PageRightHalf
            maxWidth={333}
            description="Так задание будет выглядеть в виджете"
          >
            <MapPreview
              backgroundSrc={deltaNila as string}
              description='Вывести описание'
              name='Вывести Имя'
            />
          </PageRightHalf>
        </PageMain>
      </PageContent>
    </PageWrapper>
  );
};
