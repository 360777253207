import styled from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { achievementService } from '../services';
import { getClaimDateFormat, stringToTimestamp } from '../utils';
import { AchievementArchive, AchievementArchiveField } from '../models';
import { PageHeader, PageWrapper } from '../components/common/pageLayout';
import { InputControls } from '../components/common/inputComp/InputControls';

export type Difficulties = {
  id: number;
  name: string;
};

const StyledAchievementsWrapper = styled.div`
  /* padding: 143px 32px 30px 108px; */
  overflow: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  width: 1440px;
  height: 1153px;
  max-height: 1153px;
  background: #f3f3f3;
`;

const Thead = styled.thead`
  background-color: ${(props) => props.theme.colors.whiteBg};
  tr {
    color: ${(props) => props.theme.colors.grayHeaderText};
    background-color: ${(props) => props.theme.colors.whiteBg};
    th {
      line-height: 24px;
      letter-spacing: -0.01em;
      color: ${(props) => props.theme.colors.grayHeaderText};
    }
    th:first-child {
      border-radius: 8px 0 0 8px;
    }
    th:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
`;

const Tbody = styled.tbody`
  tr {
    background-color: ${(props) => props.theme.colors.whiteBg};
    line-height: 24px;
    letter-spacing: -0.01em;
    cursor: pointer;
    td:first-child {
      border-radius: 10px 0 0 10px;
    }
    td:last-child {
      border-radius: 0 10px 10px 0;
    }
  }
`;

const Tr = styled.tr`
  text-align: left;
`;

const Th = styled.th`
  padding: 9px 16px;
  & > div {
    position: relative;
    width: fit-content;
    padding-right: 12px;
  }
`;

const Td = styled.td`
  padding: 15px 16px;
  color: ${(props) => props.theme.colors.blackLabel};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
`;

const ImgWrapper = styled.div`
  position: absolute;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const StyledImg = styled.img`
  object-fit: contain;
`;

const PageContent = styled.div`
  max-width: 1300px;
  margin: 0 auto;
`;

export const PageMain = styled.div`
  display: flex;
  align-items: flex-start;
`;

const columns = [
  {
    field: 'name',
    label: 'Название',
    controls: true,
    sortType: 'lowCaseString',
  },
  {
    field: 'startDate',
    label: 'Дата создания',
    controls: true,
    sortType: 'date',
  },
  {
    field: 'actionsCount',
    label: 'Количество повторов',
    controls: true,
    sortType: 'number',
  },
] as {
  field: AchievementArchiveField;
  label: string;
  controls: boolean;
  sortType: string;
}[];

type AchievementListItem = AchievementArchive & {
  playbookId: number;
  imageUrl: string;
};

export const AchievementArchivePage = () => {
  const navigate = useNavigate();

  const [filter, setFilter] = useState<{
    field: AchievementArchiveField;
    dir: 'desc' | 'asc';
    sortType: string;
  }>({
    field: 'startDate',
    dir: 'desc',
    sortType: 'date',
  });
  const [achievements, setAchievements] = useState<AchievementListItem[]>([]);

  useEffect(() => {
    achievementService.getAll().then(({ data }) => {
      const achievementsLoaded = data.map((achievement) => ({
        playbookId: achievement.playbookId,
        name: achievement.name,
        startDate: getClaimDateFormat(achievement.startDate),
        actionsCount: achievement.actionsCount,
        imageUrl: achievement.imageUrl,
      }));
      setAchievements(achievementsLoaded);
    });
  }, []);

  const filteredAndSortedAchievements = useCallback(() => {
    const sorted = achievements.sort((a, b) => {
      if (filter.sortType === 'number') {
        if (+a[filter.field] > +b[filter.field])
          return filter.dir === 'desc' ? -1 : 1;
        if (+a[filter.field] < +b[filter.field])
          return filter.dir === 'desc' ? 1 : -1;
      }

      if (filter.sortType === 'lowCaseString') {
        if (
          (a[filter.field] as string).toLowerCase() >
          (b[filter.field] as string).toLowerCase()
        )
          return filter.dir === 'desc' ? -1 : 1;
        if (
          (a[filter.field] as string).toLowerCase() <
          (b[filter.field] as string).toLowerCase()
        )
          return filter.dir === 'desc' ? 1 : -1;
      }

      if (filter.field === 'startDate') {
        if (
          stringToTimestamp(a[filter.field]) <
          stringToTimestamp(b[filter.field])
        )
          return filter.dir === 'desc' ? -1 : 1;
        if (
          stringToTimestamp(a[filter.field]) >
          stringToTimestamp(b[filter.field])
        )
          return filter.dir === 'desc' ? 1 : -1;
      }
      if (a[filter.field] < b[filter.field])
        return filter.dir === 'desc' ? -1 : 1;
      if (a[filter.field] > b[filter.field])
        return filter.dir === 'desc' ? 1 : -1;
      return 0;
    });
    return sorted;
  }, [achievements, filter]);

  const buttonClickHandler = (id: string) => {
    console.log(id);
    // navigate(`${id}`);
  };

  return (
    <PageWrapper>
      <PageContent>
        <PageHeader
          onClick={() => navigate('/gamification')}
          title="Создание медали"
          navButtons={[
            { to: '/achievement', label: 'Создание' },
            { to: '/achievement-archive', label: 'Уже созданные' },
          ]}
          archivePage
        />
        <PageMain>
          <StyledAchievementsWrapper>
            {/* <PageHeader title="Заявки" /> */}
            {/* <StyledSearchField>
        <SearchInput
          type="text"
          placeholder="Поиск по заявкам"
          value={search}
          onChange={handleSearchChange}
        />
        <SearchIcon />
      </StyledSearchField> */}
            <Table>
              <Thead>
                <Tr>
                  {columns.map((column) => (
                    <Th key={uuidv4()}>
                      <div>
                        {column.label}
                        {column.controls && (
                          <InputControls
                            handleStepUpClick={() =>
                              setFilter({
                                field: column.field,
                                dir: 'desc',
                                sortType: column.sortType,
                              })
                            }
                            handleStepDownClick={() =>
                              setFilter({
                                field: column.field,
                                dir: 'asc',
                                sortType: column.sortType,
                              })
                            }
                          />
                        )}
                      </div>
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {filteredAndSortedAchievements().map((achievement) => (
                  <Tr
                    key={uuidv4()}
                    onClick={() =>
                      buttonClickHandler(String(achievement.playbookId))
                    }
                  >
                    <Td>
                      <ImgWrapper>
                        <StyledImg
                          src={achievement.imageUrl}
                          width="46px"
                          aspect-ratio="auto 46 / 46"
                          height="46px"
                        />
                        {achievement.name}
                      </ImgWrapper>
                    </Td>
                    <Td>{achievement.startDate}</Td>
                    <Td>{achievement.actionsCount}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </StyledAchievementsWrapper>
        </PageMain>
      </PageContent>
    </PageWrapper>
  );
};
