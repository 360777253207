import styled from 'styled-components';

import { getNoun } from '../../utils';
import { Typography } from './Typography';

type Props = {
  imageUrl: string
};

const ImageWrapper = styled.div`
  height: 417px;
`
const Image = styled.img`
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
`

export const MapPreview = (props: Props) => {
  const {imageUrl} = props;

  return (
      <ImageWrapper>
        <Image src={imageUrl}/>
      </ImageWrapper>
  );
};
