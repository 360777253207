import api from '../http';
import {
  MapAwardDto,
  MapAwardRes,
  MapDto,
  MapRes,
  MapTaskDto,
} from '../models';

export const mapService = {
  createMapAward: (payload: MapAwardDto) =>
    api.post<MapAwardRes>('playbooks/map-awards/', payload),
  getAllAwards: () => api.get<MapAwardRes[]>('playbooks/map-awards/'),
  getAllMaps: () => api.get<MapRes[]>('playbooks/maps/'),
  createMap: (payload: MapDto) => api.post<MapRes>('playbooks/maps/', payload),
  createTaskChain: (payload: MapTaskDto) =>
    api.post<any>('playbooks/maps/task-chain', payload),
  activateMap: (playbookId: number) =>
    api.put(`playbooks/maps/start/playbookId=${playbookId}`),
};
