import api from '../http';
import { Rarity } from '../models';

export const metricService = {
  getMetrics: (playbookId: any) =>
    api.get<Rarity[]>(`user-statistics/${playbookId}/season-award`),
  getAwardStatistic: () => api.get('user-statistics/award-statistic '),
  awardCompleteExport: () =>
    api.get('user-statistics/award-complete-export', {
      responseType: 'blob',
    }),
  awardTakenExport: () =>
    api.get('user-statistics/award-taken-export', {
      responseType: 'blob',
    }),
  achievementExport: () => api.get(`/user-statistics/achievement-export`),
  getTaskMetrics: () => api.get('user-statistics/tasks/metrics'),
  getTaskMetricsByPlaybookId: (playbookId: number) =>
    api.get(`/user-statistics/${playbookId}/tasks`),
  getMedalsMetric: () => api.get('playbooks/achievements/achievement/metrics'),
  getMedalsMetricByPlaybookId: (playbookId: number) =>
    api.get(`/playbooks/achievements/${playbookId}/achievement`),
  getRanksMetrics: () => api.get('/user-statistics/ranks-metrics'),
  getAllRanks: () => api.get('user-statistics/all-ranks '),
  getRankMetricById: (id:number) => api.get(`/user-statistics/rank-statistic/${id}`),
};
