import { ReactNode } from 'react';

import styled, { css } from 'styled-components';

type Props = {
  children: ReactNode;
  className?: string;
  fz?: number;
  mb?: number;
  mr?: number;
  fw?: number;
  lh?: string | number;
  ls?: string;
  ff?: string;
  color?: string;
  center?: boolean;
};

const StyledTypography = styled.div`
  ${(propList: Props) =>
    propList.ff &&
    css`
      font-family: ${(props: Props) => `${props.ff ?? ''}`};
    `};
  ${(propList: Props) =>
    propList.mb &&
    css`
      margin-bottom: ${(props: Props) => `${props.mb ?? 0}px;`};
    `};
  ${(propList: Props) =>
    propList.mr &&
    css`
      margin-right: ${(props: Props) => `${props.mr ?? 0}px;`};
    `};
  ${(propList: Props) =>
    propList.fz &&
    css`
      font-size: ${(props: Props) => `${props.fz ?? 0}px;`};
    `};
  ${(propList: Props) =>
    propList.fw &&
    css`
      font-weight: ${(props: Props) => `${props.fw ?? 0};`};
    `};
  ${(propList: Props) =>
    propList.lh &&
    css`
      line-height: ${(props: Props) => {
        if (String(props.lh).includes('%')) return props.lh;
        return `${props.lh ?? 0}px;`;
      }};
    `};
  ${(propList: Props) =>
    propList.color &&
    css`
      color: ${(props: Props) => `${props.color ?? '#000'};`};
    `};
  ${(propList: Props) =>
    propList.ls &&
    css`
      letter-spacing: ${(props: Props) => `${props.ls ?? '0px'};`};
    `};
  ${(propList: Props) =>
    propList.center &&
    css`
      text-align: center;
    `};
`;

export const Typography = (props: Props) => <StyledTypography {...props} />;
