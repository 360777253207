export const DocsIcon = ({ fill = '#85858F' }: { fill?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="23"
    viewBox="0 0 20 23"
    fill="none"
  >
    <path
      d="M14 13H14.75C14.75 12.8011 14.671 12.6103 14.5303 12.4697L14 13ZM7 6L7.53033 5.46967C7.38968 5.32902 7.19891 5.25 7 5.25V6ZM19 8H19.75C19.75 7.80109 19.671 7.61032 19.5303 7.46967L19 8ZM12 1L12.5303 0.46967C12.3897 0.329018 12.1989 0.25 12 0.25V1ZM15 16.25C14.5858 16.25 14.25 16.5858 14.25 17C14.25 17.4142 14.5858 17.75 15 17.75V16.25ZM5.25 6C5.25 6.41421 5.58579 6.75 6 6.75C6.41421 6.75 6.75 6.41421 6.75 6H5.25ZM12 21.25H3V22.75H12V21.25ZM1.75 20V8H0.25V20H1.75ZM13.25 13V20H14.75V13H13.25ZM3 6.75H7V5.25H3V6.75ZM14.5303 12.4697L7.53033 5.46967L6.46967 6.53033L13.4697 13.5303L14.5303 12.4697ZM6.25 6V12.5H7.75V6H6.25ZM7.5 13.75H14V12.25H7.5V13.75ZM3 21.25C2.30964 21.25 1.75 20.6904 1.75 20H0.25C0.25 21.5188 1.48122 22.75 3 22.75V21.25ZM12 22.75C13.5188 22.75 14.75 21.5188 14.75 20H13.25C13.25 20.6904 12.6904 21.25 12 21.25V22.75ZM6.25 12.5C6.25 13.1904 6.80964 13.75 7.5 13.75V12.25C7.63807 12.25 7.75 12.3619 7.75 12.5H6.25ZM1.75 8C1.75 7.30964 2.30964 6.75 3 6.75V5.25C1.48122 5.25 0.25 6.48122 0.25 8H1.75ZM18.25 8V15H19.75V8H18.25ZM8 1.75H12V0.25H8V1.75ZM19.5303 7.46967L12.5303 0.46967L11.4697 1.53033L18.4697 8.53033L19.5303 7.46967ZM11.25 1V7.5H12.75V1H11.25ZM12.5 8.75H19V7.25H12.5V8.75ZM17 16.25H15V17.75H17V16.25ZM6.75 6V2.9995H5.25V6H6.75ZM11.25 7.5C11.25 8.19036 11.8096 8.75 12.5 8.75V7.25C12.6381 7.25 12.75 7.36193 12.75 7.5H11.25ZM18.25 15C18.25 15.6904 17.6904 16.25 17 16.25V17.75C18.5188 17.75 19.75 16.5188 19.75 15H18.25ZM8 0.25C6.48146 0.25 5.25 1.48047 5.25 2.9995H6.75C6.75 2.30939 7.3094 1.75 8 1.75V0.25Z"
      fill={fill}
    />
  </svg>
);
