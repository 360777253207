import styled, { css } from "styled-components";

const visuallyHidden = css`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 36px;
  background-color: ${(props) => props.theme.colors.whiteBg};
  padding: 8px 12px;
  border-radius: 10px;
`

export const Label = styled.label`
  cursor: pointer;
`

export const Input = styled.input`
  ${visuallyHidden}
`

export const Button = styled.button`
  display: flex;
  cursor: pointer;
`

export const InformationWrapper = styled.div`
  display: flex;
`