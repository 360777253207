import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { productService, specprojectService } from '../services';

import { PageContent } from '../components/common/pageLayout/Shared';
import { SelectData } from '../models';
import {
  PageHeader,
  PageLeftHalf,
  PageWrapper,
} from '../components/common/pageLayout';
import { InputSelect } from '../components/common/inputComp/InputSelect';
import { InputText } from '../components/common/inputComp/InputText';
import { ConfirmButton } from '../components/common/buttonComp/ConfirmButton';

const TextareaContainer = styled.div<any>`
  color: '#000';
`;

const ClaimInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ClaimCreatePage = () => {
  const navigate = useNavigate();

  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [product, setProduct] = useState({
    id: '0',
    name: 'Выберите продукт',
  });
  const [description, setDescription] = useState('');
  const [productsData, setProductsData] = useState<SelectData[]>([]);
  useEffect(() => {
    async function fetchData() {
      const productsRes = (await productService.getAll()).data;
      const products = productsRes.map((el) => ({
        id: String(el.id),
        name: el.name,
      }));
      setProductsData(products);
    }
    fetchData();
  }, []);

  const clearState = () => {
    setFullName('');
    setEmail('');
    setPhoneNumber('');
    setProduct({
      id: '0',
      name: 'Выберите продукт',
    });
    setDescription('');
  };

  const handleClaimCreate = () =>
    new Promise<void>((resolve) => {
      const form = new FormData();
      form.append(
        'playbook',
        JSON.stringify({
          description,
          product: { id: +product.id },
          fullName,
          email,
          phoneNumber,
        })
      );
      specprojectService.create(form).then((response) => {
        if (response.status === 200) {
          resolve();
          clearState();
        }
      });
    });

  const isValid = () =>
    product.id !== '0' &&
    !!email.length &&
    !!fullName.length &&
    !!phoneNumber.length &&
    !!description.length;

  return (
    <PageWrapper>
      <PageContent>
        <PageHeader
          onClick={() => navigate('/claims')}
          title="Связаться с командой Playground"
          navButtons={[
            { to: '/achievement', label: 'Создание' },
            { to: '/achievement-archive', label: 'Уже созданные' },
          ]}
        />
        <PageLeftHalf>
          <ClaimInfo>
            <InputSelect
              data={productsData}
              inputValue={product}
              setInputValue={setProduct}
              label="Продукт"
              mb={6}
            />
            <InputText
              inputValue={fullName}
              setInputValue={setFullName}
              label="Ваше ФИО"
              placeholder="Введите ФИО"
              mb={23}
            />
            <InputText
              inputValue={email}
              setInputValue={setEmail}
              label="Почта"
              placeholder="Введите почту"
              mb={23}
            />
            <InputText
              inputValue={phoneNumber}
              setInputValue={setPhoneNumber}
              label="Номер мобильного телефона"
              placeholder="Введите номер"
              mb={23}
            />

            <TextareaContainer>
              <InputText
                inputValue={description}
                setInputValue={setDescription}
                multiple
                maxLength={500}
                minHeight={120}
                placeholder="Напишите свой вопрос, идею или пожелание в свободной форме."
                innerHint=""
                hint="Напишите свой вопрос, идею или пожелание в свободной форме."
                mb={6}
              />
            </TextareaContainer>
            {/* {attachment && (
          <FileLabelWrapper onClick={handleLoadFile}>
            <div>{attachment?.name}</div>
            <FileSizeLabel>{getSize()}</FileSizeLabel>
          </FileLabelWrapper>
        )} */}
          </ClaimInfo>
          <ConfirmButton
            onClick={handleClaimCreate}
            text="Создать заявку"
            modalMessage="Ваше письмо отправлено команде Playground"
            modalBtnText="Хорошо"
            disable={!isValid()}
          />
        </PageLeftHalf>
      </PageContent>
    </PageWrapper>
  );
};
