export const CheckIcon = () => (
  <svg
    width="10"
    height="7"
    viewBox="0 0 10 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.21185 6.98097C2.9496 6.98108 2.69808 6.87684 2.5128 6.69124L0.399318 4.57855C0.171907 4.35106 0.171907 3.98231 0.399318 3.75483C0.626803 3.52742 0.995555 3.52742 1.22304 3.75483L3.21185 5.74364L8.47342 0.482082C8.7009 0.254671 9.06965 0.254671 9.29714 0.482082C9.52455 0.709566 9.52455 1.07832 9.29714 1.3058L3.91091 6.69124C3.72562 6.87684 3.47411 6.98108 3.21185 6.98097Z"
      fill="white"
    />
  </svg>
);
