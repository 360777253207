import styled from 'styled-components';
import { QuestionIcon } from '../../assets/icons/QuestionIcon';

const StyledQuestionLabel = styled.div`
  background-color: ${(props) => props.theme.colors.blackSidebar};
  position: fixed;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  top: 24px;
  right: 36px;
  cursor: pointer;
  user-select: none;
`;

export const QuestionLabel = (props: { onClick?: () => void }) => (
  <StyledQuestionLabel {...props}>
    <QuestionIcon />
  </StyledQuestionLabel>
);
