import styled from 'styled-components';
import { GamificationTaskButton } from './GamificationTaskButton';
import gamepad from '../../assets/images/gamepad.png';
import { theme } from '../../theme';
import { Typography } from './Typography';

const Card = styled.div<{ src: string; bgColor: string }>`
  position: relative;
  min-width: 544px;
  height: 100%;
  background-color: ${({ bgColor }) => bgColor};
  width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 28px 19px 14px 28px;
  gap: 8px;
  &:after {
    content: '';
    position: absolute;
    right: 19px;
    top: 55px;
    width: 147px;
    height: 89px;
    background: ${({ src }) => `url(${src})`};
    background-position: right top;
    z-index: 1;
  }
`;

const CardBody = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  width: 361px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 8px;
  z-index: 2;
`;

const Header = styled.div`
  width: 100%;
`;

export function GamificationFeedback() {
  const { whiteBg, blackLabel, grayDarkBgButton } = theme.colors;
  return (
    <Card src={gamepad as string} bgColor={whiteBg}>
      <CardBody bgColor={whiteBg}>
        <Header>
          <Typography fw={600} fz={20} lh={26} ls="-0.01em" color="#000000">
            Связаться с командой Playground
          </Typography>
        </Header>
        <Typography lh={18} ls="-0.01em" color={blackLabel}>
          Если вы хотите обсудить новую игровую механику, задать вопрос или
          поделиться мнением о платформе, напишите нам — мы быстро ответим.
        </Typography>
        <GamificationTaskButton
          color={grayDarkBgButton}
          link="/claim-create"
          text="Написать"
          noBorder
        />
      </CardBody>
      {/* <Gamepad /> */}
    </Card>
  );
}
