import api from '../http';
import { CreateTask, Task, UpdateTask } from '../models';

export const taskService = {
  create: (task: CreateTask) => api.post<Task>('playbooks/tasks/', task),
  update: (task: UpdateTask) => api.put<Task>('playbooks/tasks/', task),
  getAll: (page = 1, size = 30) =>
    api.get<Task[]>(`playbooks/tasks/all?page=${page}&size=${size}`),
  getByPlaybookId: (playbookId: number) =>
    api.get<Task>(`playbooks/tasks/playbookId=${playbookId}`),
  deleteByPlaybookId: (playbookId: number) =>
    api.delete(`playbooks/tasks/playbookId=${playbookId}`),
};
