import styled, { css, DefaultTheme } from 'styled-components';
import { useState } from 'react';
import { Modal } from '../Modal';

type Props = {
  theme: DefaultTheme;
  disable: boolean;
  mt?: number;
  paddingBlock?: number;
  background?: string;
  color?: string;
  border?: string;
  maxWidth?: number;
};

const StyledConfirmButton = styled.div`
  margin-top: ${(props: Props) => (props.mt ? `${props.mt}px;` : 'unset')};
  background-color: ${(props: Props) =>
    props.disable
      ? props.theme.colors.graySubtitle
      : props.background ?? props.theme.colors.blackBgButton};
  color: ${(props: Props) => (props.color ? `${props.color}` : '#fff')};
  ${(props: Props) =>
    !props.disable &&
    css`
      cursor: pointer;
    `};
  font-family: 'SB Sans Text';
  border-radius: 10px;
  letter-spacing: -0.02em;
  padding: ${(props: Props) =>
    props.paddingBlock ? `${props.paddingBlock}px 0` : '14px 0'};
  line-height: 20px;
  border: ${(props: Props) =>
    props.border ? `1px solid ${props.border}` : 'none'};
  width: 100%;
  text-align: center;
  ${(propsList: Props) =>
    propsList.maxWidth &&
    css`
      max-width: ${(props: Props) => `${props.maxWidth}px`};
    `};
`;

export const ConfirmButton = ({
  onClick,
  text,
  disable,
  mt,
  modalMessage,
  modalBtnText,
  paddingBlock,
  background,
  color,
  border,
  maxWidth,
}: {
  onClick: any;
  text: string;
  disable: boolean;
  mt?: number;
  modalMessage?: string;
  modalBtnText?: string;
  paddingBlock?: number;
  background?: string;
  color?: string;
  border?: string;
  maxWidth?: number;
}) => {
  const [showModal, setModal] = useState(false);

  const handleModalSuccess = () => {
    setModal(false);
  };

  const handleToggleModal = () => {
    setModal((prev) => !prev);
  };

  const handleConfirmClick = () => {
    if (disable) return;
    onClick().then(() => handleToggleModal());
  };

  return (
    <StyledConfirmButton
      disable={disable}
      mt={mt}
      paddingBlock={paddingBlock}
      background={background}
      color={color}
      border={border}
      onClick={handleConfirmClick}
      maxWidth={maxWidth}
    >
      <>
        {text}
        {modalMessage && modalBtnText && showModal && (
          <Modal
            message={modalMessage}
            btnText={modalBtnText}
            onClose={handleToggleModal}
            onSuccess={handleModalSuccess}
          />
        )}
      </>
    </StyledConfirmButton>
  );
};
