import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { mapService } from '../../services';
import { PageContent, PageMain } from '../../components/common/pageLayout/Shared';
import { MapPreview } from '../../components/common/MapPreview';
import {
  PageHeader,
  PageLeftHalf,
  PageRightHalf,
  PageWrapper,
} from '../../components/common/pageLayout';
import { ConfirmButton } from '../../components/common/buttonComp/ConfirmButton';
import { InputText } from '../../components/common/inputComp/InputText';
import { InputDate } from '../../components/common/inputComp/InputDate';
import { addDays, getDurationDate } from '../../utils';
import { Typography } from '../../components/common/Typography';
import { theme } from '../../theme';
import { InputUpload } from '../../components/common/inputComp/InputUpload/InputUpload';
import { StyledPageLeftContent, UploaderList, UploaderListItem, UploaderWrapper } from './Styles';
import { useHandlePreviewImage } from './hooks/useHandlePreviewImage';

const COUNT_LEVELS = {
  min: 1,
  max: 8
};

const SBER_ID = 1; // Наверное сберовский
const MOCK_AWARD_ID = 1; // Требует сервер, но в логике выбора нет

const today = new Date();

const initData = {
  value: today,
  touched: false
}

export const MapCreatePage = () => {
  const { graySubtitle, blackcurrant } = theme.colors
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [mapName, setMapName] = useState<string>('');
  const [mapDescription, setMapDescription] = useState<string>('')
  const [startDate, setStartDate] = useState<typeof initData>(initData);
  const [endDate, setEndDate] = useState<typeof initData>({...initData, value: addDays(today, 1)});
  const [levelsCount, setLevelsCount] = useState<string>('');
  const [userImages, setUserImages] = useState<(File | null)[]>([null]);

  const {previewImage} = useHandlePreviewImage({userImages});

  useEffect(() => {
      const newUserImagesArray = Array.from<File | null>({length: levelsCount ? Number(levelsCount) : 1}).fill(null)
      setUserImages(newUserImagesArray)
  }, [levelsCount]);

  const renderImageInputs = () => userImages.map((file, index) => (
      <UploaderListItem key={index}>
        <InputUpload 
          id={index}
          value={file}
          setValue={(el) => setUserImages((prevState) => {
            const newState = [...prevState];
            newState[index] = el;
            return newState
          })}
          accept=".png"
        />
      </UploaderListItem>
    ));

  const handleMapCreate = () => {
    const duration = getDurationDate(startDate.value, endDate.value);

    const mapDto = {
      name: mapName,
      description: mapDescription,
      elementCount: Number(levelsCount),
      productId : SBER_ID,
      mapAwardId: MOCK_AWARD_ID,
      duration
    };

    return (
      new Promise<void>((resolve) => {
        setLoading(true);
  
        mapService.createMap(mapDto).finally(() => {
          resolve();
          setLoading(false);
        });
      })
    )
  }

  const isValid = () =>
    !loading &&
    mapName

  return (
    <PageWrapper>
      <PageContent>
        <PageHeader
          onClick={() => navigate('/gamification')}
          title="Карта сокровищ"
        />
        <PageMain>
          <PageLeftHalf>
            <StyledPageLeftContent>
              <InputText
                inputValue={mapName}
                setInputValue={setMapName}
                label="Название карты"
                placeholder="Например, Игра началась!"
                mb={20}
              />
              <InputText
                inputValue={mapDescription}
                setInputValue={setMapDescription}
                label="Описание карты"
                placeholder="Например, Игра началась!"
                multiple
                mb={20}
              />
              <InputDate
                min={today}
                date={startDate}
                setDate={setStartDate}
                label="Дата начала карты"
                mb={6}
              />
              <InputDate
                min={addDays(today, 1)}
                date={endDate}
                setDate={setEndDate}
                label="Дата конца карты"
                mb={6}
              />
              <InputText
                label="Количество уровней в карте"
                inputValue={levelsCount}
                setInputValue={setLevelsCount}
                placeholder="Введите количество уровней"
                hint="Максимум 8"
                min={COUNT_LEVELS.min}
                max={COUNT_LEVELS.max}
                mb={20}
              />
              <UploaderWrapper>
                <Typography
                  lh={18}
                  ls="-0.02em"
                >
                  Визуальное отображение к карте
                </Typography>
                <UploaderList>
                  {renderImageInputs()}
                </UploaderList>
                <Typography
                  fz={12}
                  lh={16}
                  color={graySubtitle}
                >
                  Не более 2 mb. Формат PNG, с прозрачным фоном.
                </Typography>
              </UploaderWrapper>
              <ConfirmButton
                onClick={handleMapCreate}
                text="Создать карту"
                modalMessage="Карта успешно создана"
                modalBtnText="Хорошо"
                mt={40}
                maxWidth={175}
                disable={!isValid()}
              />
            </StyledPageLeftContent>
          </PageLeftHalf>
          <PageRightHalf
            description="Так иллюстрация будет выглядеть в виджете"
            minHeight={765}
            bgColor={blackcurrant}
            flexDirection='row'
          >
            {previewImage && (
              <MapPreview
                imageUrl={previewImage}
              />
            )}
          </PageRightHalf>
        </PageMain>
      </PageContent>
    </PageWrapper>
  );
};
